import * as React from 'react';
import { useSelector } from 'react-redux';
import { Layout, LayoutProps, Sidebar } from 'react-admin';
import AppBar from './appBar';
import { darkTheme, lightTheme } from './themes';

import { createBrowserHistory as createHistory } from 'history';
import { CustomBreadcrumbs } from '../customComponents';

const CustomSidebar = (props) => <Sidebar {...props} size={200} />;

export default (props) => {
  const themeName = 'light' | 'dark';
    const theme = useSelector((state) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}

            theme={theme}
           children={
              <>
                <CustomBreadcrumbs />
                {props.children}
              </>
            }

        />
    );
};
