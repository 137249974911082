import * as React from "react";
import { DOC_ROOT } from "../confProvider.js";

import {
  Datagrid,
  DateField,
  FileField,
  Filter,
  FilterList,
  FilterListItem,
  FunctionField,
  List,
  NumberField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import MobileFactures from "./mobileFactures";

import {
  CardContent,
} from "@material-ui/core"

import { useState} from "react";

import {useMediaQuery} from '@material-ui/core';

import {
  subDays,
  subMonths
} from 'date-fns';

import {ColoredSignNumberField} from '../customComponents/ColoredField';
import { DatagridTotalLine } from '../customComponents/CustomTotalLine';
import {LinkField, LinkToFile} from '../customComponents/LinkField';

import TimelapseIcon from '@material-ui/icons/Timelapse';

import {Card} from '../style.js';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const util = require('../utils');

const TermFilter = () => (
  <FilterList label="resources.invoices.filters.term_date" icon={<TimelapseIcon />} >
    <FilterListItem label="resources.invoices.filters.not_issued"
      value={{clb_dteche: " >= CURRENT_DATE"}} />
    <FilterListItem label="resources.invoices.filters.today"
      value={{clb_dteche: " = CURRENT_DATE"}} />
    <FilterListItem label="resources.invoices.filters.yesterday"
      value={{clb_dteche: " <= TO_DATE('" + subDays(new Date(), 1).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"}} />
    <FilterListItem label="resources.invoices.filters.week"
      value={{clb_dteche: " <= TO_DATE('" + subDays(new Date(), 7).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"}} />
    <FilterListItem label="resources.invoices.filters.two_weeks"
          value={{clb_dteche: " <= TO_DATE('" + subDays(new Date(), 15).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"}} />
    <FilterListItem label="resources.invoices.filters.month"
          value={{clb_dteche: " <= TO_DATE('" + subDays(new Date(), 30).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"}} />
  </FilterList>
)
export const FilterSideBar = () => (
  <Card>
    <CardContent>
      <TermFilter />
    </CardContent>
  </Card>
)


const InvoiceFilter = (props) => {
  const [dataFilter, setDataFilter] = useState({});      // filter on data retreive from server

  const onSalesMgrChange = (item) => {
    let tmpFilter = dataFilter;
    if(item === "" || item === null || item.target.value === undefined || item.target.value === "" || item.target.value === null) delete tmpFilter["eq_ta3_libre"];
    else {
      Object.assign(tmpFilter, {["eq_ta3_libre"]: "'" + item.target.value + "'"});
    }
    setDataFilter(tmpFilter);
  }

  const userFilter = util.getUserFilter();
  return (
    <Filter {...props}>
      {(userFilter.profile === "dcom" || userFilter.profile === "adm") &&
        <ReferenceInput label="resources.customers.fields.sales_mgr" source="likeic_ta3_libre" filter={userFilter}
          reference="sales_mgr" allowEmpty={true} resettable={false} emptyText= {`pos.all`}
          alwaysOn sort={{field:"dcomm.ta_lib", order:"ASC"}}
          onChange={onSalesMgrChange}
        >
          <SelectInput optionText="ta_lib" />
        </ReferenceInput>
      }
      <ReferenceInput label="resources.customers.fields.country" source="likeic_t76_pays" filter={Object.assign({}, userFilter, dataFilter)}
        reference="country" allowEmpty={true} resettable={false} emptyText= {`pos.all`}
        alwaysOn sort={{field:"ta_lib", order:"ASC"}}
        perPage={10000}
      >
        <SelectInput optionText="ta_lib" />
      </ReferenceInput>

      <ReferenceInput label="resources.customers.fields.statistical_area" source="likeic_cli.t72_secsta"
        filter={Object.assign({}, userFilter, dataFilter)} reference="statistical_area" allowEmpty={true} resettable={false} emptyText={`pos.all`}
        alwaysOn sort={{ field: "TA_LIB", order: "ASC" }}
        perPage={10000}
      >
        <SelectInput optionText="TA_LIB" />
      </ReferenceInput>

      <ReferenceInput label="resources.customers.fields.commercial" source="likeic_ta7_libre"
        filter={Object.assign({}, dataFilter)} reference="salesman" allowEmpty={true} resettable={false} emptyText= {`pos.all`}
        alwaysOn sort={{field:"comm.ta_lib", order:"ASC"}}
        perPage={10000}
      >
        <SelectInput optionText="ta_lib" />
      </ReferenceInput>
      <TextInput label="resources.customers.fields.name" source="likeic_cli.cl_rsoc1" alwaysOn />
    </Filter>
  );
}
export const InvoiceList = (props) => {
  const isXsmall = useMediaQuery(theme =>theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  // assistant: an invoice is late as soon as yesterday
  // sales & sales mgr, 15 days before.
  let userFilter =util.getUserFilter();
  //userFilter["eq_cli.cl_acti"] = "'0'";
  //userFilter["eq_clb_dc"] = "'D'"

  return (
    <List
      filter={userFilter}
      filters={isSmall || isXsmall ? null: <InvoiceFilter/> }
      {...props}
      hasList
      aside={<FilterSideBar />}
      sort={{field:"clb_dteche", sort: "ASC"}}
      bulkActionButtons={false}
    >
      {isXsmall ? ( <MobileFactures/> ) : (
        <DatagridTotalLine
          filter={userFilter}
          fieldsToTotalize={[
            {
              propriete: " SUM(b0111.clb_mt * (CASE b0111.clb_dc WHEN 'C' THEN -1 ELSE 1 END))",
              as: "clb_mt",
              resource: "sommeProprietesInvoices"
            }
          ]}
        >
          <DateField source="clb_dteche" label={'resources.invoices.fields.term_date'} />
          <LinkField  source="cli.cl_rsoc1" label={'resources.customers.fields.name'} basePath="/customers" linkValue="cli.cl_code" linkParam="2"/>
          <ColoredSignNumberField source="clb_mt" label={'resources.invoices.fields.amount'}
            options={{minimumFractionDigits: 2}} />
          <TextField  source="clb_libelle" label={'resources.invoices.fields.label'} />
          <TextField  source="comm.ta_lib" label={'resources.customers.fields.commercial'} />
          <TextField  source="pays.ta_lib" label={'resources.customers.fields.country'} />
          {/*<TextField source="clb_piece" label={'resources.invoices.fields.number'} />*/}
          <LinkToFile label={'resources.invoices.fields.number'} url={`${DOC_ROOT}FACTURES/`} source="clb_piece" type=".pdf" target="_blank"/>
          {/*<FileField label={'resources.invoices.fields.number'} source={`${record.clb_piece}.pdf`} title="clb_piece" target="_blank"/>*/}
        </DatagridTotalLine>
       )}
    </List>
  );
}

const exports = {
  list: InvoiceList,
}
export default exports;
