import * as React from 'react';
//import { SVGProps } from 'react';
import { useTheme } from '@material-ui/core/styles';

const Logo = (props) => {
    const theme = useTheme();
    return (
        <svg
            width={234.532}
            height={20.475}
            viewBox="0 0 62.053 5.417"
            {...props}
        >
            <g
                aria-label="CFE"
                style={{
                    lineHeight: 1.25,
                }}
                fontWeight={400}
                fontSize={7.056}
                fontFamily="Permanent Marker"
                letterSpacing={0}
                wordSpacing={0}
                strokeWidth={0.265}
                fill={theme.palette.secondary.light}
            >
            <path d="M680.73147,424.260692 C628.267652,424.260692 573.22228,383.949754 573.22228,313.843776 C573.22228,248.836414 626.352306,208.525476 678.149917,208.525476 C742.355637,208.525476 783.827036,264.291595 783.827036,313.286114 C783.827036,382.117439 732.612357,424.260692 680.73147,424.260692 M845.784308,137.065177 L813.22343,137.065177 C799.149802,137.065177 787.741004,147.979404 787.741004,161.442938 L787.741004,186.856355 L786.491865,186.856355 C776.665309,173.233489 745.520122,132.842885 671.737672,132.842885 C580.384008,132.842885 488.28086,205.418506 488.28086,313.286114 C488.28086,428.56265 584.880907,499.863617 672.320604,499.863617 C741.522878,499.863617 774.666687,464.651296 787.907556,447.682462 L787.907556,471.104232 C787.907556,558.896037 714.45821,630.436002 624.187132,630.436002 C533.832779,630.436002 460.383433,558.896037 460.383433,471.104232 L460.383433,383.312427 L460.300157,383.312427 L460.300157,282.455417 C460.300157,198.168911 389.015985,129.65625 301.409736,129.65625 C213.886763,129.65625 142.602591,198.168911 142.602591,282.455417 L142.602591,470.148242 C142.602591,483.691442 154.01139,494.605668 168.085017,494.605668 L201.228826,494.605668 C215.302454,494.605668 226.794528,483.691442 226.794528,470.148242 L226.794528,282.455417 C226.794528,242.622475 260.188165,210.19846 301.409736,210.19846 C342.548032,210.19846 376.024944,242.622475 376.024944,282.455417 L376.024944,383.312427 L375.941669,383.312427 L375.941669,471.104232 C375.941669,471.582228 376.024944,471.980557 376.024944,472.378886 L376.024944,484.488101 L377.274083,484.488101 C384.602362,610.599197 491.945,711.296875 624.187132,711.296875 C761.092715,711.296875 872.432596,603.508933 872.432596,471.104232 L871.350009,161.363272 C871.350009,147.899738 859.857935,137.065177 845.784308,137.065177" id="Fill-4"></path>
            </g>
        </svg>
    );
};

export default Logo;
