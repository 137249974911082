import * as React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/configuration';
import { Profile } from './profile/profile';
//import { Statistique } from './statistique/statistique';

import { OrderCreate, OrderList } from './orders/orders';

/* Import Categories */
import {ProdList} from './products/products_List';
/* Import Produits */
import {ProdEdit} from './products/products_Edit';
import {ProdHTEdit} from './products/products_Edit';

const routes = [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/profile" render={() => <Profile />} />,
    //<Route path="/statistique/:idCli" render={(props) => <Statistique idCli={props.match.params.idCli} {...props} />} />,
    //<Route exact path="/mail" render={() => <FormRequest />} />,

    /* Routes Categories */
    <Route exact path="/FWD" render={(props) => <ProdList categorie="FWD" {...props}/>} />,
    <Route exact path="/Tarif" render={(props) => <ProdList categorie="Tarif" {...props}/>} />,
    <Route exact path="/HorsTarif" render={(props) => <ProdList categorie="HorsTarif" {...props}/>} />,
    //<Route exact path="/Primeurs" render={() => <ProdListPrim/>} />,
    /* Routes Produits */
    <Route exact path="/products/details/:id" render={(props) => <ProdEdit id={props.match.params.id} {...props}/>}/>,
    <Route exact path="/products/details/HorsTarif/:id" render={(props) => <ProdHTEdit id={props.match.params.id} {...props}/>}/>,

    /* Routes Orders */
    <Route path="/commandes/:idCli" render={(props) => <OrderList idCli={props.match.params.idCli} {...props} />} />,
    <Route path="/commandes/:idCli/edit" render={(props) => <OrderList idCli={props.match.params.idCli} {...props} />} />,
    <Route path="/commandes/:idCli/show" render={(props) => <OrderList idCli={props.match.params.idCli} {...props} />} />,
    <Route path="/commandes/create" render={(props) => <OrderCreate {...props} />} />,

    //<Route path="/notifications" render={(props) => <NotificationShow {...props} title={"Notifications"}/>}/>,
];
export default routes;
