import * as React from "react";
import { createElement } from 'react';
import { Link } from 'react-router-dom';
import {
    ResourceContextProvider,
    ListContextProvider,
    List,
    Datagrid,
    DateField,
    NumberField,
    TextField,
    Filter,
    Create,
    SimpleForm,
    TextInput,
    Button
} from 'react-admin';
import {CustomPagination, CustomList} from '../customComponents';
import {LinkField} from '../customComponents/LinkField';
import MobileOrders from './mobileOrders';
import AsideListOrders from './AsideListOrders';
import {
    Grid,
    useMediaQuery
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  Show,
  SimpleShowLayout
} from 'react-admin';
import {useListContext} from 'ra-core';
import {subYears} from 'date-fns';

const OrderFilter = (props) => {
    return (
        <Filter {...props}>
          <TextInput label="resources.orders.fields.customer" source="cl_rsoc1_liv" alwaysOn variant="outlined"/>
        </Filter>
    );
}

export const OldOrderList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return(
        <Grid item xs={12}>
            <CustomList
                resource="commande"
                filter={{idCli:props.idCli}}
                filters={isSmall ? undefined : <OrderFilter/>}
                pagination={<CustomPagination />}
                perPage={5}
                sort={{field:"ce_datcli",order:"DESC"}}
                hasCreate
                hasEdit
                hasList
                hasShow
                title="."
            >
                <OrderGrid />
            </CustomList>
        </Grid>
    )
};

export const MonthOrder = (props) => {

    return(
        <Grid item xs={12}>
            <List
                resource={props.resource}
                basePath={props.basePath}
                perPage={10}
                empty={false}
                title="pos.dashboard.name"
                sort={{field:"ce_datsais",order:"DESC"}}
            >
                <OrderGrid />
            </List>
        </Grid>
    )
};



export const OrderList = props =>{
  const isXsmall = useMediaQuery(theme =>theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  let userFilter= {
    ["eq_cl_code_liv"]: "'" + props.idCli + "'",
    ["eq_b0100.cl_acti"]: "'0'" ,
    ["gte_b1010.ce_datsais"]: "'" + subYears(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours()), 10).toISOString() +"'"
  }

  return(
    <List
      filter={userFilter}
      filters={isSmall ? undefined : <OrderFilter/>}
      {...props}
      aside={<AsideListOrders/>}
      sort={{field: "ce_datsais", order: "DESC"}}
      bulkActionButtons={false}
    >
      {isXsmall ? (
        <MobileOrders/>
      ) : (
        <Datagrid >
          <LinkField source="ce_num" label="resources.orders.fields.order_num" linkValue="id" {...props}/>
          <TextField source="typeBon.ta_lib" label={`resources.orders.fields.type`} />
          <TextField source="deliveredCustomer.cl_rsoc1" label={`resources.orders.fields.deliv_customer`}  />
          <NumberField source='ce_cacdeht' label={`resources.orders.fields.turnover`} />
          <TextField source="ce_refcli" label={`resources.orders.fields.customer_order_num`} />
          <DateField source="ce_datenl" label={`resources.orders.fields.dates.takeoff`} />
        </Datagrid>
      )}
    </List>
  )
}


export const OrderGrid = (props) => {
    const t_rootPath = 'resources.orders';
    const { ids, data, basePath, resource, currentSort, selectedIds } = useListContext();

    return(
        <ResourceContextProvider value={resource}>
            <ListContextProvider
                value={{
                    basePath: basePath,
                    data: data,
                    ids: ids,
                    currentSort: currentSort,
                    selectedIds: selectedIds,
                }}
            >
                <Datagrid {...useListContext()} >
                  <LinkField label={`${t_rootPath}.fields.invoiced_customer`} source="cl_rsoc1" linkValue="cl_code_fac" {...props} basePath="/customers" resource="customers"/>
                  <LinkField label={`${t_rootPath}.fields.deliv_customer`} source="cl_rsoc_liv" linkValue="cl_code_liv" {...props} basePath="/customers" resource="customers"/>
                  <LinkField label={`${t_rootPath}.fields.reference`}  source="id" linkValue="id" {...props} basePath="/orders" resource="orders"/>
                  <TextField label={`${t_rootPath}.fields.customer_order_num`} source="ce_refcli" />
                  <TextField label={'resources.customers.fields.commercial'} source="commercial" />
                  <TextField label={'resources.customers.fields.country'} source='cl_pays' />
                  <TextField label={`${t_rootPath}.fields.invoice`} source='ce_numfac' />
                  <DateField label={`${t_rootPath}.fields.dates.invoice`} source="ce_datfac" />
                  <DateField label={`${t_rootPath}.fields.dates.input`} source="ce_datsais" />
{/*                  <DateField label={`${t_rootPath}.fields.dates.exped_mode`} source="ce_datexpd" />
                  <TextField label={`${t_rootPath}.fields.step`}  source="avcde" />
                  <TextField label={`${t_rootPath}.fields.locked`}  source="bloc" />
                  <TextField label={`${t_rootPath}.fields.anomaly`}  source="ancde" /> */}
                </Datagrid>
            </ListContextProvider>
        </ResourceContextProvider>
    );
};

export const OrderShowButton = (props) => {
    return(
        <Button
            component={Link}
            to={'/commandes/'+props.record.ce_num+'/show'}
            label={"Détail"}
        >
            {createElement(VisibilityIcon)}
        </Button>
    );
}

export const OrderShow = (props) => {
    const t_rootPath = 'resources.orders';

    return(
            <Show
                {...props}
                basePath="/commande"
                resource="commande"
                id={props.idCom}
                title={"Commande "+props.idCom}
                hasCreate hasEdit hasList hasShow
            >
                <SimpleShowLayout >
                    <TextField label={`${t_rootPath}.fields.customer`} source="cl_rsoc1" />
                    <TextField label={`${t_rootPath}.fields.reference`}  source="ce_num" />
                    <DateField label={`${t_rootPath}.fields.dates.customer_order_date`} source="ce_datcli" />
                    <DateField label={`${t_rootPath}.fields.dates.expected_delivery`} source="ce_datliv" />
                    <NumberField label={`${t_rootPath}.fields.number_of_articles`} source="ce_nbrart" />
                    <NumberField label={`${t_rootPath}.fields.number_of_packages`} source="ce_nbcolis" />
                    <DateField label={`${t_rootPath}.fields.dates.exped_mode`} source="ce_datexpd" />
                    <TextField label={`${t_rootPath}.fields.step`}  source="avcde" />
                    <TextField label={`${t_rootPath}.fields.locked`}  source="bloc" />
                    <TextField label={`${t_rootPath}.fields.anomaly`}  source="ancde" />
                    <DateField label={`${t_rootPath}.fields.dates.input`} source="ce_datsais" />
                    <DateField label={`${t_rootPath}.fields.dates.update`} source="ce_datmodif" />
                </SimpleShowLayout>
            </Show>
    )
};

export const OrderCreate = props => (
    <Create {...props} basePath="/commande" resource="commande" hasCreate hasEdit hasList hasShow>
    <SimpleForm>
    <TextInput label="Réf" source="ce_num" />
    </SimpleForm>
    </Create>
);
