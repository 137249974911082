import * as React from "react";
import {
    List,
    Datagrid,
    NumberField,
    TextField,
} from 'react-admin';
import MobileOrders from './mobileOrders';
import {
    useMediaQuery
} from '@material-ui/core';

import {NoResult} from "../customComponents/NoResult";
import { SubListPagination } from "../customComponents/SubListPagination";

export const EarlyWinesList = props =>{
  const isXsmall = useMediaQuery(theme =>theme.breakpoints.down('xs'));

  return(
    <List
      {...props}
      sort={{field: "product.ba_lib", order: "ASC"}, {field: "millesime", order: "DESC"}}
      bulkActionButtons={false}
      title=" "
      empty={<NoResult/>}
      pagination={<SubListPagination/>}
    >
      {isXsmall ? (
        <MobileOrders/>
      ) : (
        <Datagrid >
          <TextField source="product.ba_lib" label="resources.orders.fields.product" sortBy='"product.ba_lib"'/>
          <TextField source="MILLESIME" label={`resources.orders.fields.millesime`} />
          <TextField source="bout.volume" label={`resources.products.fields.Vol`}  sortBy='"bout.volume"'/>
          <NumberField source='qte' label={`resources.orders.fields.quantity`} sortBy='"qte"'/>
          <NumberField source='qte_liv' label={`resources.orders.fields.deliv_quantity`} sortBy='"qte_liv"'/>
          <NumberField source="solde"  label="resources.orders.fields.solde" sortBy='"solde"'/>
        </Datagrid>
      )}
    </List>
  )
}
