/**************************************************************************
Component to display empty list
****************************************************************************/
import {
  Card,
CardContent
} from "@material-ui/core";

import {Typography} from "@material-ui/core";

import {useTranslate} from 'react-admin';

export const NoResult = () => {
  const translate = useTranslate();

  return (
    <Card>
      <CardContent>
        <Typography color="text.secondary" gutterBottom>{translate("pos.no_data")}</Typography>
      </CardContent>
    </Card>
  );
}
