import * as React from "react";

// standard GUI components

import {
  Grid,
} from '@material-ui/core';

import Loader from 'react-loader-spinner';

import { green, orange, red} from "@material-ui/core/colors";
import {
  Block,
  Contacts,
  Euro,
  MonetizationOn,
  Person,
  ShoppingCart,
} from '@material-ui/icons'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import TimerIcon from '@material-ui/icons/Timer';
import IconeNouveauClient from '@material-ui/icons/AddBox'
// Standard hooks

import {
  useDataProvider,
  useLocale,
  useTranslate,
} from 'react-admin';

import {
    useEffect,
    useState
  } from 'react';

import {
    addDays,
    addMonths,
    addWeeks,
    subDays,
    subMonths,
  } from 'date-fns';


// custom GUI components
import InfoBox from "./infoBox";

const util = require('../utils');

const Good = green["600"];
const Medium = orange["500"];
const Bad = orange["900"];
const Warn = red["500"];


/*******************************************************************************************************************
Indicators objects
*******************************************************************************************************************/
const CaNouveauxClients = {
  name: 'CaNouveauxClients', icon: IconeNouveauClient, bgColor: Good, isCurrency: true,
  to:'/customers',
  search: `filter=${JSON.stringify({lte_cl_dercde: undefined,
    gte_cl_dercde: "TO_DATE('" + subMonths(new Date(new Date()), 24).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"})}`
};

const nbBlockedOrders = {
  name: 'nbBlockedOrders', icon: Block, bgColor: Bad, isCurrency: false,
  to:'/orders',
  search: `filter=${JSON.stringify({eq_ce_datimp: undefined,
                                    ce_datenl: undefined,
                                    t41_avcde: undefined,
                                    "com.t42_ancde": " IN ('L0', 'L5')"
                                    }
                    )}`
};
const nbClients = {
  name: 'nbClients', icon: Person, bgColor: Good, isCurrency: false,
  to:'/customers',
  search: `filter=${JSON.stringify({lte_cl_dercde: undefined,
                                    gte_cl_dercde: "TO_DATE('" + subMonths(new Date(new Date()), 24).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"})}`
};
const nbLateInvoicesForAssi = {
  name: 'nbLateInvoices', icon: HourglassEmptyIcon, bgColor: Bad, isCurrency: false,
  to:'/invoices',
  search: `filter=${JSON.stringify({clb_dteche: " <= TO_DATE('" + subDays(new Date(), 1).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"})}`
};
const nbLateInvoicesForCom = {
  name: 'nbLateInvoices', icon: HourglassEmptyIcon, bgColor: Bad, isCurrency: false,
  to:'/invoices',
  search: `filter=${JSON.stringify({clb_dteche: " <= TO_DATE('" + subDays(new Date(), 1).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"})}`
};
const nbMonthDepartures = {
  name: 'nbMonthDepartures', icon: TimerIcon, bgColor: Good, isCurrency: false,
  to: '/orders',
  search: `filter=${JSON.stringify({gte_ce_datenl: "'" + new Date(new Date().getFullYear(),
                                                              new Date().getMonth(),
                                                              new Date().getDate(),
                                                              new Date().getHours(), 0)
                                                              .toISOString() + "'",
                                    lte_ce_datenl: "'" + addMonths(new Date(new Date().getFullYear(),
                                                                    new Date().getMonth(),
                                                                    new Date().getDate(),
                                                                    new Date().getHours(), 0), 1)
                                                                    .toISOString() + "'"
                                    }
                    )}`
};
const nbNoTrspInfoOrders = {
  name: 'nbNoTrspInfoOrders', icon: LocalShippingIcon, bgColor: Medium, isCurrency: false,
  to:'/orders',
  search: `filter=${JSON.stringify({ce_datenl: " IS NULL",
                                    "com.t41_avcde": " in ('30', '35', '40', '55','60', '65', '68')",
                                    "com.t42_ancde": " = '00'",
                                    ce_acti: " = '0'"})}`
};
const nbProcessingOrders = {
  name: 'nbProcessingOrders', icon: Euro, bgColor: Good, isCurrency: false,
  to: '/orders',
  search: `filter=${JSON.stringify({ce_numfac: " IS NOT NULL",
                                    "com.t41_avcde" : " <> '80'",
                                    "com.t42_ancde": " = '00'",
                                    ce_acti: " = '0'"})}`
};
const nbProspects = {
  name: 'nbProspects', icon: Contacts, bgColor: Good, isCurrency: false,
  to: '/prospect'
};
const nbShortDepartures = {
  name: 'nbShortSendingOrders', icon: TimerIcon, bgColor: Bad, isCurrency: false,
  to: '/orders',
  search: `filter=${JSON.stringify({gte_ce_datenl: "'" + new Date(new Date().getFullYear(),
                                                              new Date().getMonth(),
                                                              new Date().getDate(), 0)
                                                              .toISOString() + "'",
                                    lte_ce_datenl: "'" + addDays(new Date(new Date().getFullYear(),
                                                                    new Date().getMonth(),
                                                                    new Date().getDate(), 0), 2)
                                                                    .toISOString() + "'"
                                    }
                    )}`
};
const nbTwoWeeksDepartures = {
  name: 'nb2WeeksDepartures', icon: TimerIcon, bgColor: Good, isCurrency: false,
  to: '/orders',
  search: `filter=${JSON.stringify({gte_ce_datenl: "'" + new Date(new Date().getFullYear(),
                                                              new Date().getMonth(),
                                                              new Date().getDate(),
                                                              new Date().getHours(), 0)
                                                              .toISOString() + "'",
                                    lte_ce_datenl: "'" + addWeeks(new Date(new Date().getFullYear(),
                                                                    new Date().getMonth(),
                                                                    new Date().getDate(),
                                                                    new Date().getHours(), 0), 2)
                                                                    .toISOString() + "'"
                                    }
                    )}`
};
const nbWaitingOrders = {
  name: 'nbWaitingOrders', icon:HourglassEmptyIcon , bgColor: Medium, isCurrency: false,
  to:'/orders',
  search: `filter=${JSON.stringify({ce_numfac: " IS NULL",
                                    eq_ce_datimp: " TO_DATE('31/12/' || EXTRACT(YEAR FROM CURRENT_DATE), 'DD/MM/YYYY')",})}`
};

const nbWeekDepartures = {
  name: 'nbWeekDepartures', icon: TimerIcon, bgColor: Medium, isCurrency: false,
  to: '/orders',
  search: `filter=${JSON.stringify({gte_ce_datenl: "'" + new Date(new Date().getFullYear(),
                                                              new Date().getMonth(),
                                                              new Date().getDate(),
                                                              new Date().getHours(), 0)
                                                              .toISOString() + "'",
                                    lte_ce_datenl: "'" + addWeeks(new Date(new Date().getFullYear(),
                                                                    new Date().getMonth(),
                                                                    new Date().getDate(),
                                                                    new Date().getHours(), 0), 1)
                                                                    .toISOString() + "'"
                                    }
                    )}`
};
const salesAmount = {
  name: 'salesAmount', icon: Euro, bgColor: Good, isCurrency: true,
  to:'/orders',
  search: `filter=${JSON.stringify({gte_ce_datfac: " TO_DATE('01/01/" + new Date().getFullYear() + "', 'DD/MM/YYYY')",
              ce_numfac: undefined,
              eq_ce_datimp: undefined,
              ce_datenl: undefined,
              "com.t41_avcde": undefined}
            )}`
};
const valueBlockedOrders = {
  name: 'valueBlockedOrders', icon: Block, bgColor: Warn, isCurrency: true,
  to:'/orders',
  search: `filter=${JSON.stringify({ce_datimp: undefined,
                                    ce_datenl: undefined,
                                    "com.t42_ancde": " IN ('L0', 'L5')"
                                    }
                    )}`
};
// suite ticket 357 le nombre de jours pour les factures en retard des commerciaux passe à 1
const valueLateInvoices = {
  name: 'valueLateInvoices', icon: HourglassEmptyIcon, bgColor: Bad, isCurrency: true,
  to:'/invoices',
  search: `filter=${JSON.stringify({clb_dteche: " <= TO_DATE('" + subDays(new Date(), 1).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"})}`
};
const valueNoTrspInfoOrders = {
  name: 'valueNoTrspInfoOrders', icon: LocalShippingIcon, bgColor: Medium, isCurrency: true,
  to:'/orders',
  search: `filter=${JSON.stringify({"com.t41_avcde": " in ('30', '35', '40', '55','60', '65', '68')",
                                    "com.t42_ancde": " = '00'",
                                    ce_datenl: " IS NULL"})}`
};
const valuePendingInvoices = {
  name: 'valuePendingInvoices', icon: MonetizationOn, bgColor: Medium, isCurrency: true,
  to: '/invoices',
  search: `filter=${JSON.stringify({clb_dteche: undefined})}`
};
const valueWaitingOrders = {
  name: 'valueWaitingOrders', icon:ShoppingCart , bgColor: Medium, isCurrency: true,
  to:'/orders',
  search: `filter=${JSON.stringify({ce_numfac: " IS NULL",
                                  "com.t42_ancde": " = '00'"})}`
};

/*const valueWaitingOrders = {
  name: 'valueWaitingOrders', icon:ShoppingCart , bgColor: Medium, isCurrency: true,
  to:'/orders',
  search: `filter=${JSON.stringify({ce_numfac: " IS NULL",
                                    eq_ce_datimp: " TO_DATE('31/12/' || EXTRACT(YEAR FROM CURRENT_DATE), 'DD/MM/YYYY')",})}`
};*/

/*******************************************************************************************************************
Arrays of all indicators to display regarding user's profile.
Each object has :
  - name: must be the key returned by the API (i.e. nbProcessingOrders: 5 take nbProcessingOrders)
  - icon: icon component to display (has to be imported in the import section of this file)
  - bgColor: color of the card (surroundings, icon and value). Must be defined before in this file
  - isCurrency: true or false, indicates whether the value is displaied or not in currency format
  - to: route to be displaied when clicked.
Remark: name must have the same entry in i18n files in dashboard object.
*******************************************************************************************************************/
const kpi_items = {
  assi:[ nbProcessingOrders
    , nbWaitingOrders
    , nbNoTrspInfoOrders
    , nbShortDepartures
    , nbWeekDepartures
    , nbTwoWeeksDepartures
    , nbMonthDepartures
    , nbBlockedOrders
    , nbLateInvoicesForAssi
  ],
  dcom:[ nbClients
    , CaNouveauxClients
    //, nbProspects
    , salesAmount
    , valueWaitingOrders
    , valueNoTrspInfoOrders
    , nbBlockedOrders
    , valueBlockedOrders
    , valuePendingInvoices
    , valueLateInvoices
    , nbLateInvoicesForCom
  ],
  com:[nbClients
    , CaNouveauxClients
    , nbProspects
    , salesAmount
    , valueWaitingOrders
    , valueNoTrspInfoOrders
    , nbBlockedOrders
    , valueBlockedOrders
    , valuePendingInvoices
    , valueLateInvoices
    , nbLateInvoicesForCom
  ],
  adm:[nbClients
    , CaNouveauxClients
    //, nbProspects
    , salesAmount
    , valueWaitingOrders
    , valueNoTrspInfoOrders
    , nbBlockedOrders
    , valueBlockedOrders
    , valuePendingInvoices
    , valueLateInvoices
    , nbLateInvoicesForCom
  ]
}

/*const kpi_items_old = {
  assi:[nbProcessingOrders,
        nbWaitingOrders,
        nbNoTrspInfoOrders,
        nbShortDepartures,
        nbWeekDepartures,
        nbTwoWeeksDepartures,
        nbMonthDepartures,
        nbBlockedOrders,
        nbLateInvoicesForAssi
  ],
  dcom:[nbClients,
    nbProspects,
    salesAmount,
    valueWaitingOrders,
    valueNoTrspInfoOrders,
    nbBlockedOrders,
    valueBlockedOrders,
    valuePendingInvoices,
    valueLateInvoices,
    nbLateInvoicesForCom
  ],
  com:[nbClients,
    nbProspects,
    salesAmount,
    valueWaitingOrders,
    valueNoTrspInfoOrders,
    nbBlockedOrders,
    valueBlockedOrders,
    valuePendingInvoices,
    valueLateInvoices,
    nbLateInvoicesForCom
  ],
  adm:[nbClients,
    nbProspects,
    salesAmount,
    valueWaitingOrders,
    valueNoTrspInfoOrders,
    nbBlockedOrders,
    valueBlockedOrders,
    valuePendingInvoices,
    valueLateInvoices,
    nbLateInvoicesForCom
  ]
}*/

const Indicateurs = (props) => {
    const dataProvider= useDataProvider();
    const translate = useTranslate();
    const locale = useLocale();

    // const [kpis, setKpis] = useState({
    //   isLoading:true,
    //   isError: false,
    //   data:[{id:0, count:0}]
    // });
    //
    // useEffect(()=> {
    //     dataProvider.getList((localStorage.profile.toLowerCase() === "assi"? "kpi_assi": 'kpi'),{
    //         pagination:{
    //             page:1,
    //             perPage:25
    //         },
    //         filter: Object.assign({}, util.getUserFilter()),
    //         sort:{
    //             // field:'',
    //             // order:''
    //         }
    //     })
    //     .then((data)=>{
    //         setKpis({
    //           isLoading:false,
    //           isError: false,
    //           data: data.data
    //       });
    //     })
    //     .catch((erreur)=>{
    //         //console.log(erreur);
    //         setKpis({
    //           isLoading:false,
    //           isError: true,
    //           data:[{id:0, count:0}]
    //         });
    //     });
    // }, []);
    //
    // const getKpiValue = (item) =>  {
    //   const kpi = kpis.data.find(key => key.id === item.name);
    //   if( kpi === undefined) return "N/A";
    //
    //   return isNaN(parseFloat(kpi.kpiValue))? kpi.kpiValue:
    //     new Intl.NumberFormat(locale, item.isCurrency? {style: 'currency', currency: 'EUR', maximumFractionDigits: 0}: {}).format(kpi.kpiValue)
    // };
    //
    // const getKpiKey = (item) =>  {
    //   const kpi = kpis.data.find(key => key.id === item.name);
    //   if( kpi === undefined) return "null";
    //
    //   return kpi.RNUM;
    // };

  return (
    // <div>
    //   {kpis.isLoading ?
    //     <Loader
    //          type="Oval"
    //          color="#0000FF"
    //          height={100}
    //          width={100}
    //       />
    //     :
        <Grid container spacing={1}>
          {kpi_items[localStorage.profile.toLowerCase()].map(item => (
            <Grid item key={item.name}>
            {/*
            <Grid item key={getKpiKey(item)}>
              <InfoBox
                to = {{
                  pathname: `${item.to}`,
                  search: `${item.search}`
                }}
                icon={item.icon}
                spanBgColor={item.bgColor}
                title= {translate(`pos.dashboard.${item.name}`)}
                value= { getKpiValue(item)
                }
              />
              */}
              <InfoBox
                to = {{
                  pathname: `${item.to}`,
                  search: `${item.search}`
                }}
                icon={item.icon}
                spanBgColor={item.bgColor}
                title= {translate(`pos.dashboard.${item.name}`)}
                value= { item.name }
                isCurrency={item.isCurrency}
              />
            </Grid>
          ))}
        </Grid>
//      }
//    </div>
)};

export default Indicateurs;
