import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    SaveButton,
    useCreate,
    useNotify,
    FormWithRedirect,
    SelectInput,
    useRefresh,
    ReferenceInput,
    TextInput,
    AutocompleteInput,
    Filter,
    useGetMany,
    useUnselectAll,
    Button,
    useTranslate,
    List,
    Datagrid,
    TextField
} from 'react-admin';
import {
  CardContent,
  DialogActions,
  Grid
} from '@material-ui/core';
import {Modal} from '../../modals/modals';
import IconContentAdd from '@material-ui/icons/Add';
import {LinkField} from '../../customComponents/LinkField';

const util = require('../../utils');

const BulkAddOffreButton = (props) => {
  const translate = useTranslate();
  const { data,loading, error } = useGetMany (
        'addProspectLookup',
         props.selectedIds
    );
  const [create] = useCreate('offres_prospect');
  const unselectAll = useUnselectAll();
  const refresh = useRefresh();
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        data[i]['id_offre'] = props.id;
        data[i]['id'] = null;
        create(
            { payload: { data: data[i] } },
            {
                onSuccess: ({ data }) => {
                    notify(translate("resources.offres.prospect.Succescli"),  'success' );
                    form.change('id', data.id);
                    unselectAll('prospect');
                    refresh();
                },
                onFailure: ( error ) => {
                    notify(typeof error === 'string' ? error : translate("resources.offres.prospect.Failcli"), 'warning' );
                    unselectAll('prospect');
                },
            }
          );
      }

    }
 }
  return (
    <Button
        label="resources.offres.button.AddPros"
        onClick={handleClick}
        disabled={loading}
    >
    </Button>
  );
};

function ListAddProspect(props) {
  return (
    <List
        resource="prospect"
        basePath="/prospect"
        perPage={10}
        exporter={false}
        currentSort={{field:"cl_rsoc1",order:"ASC"}}
        title=" "
        bulkActionButtons={<BulkAddOffreButton {...props}/>}
    >
      <Datagrid>
      <TextField label="resources.customers.fields.name" source="cl_rsoc1" />
      <TextField label="resources.customers.fields.city" source="cl_ville" />
      <TextField label="resources.customers.fields.country" source="cl_pays" />
      </Datagrid>
    </List>
  );
}
export default ListAddProspect;
