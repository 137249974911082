import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField
} from 'react-admin';

import {NoResult} from "../customComponents/NoResult";

export const PalList = (props) => {
  return(
      <List
      filter={{eq_st_code:"'"+props.id+"'"}}
      resource='palette'
      basePath='/palette'
      title={" "}
      hasList hasEdit hasShow
      empty={<NoResult/>}
      >
  <Datagrid>
      <TextField label="resources.products.fields.pal" source='t07_transcode' />
      <NumberField label="resources.products.fields.CarPal" source='rq_car_pal'/>
      <NumberField label="resources.products.fields.CartCou" source='rq_car_cou'/>
      <NumberField label="resources.products.fields.CouPal" source='cou_pal'/>
      <NumberField label="resources.products.fields.PoidPal" source='ms_poids'/>
      <NumberField label="resources.products.fields.Haut"source='hauteur'/>
      <NumberField label="resources.products.fields.Long"source='longueur'/>
      <NumberField label="resources.products.fields.Lar"source='largeur'/>

  </Datagrid>
  </List>
)};
