import frenchMessages from 'ra-language-french';

const exports = {
    ...frenchMessages,
    pos: {
        search: 'Rechercher',
        language: 'Langue',
        all: "Tous",
        no_data: "Aucun résultat",
        prospect:'Prospect',
        save:"Enregistrer",
        theme: {
            name: 'Theme',
            light: 'Clair',
            dark: 'Obscur',
        },
        dashboard: {
          name: "Tableau de bord",
          CaNouveauxClients:'CA nouveaux clients (24 mois)',
          earlyWinesEntries: "Entrées primeurs",
          marginAmount: 'Marge',
          marginPrecYear: 'Marge (n-1)',
          marginYear: 'Marge %{year}',
          marginYear_sm: '%{year}',
          marginYearN_2: "Marge (n-2)",
          marginYearN_3: "Marge (n-3)",
          monthOrders:" Commandes du mois",
          nbBlockedOrders: 'Cdes bloquées ATRADUS',
          nbClients: 'Clients actifs',
          nbLateInvoices: 'Factures échues (nbre)',
          nbLateOrders: 'Cdes en retard',
          nbMonthDepartures: 'Départs à 1 mois',
          nbNoTrspInfoOrders: 'Cdes sans infos trsp',
          nbPriorirityProducts: 'Produits prioritaires',
          nbProcessingOrders: 'Commande en cours',
          nbProspects: 'Prospects',
          nbShortSendingOrders: 'Enlèvements proches',
          nbTrspInfoOrders: 'Cdes avec enlèvement',
          nb2WeeksDepartures: 'Départs à 2 semaines',
          nbWaitingOrders: 'Cdes non facturées',
          nbWeekDepartures: 'Départs < 1 semaine',
          reserves: "Réserves",
          salesAmount: 'C.A. depuis 1er Jan',
          salesPrecYear: 'C.A. (n-1)',
          salesYear: 'C.A. %{year}',
          salesYearN_2: "C.A. (n-2)",
          salesYearN_3: "C.A. (n-3)",
          targetSuccess: '% objectif',
          turnover: "C.A.",
          valueBlockedOrders: 'C.A. cdes bloquées',
          valueLateInvoices: 'Factures échues (C.A.)',
          valueNoTrspInfoOrders: 'Cdes sans infos trsp',
          valuePendingInvoices: 'Encours comptable',
          valueWaitingOrders: 'C.A. en portfeuille',
          filters:{
            assist: "Assistante",
            country: "Pays",
            primeur: 'Primeurs',
            salesman: "Commercial",
            salesmgr: "Dir Commercial",
            zone: "Zone",
          },
          lists: {
            best_customers: ' Performance des 25 plus gros clients',
            last_customers: 'Les 25 derniers clients créés',
            month_orders: 'Cdes du mois ',
            produits_prioritaires:'Produits prioritaires',
          },
        },
        menu: {
            catalog: 'Catalogue',
            customers: 'Clients',
            offres: 'Offres',
            invoices: 'Factures',
            orders:'Commandes',
            prospect:'Prospects',
            categories:'Produits',
            configuration: 'Configuration',
            profile:'Profil'

        },
    },
    resources: {
        customers: {
            customer:'Client',
            fields: {
                accise_number:'Numéro Accise',
                accise_warehouse:'Accise Entrepôt',
                accounting_account: 'Compte compta',
                addExcel:"Ajouter le fichier excel ici",
                address_line_1: 'Adresse Postale',
                address_line_2: 'Adresse (suite)',
                add_offres:'Ajouter à une offre',
                all_taxes_year_income: 'CA (n)',
                assistant:"Assistante",
                city: 'Ville',
                client_type:'Type',
                client_category:'Catégorie',
                code: 'Code client',
                coface_account: 'Compte ATRADUS',
                col_n: "Col année",
                col_n1: 'Col n-1',
                col_n2: 'Col n-2',
                collective_account: 'Compte collectif',
                comment1: 'Documents d\'expédition',
                comment2: 'Spécificités client',
                comment3: 'Production',
                comment4: 'Autres informations',
                commercial:"Commercial",
                commercial_ceiling: 'Plafond commercial',
                commercialDirector:"Directeur Commercial",
                commercial_encour:'Cdes en portefeuille',
                comptable_encour:'Encours Comptable',
                contact:{
                  email: "Email",
                  firstName: "Prénom",
                  name: "Nom",
                  typeCode: "Type",
                  typeLabel: "Libellé"
                },
                correspondant: 'Corresp.',
                country: 'Pays',
                creation_date: 'Créé le',
                credit_indice: 'Indice crédit',
                domiciliation: 'Domiciliation',
                earlyWines: 'Primeurs non expédiés',
                email: 'Email',
                eori: 'EORI',
                etat: "Etat",
                exploitation:'Exploitation',
                exportObjectTarget:'Exporter objectif commercial',
                dataSave:"Données enregistrées",
                dataSaveError:"Problème lors de l'enregistrement",
                facebook:'Facebook',
                fax:'FAX',
                first_order: '1ère Cde',
                gamma_destination_type:'Type de destination gamma',
                history: "Historique",
                idInternational:'ID International',
                import:"Importer objectif commercial",
                incoterm_location:"Lieux de l'Incoterm",
                instagram:"Instagram",
                insurance_balance: 'Couv. crédit dispo',
                insurance_ceiling: 'Plafond assuré',
                insurance_folder: 'Dossier assurance',
                invoice_customer_code: 'Client fact.',
                last_modification: 'Dern. modif',
                last_order: 'Dern. Commande',
                last_products: "Produits commandés (36 dern. mois)",
                last_ten_orders: "10 dernières commandes facturées",
                last_user: 'Modifié par',
                linkedin:'Linkedin',
                MajListe:"Mettre à jour la liste",
                naf:'NAF',
                name: 'Nom',
                name2: 'Nom 2',
                objComm: 'Objectif commercial',
                offers_list: "Offres",
                order_list: "Commandes",
                orders: 'Nombre de Commandes',
                order_amount_n: 'C.A. %{year}',
                order_amount_n_1: 'Mtt cdes n-1',
                order_amount_n_1_d2d: "C.A. %{year} à date",
                order_amount_variation: 'Variation',
                order_amount_vpcent: '%',
                phone: 'Téléphone',
                previous_order:'Avant-dern. cde',
                promo_budget:'Budget promo',
                prospectComment1:'Informations Diverses',
                prospectComment2:'Offres/Echantillons',
                regroupement_country:'Regroupement de pays',
                reserve_list: "Réserves",
                retard_number:'Nombre de retard',
                s_commercial_encour:'portefeuille',
                sales_mgr: "D.C.",
                sales_n: "CA  n",
                sales_n1: "CA  n-1",
                sales_n2: "CA  n-2",
                sales_year: "CA %{year}",
                sample_list: "Echantillons sur 4 ans",
                sell_area:"Zone de vente",
                siren: 'SIREN',
                siret:'SIRET',
                social_reason_1:'Raison Sociale 1',
                social_reason_2:'Raison Sociale 2',
                statistical_area: "Sect. stat.",
                statut_client:"Statut client",
                tiktok:'Tik Tok',
                twitter:'Twitter',
                unpayed_number:"Nombre d'impayés",
                url: 'Adresse site internet',
                vat_number: 'N° TVA Intra',
                vol_n: 'Volume n',
                vol_n1: 'Volume n-1',
                vol_n2: 'Volume n-2',
                wechat:'WeChat',
                whatsapp:'Whats App',
                zipcode: 'Code postal',
                zone:'Zone',

                address: 'Rue',
                birthday: 'Anniversaire',
                stateAbbr: 'Etat',
                commands: 'Commandes',
                first_name: 'Prénom',
                first_seen: 'Première visite',
                groups: 'Segments',
                has_newsletter: 'Abonné à la newsletter',
                has_ordered: 'A commandé',
                last_name: 'Nom',
                last_seen: 'Vu le',
                last_seen_gte: 'Vu depuis',
                latest_purchase: 'Dernier achat',
                total_spent: 'Dépenses',
                password: 'Mot de passe',
                confirm_password: 'Confirmez le mot de passe',
                client_masque: 'Client masqué',
            },
            filters: {
              active: "- de 24 mois glissants",
              masque:"Client masqué",
              non_masque:"Client non masqué",
              oldest: "+ de 24 mois glissants",
              older: "+ de 12 mois glissants",
              old: "+ de 6 mois glissants",
            },
            fieldGroups: {
                identity: 'Identité',
                address: 'Adresse',
                stats: 'Statistiques',
                history: 'Historique',
                password: 'Mot de passe',
                change_password: 'Changer le mot de passe',
            },
            formBox: {
                accounting: "Informations comptables",
                biling_address:'Adresse de Facturation',
                contact: 'Contacts',
                contact_address:'Adresse Contact',
                credit_insurance: 'Assurance / Crédit',
                delivered_custs: "Clients livrés",
                delivery_address:'Adresse de livraison',
                identification:'Identification',
                identity: 'Identité',
                internal_info: 'Informations internes',
                international_information:'Information International',
                invoices: "Encours client",
                main_address: 'Adresse principale',
                retard_primeur: 'Factures Primeurs en retard',
                sales_info: 'Informations commerciales',
            },
            formTab:{
                activity:'Activity',
                agent:'Agent / Centrales',
                credit_insurance:'Assurance Crédit',
                contact:'Contact',
                customer_file:'Dossier Client',
                document:'Document',
                expedition:'Expedition',
                facturation:'Facturation',
                gencod:'Gencod',
                general:'Général',
                offers: "Offres",
                orders:'Commandes',
                paiement_facuration_condition:' Conditions de Paiement / Facturation',
                regulation:'Réglement',
                reserve:'Réserve',
                sales_view: "Vue comptable",
                social_network:'Réseaux Sociaux',
                statistics: 'Statistiques client',
            },
            page: {
                delete: 'Supprimer le client',
                create_prospect:'Nouveau Prospect',
            },
            errors: {
                password_mismatch:
                    'La confirmation du mot de passe est différent du mot de passe.',
            },
        },
        invoices: {
          fields: {
            amount: "Montant",
            amount_TTC: "Montant TTC",
            chateau: "Château",
            conditionnement:"Conditionnement",
            direction:"Créd/Déb",
            invoice_date: "Date facture",
            label: "Libellé",
            late: 'Retard',
            mill: "Millésime",
            number: 'Num pièce',
            term_date: "Date ech.",
          },
          filters: {
            all: "Tous",
            credit: "Crédit",
            debit: "Débit",
            month: "Dépassée depuis 30 jours et +",
            not_issued:"Non échues",
            term_date: "Non Payées",
            today: "Atteinte aujourd’hui",
            two_weeks: "Dépassée depuis 15 jours et +",
            week: "Dépassée depuis 7 jours et +",
            yesterday: "Dépassée depuis hier",
          },
        },
        order: {
            order: 'Commande',
        },
        offres:{
          offres:"Offres",
          offre:"Offre",
          title:{
            AddCli:"Ajout de client",
            AddOffre:"Création nouvelle offre",
            AddPros:"Ajout de prospect",
            AddProd:"Ajout d'un produit",
            SendEmail:"Envoi de l'offre",

          },
          button:{
            AddCli:"Ajouter client",
            AddOffre:"Créer une nouvelle offre",
            AddPros:"Ajouter prospect",
            AddProd:"Ajout d'un produit",
            SendEmail:"Envoyer l'offre",
            AddNewProd:"Ajouter un nouveau produit",
          },
          field:{
            by:"Créée par",
            cli:"Clients",
            code:"Code",
            codeProd:"Code produit",
            com:"Commercial",
            dateAdd:"Date création",
            dateEnd:"Date fin offre",
            format:"Format",
            lib:"Libelle",
            mill:"Millesime",
            prix:"P.U.",
            prod:"Produits",
            pros:"Prospects",
            qte:"Quantité",
            status:"Status"
          },
          filter:{
            annee:"Année",
            threeLastMonth:"3 derniers mois",
            thisMonth:'Mois en cours'
          },
          prod:{
            Succesprod:"Le(s) produit(s) ajouté(s)",
            Failprod:"produit(s) déjà ajoutés",
          },
          cli:{
            Succescli:"Client(s) ajouté(s)",
            Failcli:"Client(s) déjà ajouté(s)",
          },
          prospect:{
            Succescli:"Prospect(s) ajouté(s)",
            Failcli:"Prospect(s) déjà été ajouté(s)",
          },
          email:{
            bcc:"Cci",
            cc:"Cc",
            Emptymail:"Ajouter une adresse mail",
            Emptymessage:"Message vide",
            Failmail:"Echec de l'envoi",
            forward: "Tr: ",
            from: "De:",
            info:"Cette offre ne contient pas de produit",
            message:"Message",
            nonValidMail:"Une ou plusieurs adresses mails ne sont pas valide",
            objet:"Objet",
            otherFiles: "Ajouter des fichiers",
            replyTo: "Re: ",
            send:"Envoyer",
            subject: "Objet:",
            Succesmail:"Email envoyé",
            to:"À"
          }
        },
        files:{
          name: 'Fichier |||| Fichiers',
          fields: {
            subfile:"Dossiers",
            file:"Fichiers",
            addFile:"Ajouter des fichiers",
            addFileSuccess:"Ajout du fichier réussi",
            addFileFailure:"Erreur lors de l'ajout de fichier",
            noFile:"Vous ne possedez pas de fichiers pour ce produit"
          }
        },
        orders: {
            amount: '1 commande |||| %{smart_count} commandes',
            title: 'Commande n°%{reference} de %{client} pour %{liv}',
            listTitle: "Commandes en portefeuille",
            fields: {
              anomaly: 'Anomalie',
              colour: "Couleur",
              customer: 'Client',
              customer_order_num: 'Num Cde client',
              customer_order_ref: 'Ref Cde client',
              deliv_customer: 'Client Liv.',
              deliv_quantity: 'Qté livrée',
              eq75: "Eq 75cl",
              eq75_by_year: "Eq 75cl (%{year})",
              invoice: 'Num fact',
              invoiced_customer: "Client facturé",
              locked: 'Blocage',
              millesime: 'Millésime',
              number_of_articles: 'Nbre art.',
              number_of_packages: 'Nbre colis',
              order_num: 'Num Cde',
              parenthesis_code: "(code)",
              prmp: "PRMP",
              product: 'Produit',
              product_name: 'Libellé',
              qty: 'Qté',
              qty_by_year: "Qté (%{year})",
              quantity: 'Quantité',
              quantity_exp: 'Qté expédiée',
              quantity_reserved: 'Qté réellement réservée',
              reference: 'Num Cde.',
              reserve_lig: 'Ligne',
              reserve_number: 'Num. Rés',
              step: 'Avancement',
              solde: "Solde",
              sale_price_by_col_year: "PV/Col (%{year})",
              sale_price_eq75_year: "PV/Eq 75cl (%{year})",
              total_pr: "Total PR",
              turnover: 'C.A. (HT)',
              turnover_by_year: "C.A. (%{year})",
              type: "Type",
              unit_price: "P.U",
              dates: {
                customer_order_date:'Date Cde fournie par le client',
                expected_delivery: 'Date Livraison Souhaitée',
                exped_date: "Date exped.",
                exped_mode: 'Date passage expédiable',
                expiration: 'Date Expiration Offre',
                input: 'Date cde',
                invoice: 'Date facture',
                takeoff: 'Date enlèvement',
                update: 'Modification',
              },
            },
            formBox: {
              details: "Détails",
              order_lines: "Lignes de commande",
            },
            filters:{
              active: "Actives",
              billing: "Facturations",
              blocked: "Bloquées",
              credit_note: "Avoirs",
              departure: "Départs dans",
              late: "En retard",
              mise: "Mises",
              month_dep: "Le mois",
              pending: "En cours",
              processing: "En cours",
              reserve: "Réserves",
              reserve_client: "Rés. client",
              sample: "Echantillons",
              short: "Les 2 jours",
              status: "Etat",
              two_weeks_dep: "Les 2 semaines",
              type: "Types Cdes",
              unactive: "Inactives",
              uninvoiced: "Non facturées",
              waiting: "En attente",
              week_dep: "La semaine",
              wo_transport_info: 'Sans info. trsp.',
              yearly: "Depuis 1er Janv",
            },
            section: {
                order: 'Commande',
                customer: 'Client',
                shipping_address: 'Adresse de livraison',
                items: 'Articles',
                total: 'Total',
            },
        },
        order_lines: {
          fields:{
            format: "Format",
            line_number: "Ligne",
            millesime: "Millesime",
            product: "Produit",
            quantity: "Qté",
            total_price: "Prix total",
            unit_price: "P.U.",
          },
        },
        products: {
            title: {
              ProdFWD:'Produits Fine Wine Desk',
              ProdPrim:'Produits Primeurs',
              ProdTarif:'Produits Petit château',
              ProdHorsTarif:'Historique Produits',
              ConditProd:'Conditionnement du produit',
              history: "Historique par secteur statistique et client"
            },
            button: {
              ListProd:'Liste des produits',
              ConditProd:'Conditionnement',
              fiche_prod:'Fiche produit',
              SendProd:'Envoyer produit par email'
            },
            fichier: {
              photo:'Déposez les photos à uploader, ou cliquez pour en sélectionner',
              pdf:'Déposez les PDF à uploader, ou cliquez pour en sélectionner',
              autre:'Déposez les fichiers à uploader, ou cliquez pour en sélectionner'
            },
            fields: {
                appell:'Appellation',
                art:'Article',
                autre:'Autre',
                AutreProd:'Autre code produit',
                Avancement:'%',
                Caisse:'Caissage',
                CarPal:'Cartons par palette',
                CouPal:'Couches par palette',
                CArt:'Code article',
                CartCou:'Cartons par couche',
                chateau:'Château',
                CliLiv:'Client livré',
                code: "Code",
                com:'Commercial',
                condit:'Conditionnement',
                Cprod:'Caractéristiques de l\'article',
                CreerOffre:'Création d\'une offre',
                customId: "Code douane",
                DateLiv:'Date livraison',
                Dim:'Dimensions',
                DMF:'Délai moy. de fab',
                Docs: "Documents",
                Dprod:'Détails du produits',
                eq75: "Stock (Eq 75cl)",
                eq75_avail: "Stock disponible (Eq 75cl)",
                Etat:'Etat',
                FL:'Fiche logistique',
                fileName: "Fichier",
                folder: "Sous-dossier de CHATEAUX_et_VINS",
                fp:'Fiche produit',
                FWD:'Fine Wine Desk',
                gencode_article: "Gencode Article",
                gencode_bottle: "Gencode Bouteille",
                Haut:'Hauteur',
                image: 'Photos',
                infos:'Informations',
                isDirectory: "Dossier",
                isFile: "Fichier",
                Lar:'Largeur',
                last_customers:"Clients ayant commandé dans les 4 ans",
                libBouch:'Bouchon',
                libBout:'Bouteille',
                libCondit:'Caisse',
                libCoul:'Couleur',
                libHab:'Habillage',
                libRegie:'Regie',
                Long:'Longueur',
                marge:'Marge',
                margepp:'Marge PP',
                mark:'Marketing',
                mill:'Millésime',
                MS:'Matières Sèches',
                nbr:'Bout/Caisse',
                NouvOffre:'Créer une nouvelle offre',
                Objectif:'Objectif',
                P:'Poids',
                P_S:'Produit sur stock',
                P_V:'Poids & Volume',
                pal:'Palette',
                Palettisation:'Palettisation',
                PC:'Produit Composé',
                pdf:'PDF',
                photo:'Photo',
                pictures: "Images",
                PoidPal:'Poids palette complète',
                PoidT:'Poids Total',
                PoidU:'Poids unitaire',
                Prim:'Primeurs',
                Prix: 'Prix',
                PrixF:'Prix fixe',
                PrixInv:'Prix inventaire',
                PrixMod:'Prix modifié',
                PrixP:'Prix plancher',
                PrixPT:'Px pilote tarif',
                PrixV:'Prix de vente',
                PRMP:'P.R.M.P',
                prod:'Produits',
                prodFiles: "Images et documents",
                prodstats:'Stats',
                Qte:'Quantité',
                QteAcc:'Qte acceptée',
                QteIni:'Qte initiale',
                QteSupp:'Qte supp',
                res:'Réserves',
                res75:'Réserves (Eq 75cl)',
                Salarm:'Stock alarme',
                sale_price_by_col_year: "PV/Col (%{year})",
                Smax:'Stock max',
                Smin:'Stock min',
                Srent:'Seuil de rentabilité',
                Stat:'Statistiques',
                stock:'Stock',
                stock_avail: "Stock disponible",
                Tarif:'Tarif',
                HorsTarif:'Historique Produits',
                Tarifé:'Petit château et hors tarif',
                Tarifi:'Tarification',
                Type:'Type',
                Vol:'Cent',
                Volume:'Volume',
                deg:'Degre',
                gen:'Gencod'
            },
            tabs: {
                image: 'Image',
                details: 'Détails',
                description: 'Description',
                reviews: 'Commentaires',
                statistics: 'Statistiques produit',
            },
            filters: {
              average_sellers: 'Moyennes',
              average_stock: '10 - 49 unités',
              best_sellers: 'Meilleures ventes',
              categories: 'Catégories',
              enough_stock: '50 unités et plus',
              low_sellers: 'Peu vendu',
              low_stock: '1 - 9 unités',
              never_sold: 'Jamais vendu',
              no_stock: 'En rupture',
              range0: "Sans millésime ",
              range1: "Inférieur ou égal à 5 ans",
              range2: "De 6 à 10 ans",
              range3: "De 11 à 15 ans",
              rangeUp: "Plus de 15 ans",
              sales: 'Ventes',
              stock: 'Stock'
            },
        },
        categories: {

            fields: {
                products: 'Produits',
            },
        },
        reviews: {
            name: 'Commentaire |||| Commentaires',
            amount: '1 commentaire |||| %{smart_count} commentaires',
            relative_to_poster: 'Commentaire sur',
            detail: 'Détail du commentaire',
            fields: {
                customer_id: 'Client',
                command_id: 'Commande',
                product_id: 'Produit',
                date_gte: 'Publié depuis',
                date_lte: 'Publié avant',
                date: 'Date',
                comment: 'Texte',
                status: 'Statut',
                rating: 'Classement',
            },
            action: {
                accept: 'Accepter',
                reject: 'Rejeter',
            },
            notification: {
                approved_success: 'Commentaire approuvé',
                approved_error: 'Erreur: Commentaire non approuvé',
                rejected_success: 'Commentaire rejeté',
                rejected_error: 'Erreur: Commentaire non rejeté',
            },
        },
        segments: {
            name: 'Segment |||| Segments',
            fields: {
                customers: 'Clients',
                name: 'Nom',
            },
            data: {
                compulsive: 'Compulsif',
                collector: 'Collectionneur',
                ordered_once: 'A commandé',
                regular: 'Régulier',
                returns: 'A renvoyé',
                reviewer: 'Commentateur',
            },
        },
        stats:{
            layout:{
                buttonName: 'Stats'
            },
            months:['jan.', 'fev.','mar.','avr.','mai','juin','juil.','aou','sept.','oct.','nov.','déc.'],
        },
        users:{
          fields: {
            code: "Code utilisateur",
            first_name: "Prénom",
            id: "identifiant",
            last_name: "Nom",
            mail_pwd: "Mot de passe messagerie",
            mail_user: "Identifiant messagerie",
            password: "Changer le mot de passe"
          },
        },
        loading:{
            loading:"Chargement en cours",
            wait:"Veuillez patienter...",
        }
    },
};
export default exports;
