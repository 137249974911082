import * as React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  ExportButton,
  FileField,
  FileInput,
  Filter,
  FilterButton,
  FormTab,
  FormWithRedirect,
  List,
  ListButton,
  NumberField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar
} from 'react-admin';


import LinearProgress from '@material-ui/core/LinearProgress';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  useGetList,
  useListContext,
  useNotify,
  useRefresh
} from "react-admin";

import MobileCustomers from './mobileCustomers';
import { useMediaQuery } from '@material-ui/core';
import AsideListCustomers from './AsideListCustomers';
import CustomerStatButton from "./statistique";
import { CustomerEditStatButton } from "./statistique";
import { ColoredSignLinkField, ColoredSignNumberField } from '../customComponents/ColoredField';
import { LinkField } from '../customComponents/LinkField';
import { email } from 'react-admin/lib';
import { Typography, Box } from '@material-ui/core';

import { useTranslate } from 'react-admin';
import { useState } from 'react';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import NotificationsIcon from '@material-ui/icons/ModeCommentOutlined';
import StatusIcon from "@material-ui/icons/MenuBook";

import { ClientNotifications } from '../notification/clientNotifications';
import { ClientMails } from '../mails/mails';

import { ContactShow, GeneralShow } from './General';
import { SocialNetworkShow } from './socialMedia';
import { CustomerFileShow } from './customersFile';
import { SalesShow } from './sales';
import { Contact } from './Contact';

import { Modal } from '../modals/modals';
import IconContentAdd from '@material-ui/icons/Add';
import AddCustomers from './addCustomersOffres';

import { NoResult } from "../customComponents/NoResult";

import ReactExport from "react-export-excel";
import { Update } from "@material-ui/icons";
import { URL_SERVER_REST } from '../confProvider';
import ImportExportIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

//import { ExportButton } from "../customComponents/ExportButton";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const util = require('../utils');

const CustomerTitle = ({ record }) => {
  return <span>{record ? `${record.cl_rsoc1}` : ''}</span>;
}


function setUserFilter() {
  // ticket 251: obligé d'enlever les filtres additionnels pour gérer correctement la requête SQL.
  // les filtrs sur T75_TYPCL, cl_dercde et cl_code ont été mis directement dans la requête
  // return Object.assign({}, util.getUserFilter(), {ne_T75_TYPCL: "'2'", isnotnull_cl_dercde: "",
  //                                                           notlike_cl_code: "'89%'"});
  return Object.assign({}, util.getUserFilter());
}

const CustomerFilter = (props) => {
  const [dataFilter, setDataFilter] = useState({});      // filter on data retreive from server

  const userFilter = setUserFilter();

  const onSalesMgrChange = (item) => {
    let tmpFilter = dataFilter;
    if (item === "" || item === null || item.target.value === undefined || item.target.value === "" || item.target.value === null) delete tmpFilter["eq_ta3_libre"];
    else {
      Object.assign(tmpFilter, { "eq_ta3_libre": "'" + item.target.value + "'" });
    }
    setDataFilter(tmpFilter);
  }

  return (
    <Filter {...props}>
      {(userFilter.profile === "dcom" || userFilter.profile === "adm") &&
        <ReferenceInput label="resources.customers.fields.sales_mgr" source="likeic_b0100.ta3_libre" filter={userFilter}
          reference="sales_mgr" allowEmpty={true} resettable={false} emptyText={`pos.all`}
          alwaysOn sort={{ field: "ta_lib", order: "ASC" }}
          onChange={onSalesMgrChange}
        >
          <SelectInput optionText="ta_lib" />
        </ReferenceInput>
      }
      <ReferenceInput label="resources.customers.fields.country" source="likeic_b0100.t76_pays" filter={Object.assign({}, userFilter, dataFilter)}
        reference="country" allowEmpty={true} resettable={false} emptyText={`pos.all`}
        alwaysOn sort={{ field: "ta_lib", order: "ASC" }}
        perPage={10000}
      >
        <SelectInput optionText="ta_lib" />
      </ReferenceInput>
      <ReferenceInput label="resources.customers.fields.statistical_area" source="likeic_b0100.t72_secsta"
        filter={Object.assign({}, userFilter, dataFilter)} reference="statistical_area" allowEmpty={true} resettable={false} emptyText={`pos.all`}
        alwaysOn sort={{ field: "TA_LIB", order: "ASC" }}
        perPage={10000}
      >
        <SelectInput optionText="TA_LIB" />
      </ReferenceInput>
      <ReferenceInput label="resources.customers.fields.commercial" source="likeic_b0100.ta7_libre"
        filter={Object.assign({}, userFilter, dataFilter)} reference="salesman" allowEmpty={true} resettable={false} emptyText={`pos.all`}
        alwaysOn sort={{ field: "comm.ta_lib", order: "ASC" }}
        perPage={10000}
      >
        <SelectInput optionText="ta_lib" />
      </ReferenceInput>
      <TextInput label="resources.customers.fields.name" source="likeic_b0100.cl_rsoc1" alwaysOn />
      <BooleanInput label="resources.customers.fields.client_masque" source="eq_COALESCE(client_masque, false)" alwaysOn />
    </Filter>
  );
}

const ExportTarget = (props) => {
  // Warning: the label (excel column headers) must be the same as database fields names
  const userFilter = setUserFilter();
  const translate = useTranslate();
  const { data:customers } = useGetList(
    props.resource,
    { page: 1, perPage: props.total },
    { field: 'b0100.cl_rsoc1', order: 'ASC' },
    Object.assign({}, userFilter, { eq_cl_acti: "'0'" }, props.filterValues),
  );
  return(
  <ExcelFile
    element={
      <Button startIcon={<GetAppIcon />} size="small" color="primary" >
          <Typography variant="p" gutterBottom color='primary' >
              {translate("resources.customers.fields.exportObjectTarget")}
          </Typography>
      </Button>
    }
  >
      <ExcelSheet data={Object.values(customers)} name="Customers">
          <ExcelColumn label="Code" value="cl_code"/>
          <ExcelColumn label="nom" value="cl_rsoc1"/>
          <ExcelColumn label="ville" value="cl_ville"/>
          <ExcelColumn label="objectif_commercial" value="objectif_commercial"/>
      </ExcelSheet>
  </ExcelFile>
)};


const ImportList = (props) => {
  const translate = useTranslate();
  return(
    <Modal  title={translate("resources.customers.fields.MajListe")}
            buttonIcon={<ImportExportIcon />}
            buttonText={translate("resources.customers.fields.import")}
            {...props} width="lg"
    >
      <UpdateList {...props} />
    </Modal>
  )
}

const UpdateList = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const handleSend = async values => {
    const formData = new FormData();

    formData.append('datafiles' ,values.fichier.rawFile);
    fetch(URL_SERVER_REST + `/customersImportList`,
        {
          method: 'PUT',
          body: formData,
          credentials: 'include',
        })
        .then(res => res.json())
        .then((response) => {
          //console.log(response);
          if (response === "OK") {
            notify(translate("resources.customers.fields.dataSave") , 'success');
            refresh();
          } else {
            notify(translate("resources.customers.fields.dataSaveError"), 'warning');
          }
        })
  }
  return(
    <FormWithRedirect
    save={handleSend}
    render={({
      handleSubmitWithRedirect,
      pristine
    }) => (
      <form>
    <FileInput label="resources.customers.fields.addExcel" source="fichier">
    <FileField source="src" title="title" />
  </FileInput>
  <SaveButton label="pos.save"
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
              />
  </form>
      )}
    />
  )
}
const CustomListToolbar = (props) => (
  <TopToolbar {...props} >
    {/* <ExportButton {...props}/> */}
    <ExportTarget {...props}/>
    <ImportList {...props}/>
  </TopToolbar>
);
/************************************************************************************
 list for dashboards
 **********************************************************************************/
export const LastCreatedCustomerList = props => {
  const translate = useTranslate();
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const userFilter = setUserFilter();

  return (
    <List
      filters={isSmall ? <CustomerFilter /> : undefined}
      {...props}
      resource={props.resource} basePath={props.basePath} filter={Object.assign({}, userFilter, { idUt: localStorage.getItem('id') })} {...props}
      hasCreate hasEdit hasList hasShow
      title={translate("pos.dashboard.name")}
      perPage={25}
      bulkActionButtons={false}
      actions={false}
      pagination={null}
      sort={{ field: "CL_DATCRE", order: "DESC" }}
    >
      {isXsmall ? (
        <MobileCustomers />
      ) : (
        <Datagrid>
          <LinkField label="resources.customers.fields.name" source="CL_RSOC1" linkValue="id" basePath="/customers" resource="customers" />
          <TextField label="resources.customers.fields.country" source="CL_PAYS" />
          <DateField label="resources.customers.fields.creation_date" source="CL_DATCRE" />
          <CustomerStatButton linkValue="id" />
        </Datagrid>
      )}
    </List>
  )
}

export const ObjectifsAvancementListe = props => {
  const translate = useTranslate();
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const userFilter = setUserFilter();

  return (
    <List
      filters={isSmall ? <CustomerFilter /> : undefined}
      {...props}
      resource={props.resource} basePath={props.basePath} filter={Object.assign({}, userFilter, { idUt: localStorage.getItem('id') })} {...props}
      hasCreate hasEdit hasList hasShow
      title={translate("pos.dashboard.name")}
      perPage={500}
      bulkActionButtons={false}
      actions={false}
      pagination={null}
      sort={{ field: "avancement", order: "DESC" }}
    >
      {isXsmall ? (
        <MobileCustomers />
      ) : (
        <Datagrid>
          <TextField label="resources.products.fields.chateau" source="BA_LIB"  />
          <NumberField label="resources.products.fields.Volume" source="VOLUME" />
          <NumberField label="resources.products.fields.Objectif" source="OBJECTIF" />
          <NumberField label="resources.products.fields.Avancement" source="AVANCEMENT" />
        </Datagrid>
      )}
    </List>
  )
}

export const BestOrdersCustomerList = props => {
  const translate = useTranslate();
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const userFilter = setUserFilter();
  return (
    <List
      filters={isSmall ? <CustomerFilter /> : undefined}
      resource={props.resource} basePath={props.basePath}
      filter={Object.assign({}, userFilter, { idUt: localStorage.getItem('id') })} {...props}
      hasCreate hasEdit hasList hasShow
      title={' '}
      bulkActionButtons={false}
      perPage={25}
      actions={false}
      loading={null}
      sort={{ field: "cd_amount_n", order: "DESC" }}
      pagination={null}
      empty={<NoResult />}
    >
      {isXsmall ? (
        <MobileCustomers />
      ) : (
        <Datagrid>
          <ColoredSignLinkField label="resources.customers.fields.name" source="CL_RSOC1" test_value="variation" linkValue="cl_code" basePath="/customers" resource="customers" />
          <NumberField source="CD_AMOUNT_N_1" label={translate("resources.customers.fields.order_amount_n", { year: new Date().getFullYear() - 1 })}
            options={{ maximumFractionDigits: 0, style: 'currency', currency: 'EUR' }} />
          <NumberField source="CD_AMOUNT_N_1_D2D" label={translate("resources.customers.fields.order_amount_n_1_d2d", { year: new Date().getFullYear() - 1 })}
            options={{ maximumFractionDigits: 0, style: 'currency', currency: 'EUR' }} />
          <NumberField label={translate("resources.customers.fields.order_amount_n", { year: new Date().getFullYear() })}
            source="CD_AMOUNT_N" locales="fr-FR" options={{ maximumFractionDigits: 0, style: 'currency', currency: 'EUR' }} />
          <ColoredSignNumberField label="resources.customers.fields.order_amount_variation" source="VARIATION"
            options={{ maximumFractionDigits: 0, style: 'currency', currency: 'EUR' }} textAlign="right" />
          <ColoredSignNumberField label="resources.customers.fields.order_amount_vpcent" source="V_PCENT" options={{ style: 'percent' }} textAlign="right" />
          <CustomerStatButton linkValue="cl_code" />
        </Datagrid>
      )}
    </List>
  )
}

/************************************************************************************
 End list for dashboards
**********************************************************************************/

export const CustomerList = props => {
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));
  //const userFilter =util.getUserFilter();
  const translate = useTranslate();
  const userFilter = setUserFilter();
  const {isLoading} = useListContext();

  if(isLoading) return ( <Loader/> );
  return (
    <List
      filter={Object.assign({}, userFilter, { eq_cl_acti: "'0'" })}
      filters={<CustomerFilter />}
      filterDefaultValues={{["eq_COALESCE(client_masque, false)"]:false}} 
      bulkActionButtons={false}
      {...props}
      hasEdit hasList
      aside={isLarge ? <AsideListCustomers /> : null}
      sort={{ field: "b0100.cl_rsoc1", order: "ASC" }}
      actions={<CustomListToolbar {...props}/>}
    >
      {isXsmall ? (
        <MobileCustomers />
      ) : (
        <Datagrid >
          <LinkField label="resources.customers.fields.name" source="cl_rsoc1" linkValue="cl_code" {...props} />
          <TextField label="resources.customers.fields.country" source="pays.ta_lib" />
          {userFilter.profile === "dcom" &&
            <TextField label="resources.customers.fields.sales_mgr" source="dcomm.ta_lib" />
          }
          <TextField label="resources.customers.fields.statistical_area" source="secsta.ta_lib" />
          <TextField label="resources.customers.fields.commercial" source="comm.ta_lib" />
          <NumberField label={translate("resources.customers.fields.sales_year", { year: new Date().getFullYear() - 1 })} source="cl_catta1"
            options={{ style: "currency", currency: "EUR", maximumFractionDigits: 0 }} />
          <NumberField label={translate("resources.customers.fields.order_amount_n_1_d2d", { year: new Date().getFullYear() - 1 })} source="cl_catta1_d2d"
            options={{ style: "currency", currency: "EUR", maximumFractionDigits: 0 }} />
          <NumberField label={translate("resources.customers.fields.sales_year", { year: new Date().getFullYear() })} source="cl_cattan"
            options={{ style: "currency", currency: "EUR", maximumFractionDigits: 0 }} />
          <DateField label="resources.customers.fields.last_order" source="cl_dercde" />
          <LinearWithValueLabel label="resources.customers.fields.objComm" source="objectif_commercial" {...props}/>
          <BooleanField emptyText="false" source="client_masque" label={translate("resources.customers.fields.client_masque")} TrueIcon={DoneIcon} FalseIcon={CloseIcon} />
          <TextField label="resources.customers.fields.statut_client" source="libelle_statut_client" />
          <CustomerStatButton linkValue="cl_code" />
        </Datagrid>
      )}
    </List>
  )
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">

      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" color="secondary" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

function LinearWithValueLabel(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {props.record.objectif_commercial ?
        <LinearProgressWithLabel value={((props.record.cl_cattan*100)/props.record.objectif_commercial)} />
        :
        <></>
      }
    </div>
  );
}
const AddCustomersOffres = props => {
  const translate = useTranslate();
  return (
    <Modal title={translate("resources.offres.title.AddCli")}
      buttonIcon={<IconContentAdd />}
      {...props}
      buttonText={translate("resources.customers.fields.add_offres")}
    >
      <AddCustomers {...props} />
    </Modal>
  );
}
const CustomToolbar = props => {
  //console.log(props);
  return (
    <Toolbar {...props} >
      <SaveButton redirect={props.redirect} disabled={false} />
      <ListButton basePath={props.basePath} />
      <CustomerEditStatButton linkValue="cl_code" cl_rsoc1={props.cl_rsoc1 !== undefined ? props.cl_rsoc1 : props.record.cl_rsoc1} />
      <AddCustomersOffres {...props} />
    </Toolbar>
  )
};

export const CustomerEdit = (props) => {
  // const notify = useNotify();
  // const refresh = useRefresh();
  // const redirect = useRedirect();
  const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));

  //console.log('errors',errors)
  const [openNotification, setNotificationOpen] = useState(false);
  const handleNotificationDrawer = () => {
    setNotificationOpen(!openNotification);
  }

  // const [openMail, setMailOpen] = useState(false);
  // const handleMailDrawer = () => {
  //   setMailOpen(!openMail);
  // }

  // const onSuccess = ({ data }) => {
  //   notify(`Changes to customer saved`)
  //   redirect('/customers');
  //   refresh();
  //   getWebsocket().methods.newMessage("Message automatique : Modifications effectuées sur le client " + getClientName(props.id), props.id);
  // };
  //console.log(props);
  return (
    <>
      <Edit title={<CustomerTitle />} undoable={true} mutationMode="pessimistic" {...props} >
        <TabbedForm redirect={props.location.pathname} toolbar={<CustomToolbar id={props.id} {...props} cl_rsoc1={props.cl_rsoc1} />}>
          <FormTab label='resources.customers.formTab.general' >
            <GeneralShow />
          </FormTab>
          {!isLarge &&
            <FormTab label='resources.customers.formTab.contact' >
              <ContactShow />
            </FormTab>
          }
          <FormTab label='resources.customers.formTab.customer_file'>
            <CustomerFileShow />
          </FormTab>
          <FormTab label='resources.customers.formTab.sales_view'>
            <SalesShow idCli={props.id} {...props} />
          </FormTab>
          <FormTab label='Contacts'>
            <Contact {...props} />
          </FormTab>
          <FormTab label='resources.customers.formTab.social_network'>
            <SocialNetworkShow />
          </FormTab>
          {/* <FormTab label='Emails'>
              <ClientMails id={props.id} type="customers"/>
            </FormTab> */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleNotificationDrawer}
          >
            <NotificationsIcon />
          </IconButton>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleMailDrawer}
          >
            <MailIcon />
          </IconButton> */}
        </TabbedForm>

      </Edit>
      <Drawer
        anchor="right"
        open={openNotification}
        onClose={handleNotificationDrawer}
      >
        <ClientNotifications id={props.id} />
      </Drawer>
      {/* <Drawer
        anchor="right"
        open={openMail}
        onClose={handleMailDrawer}
      >
        <ClientMails id={props.id} type="customers" />
      </Drawer> */}
    </>
  );
}

const CustomerView = (record, props) => {
  const translate = useTranslate();

  const validateEmail = email();

  return (
    <Box >
      <Box display="flex">
        <Box flex={3} mr="1em">
          <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.formBox.identity')}</Typography>

          <TextInput {...props} required source="cl_rsoc1" label='resources.customers.fields.name' fullWidth />
          <TextInput  {...props} source="cl_rsoc2" label='resources.customers.fields.name2' fullWidth />

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput {...props} source="cl_code" label='resources.customers.fields.code' disabled={true} />
            </Box>
            <Box flex={2} ml="0.5em">
              <TextInput color="primary" defaultValue={false} source="cl_code_ftc" label='resources.customers.fields.invoice_customer_code' />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput source="cl_vatnum" label='resources.customers.fields.vat_number' />
            </Box>
            <Box flex={1} ml="0.5em" mr="0.5em">
              <TextInput source="cl_siren" label='resources.customers.fields.siren' />
            </Box>
            <Box flex={1} ml="0.5em" >
              <TextInput source="cl_num_eori" label='resources.customers.fields.eori' />
            </Box>
          </Box>

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <DateInput InputProps={{ readOnly: true }} style={{ fontStyle: 'italic' }} color="secondary" source="cl_premcde" label='resources.customers.fields.first_order' />
            </Box>
            <Box flex={2} ml="0.5em">
              <DateInput InputProps={{ readOnly: true }} style={{ fontStyle: 'italic' }} color="secondary" source="cl_dercde" label='resources.customers.fields.last_order' fullWidth />
            </Box>
          </Box>


          <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.contact')}</Typography>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput {...props} source="cl_tel" label='resources.customers.fields.phone' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em" mr="0.5em">
              <TextInput {...props} validate={validateEmail} source="ta3_libre" label='resources.customers.fields.commercialDirector' fullWidth />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} ml="0.5em" mr="0.5em">
              <TextInput {...props} validate={validateEmail} source="cl_email" label='resources.customers.fields.email' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em" mr="0.5em">
              <TextInput {...props} source="ta7_libre" label='resources.customers.fields.commercial' fullWidth />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} ml="0.5em" mr="0.5em">
              <TextInput {...props} source="cl_url" label='resources.customers.fields.url' fullWidth />
            </Box>
            <Box flex={1} ml="0.5em" mr="0.5em">
              <TextInput {...props} source="ta1_libre" label='resources.customers.fields.assistant' fullWidth />
            </Box>
          </Box>
        </Box>
        <Box flex={2} ml="1em">
          <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.main_address')}</Typography>
          <TextInput {...props} source="cl_adr1" label='resources.customers.fields.address_line_1' fullWidth />
          <TextInput {...props} source="cl_adr2" label='resources.customers.fields.address_line_2' fullWidth />

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput source="cl_cp" label='resources.customers.fields.zipcode' />
            </Box>
            <Box flex={1} ml="0.5em" >
              <TextInput source="cl_ville" label='resources.customers.fields.city' fullWidth />
            </Box>
          </Box>
          <TextInput source='cl_pays' label='resources.customers.fields.country' />

        </Box>
      </Box>
    </Box>
  );
}

export const CustomerCreate = (props) => {

  return (
    <Create undoable={false} title={<CustomerTitle />}  {...props} >
      <SimpleForm >
        <CustomerView />
      </SimpleForm>
    </Create>
  );
}
const exports = {
  list: CustomerList,
  edit: CustomerEdit,
}
export default exports;
