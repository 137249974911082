import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { useLocale, useSetLocale, Title } from 'react-admin';
import {configStyles} from '../style.js';


const Configuration = () => {
    const locale = useLocale();
    const setLocale = useSetLocale();
    const classes = configStyles();
    return (
        <Card>
            <Title title={"Configuration"} />
            <CardContent>
                <div className={classes.label}>
                    Theme
                </div>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={locale === 'light' ? 'primary' : 'default'}
                    onClick={() => setLocale('light')}
                >
                    clair
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={locale === 'dark' ? 'primary' : 'default'}
                    onClick={() => setLocale('dark')}
                >
                    obscur
                </Button>
            </CardContent>
            <CardContent>
                <div className={classes.label}>Langue</div>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={locale === 'en' ? 'primary' : 'default'}
                    onClick={() => setLocale('en')}
                >
                    en
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={locale === 'fr' ? 'primary' : 'default'}
                    onClick={() => setLocale('fr')}
                >
                    fr
                </Button>
            </CardContent>
        </Card>
    );
};

export default Configuration;
