import * as React from 'react';
import { forwardRef } from 'react';
import { useEffect } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {URL_BO} from '../confProvider';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { ModalNotifications } from "../modals/modals";
import {initWebsocket, getWebsocket } from "../websocket/websocket";
import Logo from './logo';
import { grey } from '@material-ui/core/colors';
const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('pos.menu.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const ProfileMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/profile"
            primaryText={translate('pos.menu.profile')}
            leftIcon={<AccountCircleIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const BusinessObjects = forwardRef((props, ref) => {
    return (
        <MenuItem
            ref={ref}
        >
            <a href={URL_BO} style={{display:'inline-flex',color:grey[500]}} target="_blank">
                <ApartmentIcon/>
            </a>
        </MenuItem>
    );
});

const CustomUserMenu = (props) => {
  let icon =(
    <>
      <AccountCircleIcon />
      <Typography variant="h6" style={{ margin: "0 0 0 10px", fontSize:"15px"}}>{localStorage.getItem("firstName")}</Typography>
    </>
  );
  return(
    <UserMenu {...props} icon={icon}>
        <ProfileMenu/>
        <ConfigurationMenu />
    </UserMenu>
)};

const CustomAppBar = (props) => {
    const classes = useStyles();
    useEffect(()=>{
    initWebsocket();
},[]);
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logo />
            <span className={classes.spacer} />
            <BusinessObjects />
            {getWebsocket().isInitiated && <ModalNotifications />}
        </AppBar>
    );
};

export default CustomAppBar;
