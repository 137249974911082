/*
  Le but des sémaphores est d'empêcher les modifications / lectures simultanées
  Dans le cas de React où les composants peuvent se rafraîchir plusieurs fois d'affilée,
  les hooks d'état peuvent ne pas avoir le temps de s'actualiser et des appels de fonctions
  inutiles peuvent être effectués
  Enrober la condition et la fonction dans un sémaphore permet d'attendre la fin d'une exécution
  avant de lancer la suivante
*/

function Semaphore(max) {
  var counter = 0;
  var waiting = [];

  var take = function() {
    if (waiting.length > 0 && counter < max){
      counter++;
      let promise = waiting.shift();
      promise.resolve();
    }
  }

  this.acquire = function() {
    if(counter < max) {
      counter++
      return new Promise(resolve => {
      resolve();
    });
    } else {
      return new Promise((resolve, err) => {
        waiting.push({resolve: resolve, err: err});
      });
    }
  }

  this.release = function() {
   counter--;
   take();
  }

  this.purge = function() {
    let unresolved = waiting.length;

    for (let i = 0; i < unresolved; i++) {
      waiting[i].err('Task has been purged.');
    }

    counter = 0;
    waiting = [];

    return unresolved;
  }
}



export const semaphoredFunction = (func, max) => {
  let sema = new Semaphore(max);
  return async () => {
    try {
      await sema.acquire();
      let result = await func();
      setTimeout(() => {
        sema.release();
      }, 500);
      return result;
    } catch (e) {
      console.error("SemaphoredFunctionError : ", e);
    }
  }
}
