import {
    useCreate,
    useNotify,
    useRefresh,
    useUnselectAll,
    useTranslate,
    useGetList,
    Button,
    List,
    Datagrid,
    TextField,
    Loading,
    Error
} from 'react-admin';
import {LinkField} from '../../customComponents/LinkField';
import Typography from '@material-ui/core/Typography';

const BulkAddFileButton = props => {
  const results = [];
  const translate = useTranslate();
  const [create] = useCreate('fichiers');
  const unselectAll = useUnselectAll();
  const refresh = useRefresh();
  const notify = useNotify();
  const data = Object.values(props.data);

  if (data) {
    data.map(item => {
      for (var i = 0; i < props.selectedIds.length; i++) {
        if (item.id === props.selectedIds[i]) {
           results.push({
             id_lignes_offres: props.id,
             libelle: item.libelle,
             contenu_b64: null ,
             chemin: item.chemin
           });
        }
      }
    })
  }
  const handleClick = () => {
    if (results.length > 0) {
      for (let i = 0; i < results.length; i++) {
        create(
          { payload: { data: results[i] } },
          {
              onSuccess: ({ results }) => {
                  notify(translate("resources.files.fields.addFileSuccess"),'success')
                  unselectAll('dossiers');
                  refresh();
              },
              onFailure: ( error ) => {
                //console.log(error);
                  notify(translate("resources.files.fields.addFileFailure"), 'warning')
                  unselectAll('dossiers');
              },
          }
        )
      }
    }
 }
  return (
    <Button
        label="resources.files.fields.addFile"
        onClick={handleClick}
    >
    </Button>
  );
};

const FilesEdit = (props) => {
  const translate = useTranslate();
  const { data, loading , error } = useGetList(
        'dossiers',
        { page: 1, perPage: 10 },
        { field: 'id', order: 'ASC' },
        {eq_libelle_produit:props.record.libelle_produit}
    );
    if (loading) { return <Loading />; }
    if (error) {  return <p><Typography variant="h6">{translate("resources.files.fields.noFile")}</Typography></p>;}
    return(
      <List
          {...props}
          resource="dossiers"
          basePath="/dossiers"
          filter={{eq_libelle_produit:props.record.libelle_produit}}
          perPage={10}
          exporter={false}
          title=" "
          bulkActionButtons={<BulkAddFileButton data={data} {...props}/>}

      >
        <Datagrid >
          <TextField label="resources.files.fields.subfile" source="subfile" />
          <TextField label="resources.files.fields.file" source="libelle" />
        </Datagrid>
      </List>
)};
export default FilesEdit;
