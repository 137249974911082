import { Datagrid, TextField, List } from "react-admin"

export const Contact = (props) => {
  return(
    <List
      resource='contacts'
      basePath='/contacts'
      sort={{ field: "TA_CODE", order: "ASC" }}
      filter={{eq_CL_CODE: "'" + props.id + "'"}}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="TA_CODE" label="resources.customers.fields.contact.typeCode" />
        <TextField source="TA_LIB" label="resources.customers.fields.contact.typeLabel" />
        <TextField source="CTT_NOM" label='resources.customers.fields.contact.name'  />
        <TextField source='CTT_PRENOM' label='resources.customers.fields.contact.firstName'/>
        <TextField source='CTT_EMAIL' label='resources.customers.fields.contact.email' />
      </Datagrid>
    </List>
  );
}
