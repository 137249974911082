import React, {useState, useEffect} from "react";
import { Datagrid, TextField } from 'react-admin';
import { URL_SERVER_REST } from '../confProvider';
import { setRequestOptions, __assign } from '../dataProvider';
import {fetchUtils} from 'react-admin';
import { stringify } from 'query-string';
import { semaphoredFunction } from './semaphores'
var _ = require('lodash');
const { inspect } = require('util')

function difference(origObj, newObj) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0
    return _.transform(newObj, function (result, value, key) {
      if (!_.isEqual(value, origObj[key])) {
        let resultKey = _.isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (_.isObject(value) && _.isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }
  return changes(newObj, origObj)
}

export const DatagridTotalLine = (props) => {
  var {data, ids, resource, filterValues, fieldsToTotalize, bulkActionButtons, ...rest} = props;
  fieldsToTotalize = fieldsToTotalize||[];
  const [totalLineRecord, setRecord] = useState({});
  const [memoized, setMemoized] = useState({});
  const [savedFilterValues, setSavedFV] = useState({});
  const [refreshKey, setKey] = useState(0);

  /*  Fonction memoize
  *     @param key (obligatoire) :
  *         La clé désignant la donnée que l'on souhaite enregistrer, écraser
  *           ou récupérer
  *     @param getValue (facultatif) :
  *         Valeur ou fonction permettant de récupérer une valeur dans le cas
  *           d'un enregistrement/écrasement
  *     @param options (facultatif) :
  *       options.overwrite (facultatif) :
  *         Booléen désignant si la valeur transmise doit écraser ou non
  *           la valeur déjà enregistrée s'il y en a une
  *       options.storeAsFunction (facultatif) :
  *         Booléen désignant si la valeur transmise doit être stockée comme fonction (true) ou
  *           si elle doit être exécutée pour stocker son résultat (false)
  *     @returns Object
  *         Valeur désignée par la clé ou valeur nouvellement injectée
  */
  const memoize = (key, getValue, options) => {
    //Si on a rien mémorisé ou qu'on souhaite l'écraser
    if(getValue && (!memoized[key] || options.overwrite === true)){
      let value = (typeof getValue === 'function' && !options.storeAsFunction ? getValue() : getValue);
      /*console.log(
        memoized[key]===undefined ?
        "Memoized : (key : "+key+", value : "+value+")" :
        "Overwrote : (key : "+key+", old value : "+memoized[key]+", new value : "+value+")"
      );*/
      setMemoized({...memoized, [key]: value, lastChange: key});
      return value;
    }else{
      // console.log("Accessed : (key : "+key+", memoized value : "+memoized[key]+")");
      return memoized[key];
    }
  }
  const unmemoize = (key) => {
    // console.log("Unmemoized : (key : "+key+", old value : "+memoized[key]+")");
    setMemoized({...memoized, [key]: undefined, lastChange: key});
  };
  //fetchData fetches the total row but is heavy
  const fetchData = async () => {
    let toReturn = {};

    for (var fieldRowNum in fieldsToTotalize) {
      let row = fieldsToTotalize[fieldRowNum];

      var query =  __assign(
        __assign(
          {},
          fetchUtils.flattenObject(filterValues),
          fetchUtils.flattenObject(props.filter)
        ),
        {
          idUt:localStorage.getItem("id"),
          "@PROPSUMS":`${row.propriete} as ${row.as}`
        }
      );
      let answer = fetch(
        `${URL_SERVER_REST}/${row.resource}?`
        + stringify(query),
        {
          method: 'GET',
          ...setRequestOptions()
        }
      );
      toReturn[row.as] = (await (await answer).json())[0][row.as];
    }
    return toReturn;
  };
  /*
    semaFetchData calls fetchData when needed and with one execution at a time
    This might use some explanations
    fetchData is an heavy function, so we don't want it to be called multiple times
    But as the component gets refreshed really quickly, the memoization doesn't have time to react,
    So that the condition is pratically useless
    So we've put a semaphore (go to semaphores.js for further explanations)
    to force the executions to be one at a time
    When an execution finishes, the memoized data refreshes, and the condition finally does its job
    TADAAA
  */
  const semaFetchData = semaphoredFunction(async ()=>{
    let allData = memoize("allData");
    if(!allData){
      try {
        let res = await fetchData();
        //console.log(res);
        allData = memoize("allData", res, {overwrite : true});
        return allData;
      } catch (e) {
        //console.log(e);
      }
    }
  }, 10);

  useEffect(()=>{
    // console.log("Runned filter check");
    let pureFilterValues = JSON.parse(JSON.stringify(filterValues));
    let diff = inspect(difference(savedFilterValues, pureFilterValues), {depth: null});
    if(diff!=="{}"){
      // console.log("filterValues changed : ", diff);
      setSavedFV(pureFilterValues);
      unmemoize("allData");
    }
  },[filterValues]);

  useEffect(()=>{
    // console.log("Runned main");
    let newIndex = memoize("newIndex",()=>{return Math.floor(Math.random() * 1e10).toString(16)}, {overwrite : false});
    let record = {[newIndex]:{}};

    /*Let the user know it is loading*/
    for (let fieldNo in fieldsToTotalize) {
      let fieldName = fieldsToTotalize[fieldNo].as || fieldsToTotalize[fieldNo].propriete;
      record[newIndex][fieldName] = "Loading...";
    }
    record[newIndex].nonExistant = "Total:";
    record.id = newIndex;
    setRecord(record);

    /*Now we'll load the actual data*/
    semaFetchData()
    .then((res)=>{
      let allData = memoize("allData")||res;
      // console.log(allData);
      for (let fieldNo in fieldsToTotalize) {
        let field = fieldsToTotalize[fieldNo];
        let fieldName = field.as || field.propriete;
        if (allData && field && allData[fieldName] && !isNaN(parseFloat(allData[fieldName]))) {
          record[newIndex][fieldName]=parseFloat(allData[fieldName]);
        }
      }
      setRecord(record);
      setKey(refreshKey+1);
    })
    .catch((e)=>{console.log(e);});

  }, [savedFilterValues]);

  return(
    <Datagrid
      {...{data: {...data, ...totalLineRecord}, ids: ids.concat(totalLineRecord.id), ...rest}}
      key={refreshKey}
    >
      <TextField label=" " source="nonExistant" sortable={false} textAlign="center" />
      {props.children}
    </Datagrid>
  );
};
