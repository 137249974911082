import React, { useEffect, useState } from 'react';
import {
  useNotify,
  Edit,
  useGetList,
  useRefresh,
  useUpdate,
  FormWithRedirect,
  TextInput,
  SaveButton,
  useTranslate,
  required,
  useDataProvider,
  FileInput,
  FileField
} from 'react-admin';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, DialogActions, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import RichTextInput from 'ra-input-rich-text';
import { URL_SERVER_REST } from '../../confProvider';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const Email = (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [update] = useUpdate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [files, setFiles] = useState([]);

  const getData = () => {
    dataProvider.getList(('products_files'), {
      pagination: {
        page: 1,
        perPage: 10
      },
      filter: { eq_id_offre: props.record.id_offre },
      sort: { field: 'id', order: 'ASC' }
    })
      .then((dataFiles) => {
        //console.log(dataFiles.data);
        setFiles([...dataFiles.data])

      })
      .catch((erreur) => {
        //console.log("erreur", erreur);
        setFiles([])
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const { data } = useGetList(
    'lignes_offres',
    { page: 1, perPage: 10 },
    { field: 'id', order: 'ASC' },
    { eq_id_offre: props.record.id_offre }
  );

  /* Récupération destinataires email OFFR1 = A:, OFFRx = CC: */
  let to = [];
  let cc = [];

  const { data: contacts } = useGetList(
    'contacts',
    { page: 1, perPage: 255 },
    { field: "TA_CODE", order: "ASC"},
    { eq_CL_CODE: "'" + props.record.cl_code + "'" }
  );
  if (Object.entries(contacts).length > 0) {
    to = Object.values(contacts).filter(item => item.TA_CODE.trim() === 'OFFR1').map(item => item.CTT_EMAIL.trim());
    cc = Object.values(contacts).filter(item => item.TA_CODE.substr(0, 4) === "OFFR" && item.TA_CODE.substr(4, 1) !== '1').map(item => item.CTT_EMAIL.trim());
  }
  else to.push(props.record.email)

  const { data: get_internal } = useGetList(
    'getInternalEmails',
    { page: 1, perPage: 255 },
    { field: "id", order: "ASC"},
    { eq_cl_code: "'" + props.record.cl_code + "'"}
  );

  if (localStorage.profile === "ADM") {
    if (Object.entries(get_internal).length > 0) {
      Object.values(get_internal).map(item => {
        if(item.mail_assistante !== null) cc.push(item.mail_assistante);
        if(item.mail_commercial !== null) cc.push(item.mail_commercial);
      })
    }
  }
  else if (localStorage.profile === "DCOM") {
    if (Object.entries(get_internal).length > 0) {
      Object.values(get_internal).map(item => {
        if(item.mail_assistante !== null) cc.push(item.mail_assistante);
        if(item.mail_commercial !== null) cc.push(item.mail_commercial);
      })
    }
  }
  else if (localStorage.profile === "COM") {
    if (Object.entries(get_internal).length > 0) {
      Object.values(get_internal).map(item => {
        if (item.mail_assistante !== null) cc.push(item.mail_assistante);
      })
    }
  }
  else if (localStorage.profile === "ASSI") {
    if (Object.entries(get_internal).length > 0) {
      Object.values(get_internal).map(item => {
        if (item.mail_commercial !== null) cc.push(item.mail_commercial);
      })
    }
  }

  const handleSend = async values => {
    const formData = new FormData();

    if(values.fichier !== undefined){
      values.fichier.forEach((item) => {
        formData.append('datafiles' ,item.rawFile);
      });
    };
    let incorrectEmails = [];
    let incorrectCc = [];
    let incorrectBcc = [];

    if (!values.com) {
      values.com = localStorage.firstName + " " + localStorage.lastName
    };

    if (values.emails) {
      if (!Array.isArray(values['emails'])) {
        values['emails'] = values['emails'].trim().replace(/ /g, '').split(',');
      }
      incorrectEmails = values['emails'].filter((emails) => {
        return !((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(emails));
      });
    }

    if (values.cc) {
      if (!Array.isArray(values['cc'])) {
        values['cc'] = values['cc'].trim().replace(/ /g, '').split(',');
      }
      incorrectCc = values['cc'].filter((cc) => {
        return !((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(cc));
      });
    }
    else values.cc = [];

    if (values.bcc) {
      if (!Array.isArray(values['bcc'])) {
        values['bcc'] = values['bcc'].trim().replace(/ /g, '').split(',');
      }
      incorrectCc = values['bcc'].filter((bcc) => {
        return !((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(bcc));
      });
    }
    else values.bcc = [];

    if ((incorrectEmails.length === 0) && (incorrectCc.length === 0) && (incorrectBcc.length === 0)) {
      formData.append('email', JSON.stringify(values['emails']))
      formData.append('bcc', JSON.stringify(values['bcc']));
      formData.append('cc', JSON.stringify(values['cc']));
      formData.append('subject',values['subject'])
      formData.append('message',values['message'])
      formData.append('com',values['com'])
      formData.append('products',JSON.stringify(data))
      formData.append('id',localStorage.id)
      formData.append('file',JSON.stringify(files))

      fetch(URL_SERVER_REST + `/mail/exchange`,
        {
          method: 'POST',
          body: formData,
          credentials: 'include',
        })
        .then(res => res.json())
        .then((response) => {
          //console.log(response);
          if (response.msg == "NoError") {
            update('offres_prospect', props.id, { status: 4 }, props.record);
            notify(translate("resources.offres.email.Succesmail"), 'success');
            refresh();
          } else {
            notify(translate("resources.offres.email.Failmail"), 'warning');
          }
        })
      // .catch(values=>console.log(values));

    } else {
      notify(translate("resources.offres.email.nonValidMail"), 'warning');
    };
  };

  return (
    <FormWithRedirect
      save={handleSend}
      render={({
        handleSubmitWithRedirect,
        pristine
      }) => (
        <form>
          <Box display="flex" >
            <Box fullWidth mr="0.5em">
              <TextInput label="resources.offres.email.to" type="email" source="emails" defaultValue={to.toString()} required fullWidth />
              <TextInput label="resources.offres.email.cc" type="email" source ="cc" defaultValue={cc.toString()} fullWidth />
              <TextInput label="resources.offres.email.bcc" type="email" source="bcc" fullWidth />
              <TextInput label="resources.offres.email.objet" source="subject" defaultValue={props.record.libelle} required fullWidth />
              <FileInput label="resources.offres.email.otherFiles" source="fichier" multiple>
                <FileField source="src" title="title" />
              </FileInput>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {translate(
                        'resources.files.fields.file'
                      )}
                    </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody >

                  {files.map(item =>
                    <TableRow>
                      <TableCell  >{item.libelle} </TableCell>
                      <TableCell>
                        <IconButton >
                          <DeleteIcon onClick={() => setFiles(files.filter((ids) => ids.id !== item.id))}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <RichTextInput validate={required()} source="message" />
            </Box>
          </Box>
          <DialogActions>
            {Object.keys(data).length === 0 ?
              <Alert severity="info">{translate("resources.offres.email.info")}</Alert>
              :
              <SaveButton label="resources.offres.email.send" icon={<SendIcon />}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
              />
            }
          </DialogActions>
        </form>
      )}
    />
  );
}

export const OffresCreateMails = (props) => {
  const data = useGetList(
    'products_files',
    { page: 1, perPage: 10 },
    { field: 'id', order: 'ASC' },
    { eq_id_offre: props.record.id_offre }
  );
  if (props.resource === "offres_prospect") {
    return (
      <Edit {...props}
        component="div"
        resource='offresEmailProspect'
        basePath='/offresEmailProspect'
        title=" "
        style={{ width: 'inherit' }}
      >
        <Email files={data} {...props} />
      </Edit>

    )
  } else if (props.resource === "offres_clients") {
    return (
      <Edit {...props}
        component="div"
        resource='offresEmailClients'
        basePath='/offresEmailClients'
        title=" "
        style={{ width: 'inherit' }}
      >
        <Email files={data} {...props} />
      </Edit>
    )
  }
}
