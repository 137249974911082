import * as React from 'react';
import { CardContent } from '@material-ui/core';
import { FilterList, FilterListItem } from 'react-admin';

import DepartureIcon from "@material-ui/icons/FlightTakeoff";
import StatusIcon from "@material-ui/icons/MenuBook";

import {
  addDays,
  addMonths,
  addWeeks,
} from 'date-fns';

import {Card} from '../style.js';

const StatusFilter = () => (
  <FilterList label="resources.orders.filters.status" icon={<StatusIcon />} >
    <FilterListItem label="resources.orders.filters.yearly"
      value={{gte_ce_datfac: " TO_DATE('01/01/" + new Date().getFullYear() + "', 'DD/MM/YYYY')",
              ce_numfac: undefined,
              ce_datimp: undefined,
              ce_datenl: undefined,
              "com.t42_ancde": undefined }}
    />
    <FilterListItem label="resources.orders.filters.pending"
      value={{gte_ce_datfac: undefined,
              ce_numfac: " IS NOT NULL",
              ce_datimp: undefined,
              ce_datenl: undefined,
              "com.t42_ancde": undefined }}

    />
    <FilterListItem label="resources.orders.filters.uninvoiced"
      value={{gte_ce_datfac: undefined,
              ce_numfac: " IS NULL",
              ce_datimp: undefined,
              ce_datenl: undefined,
              "com.t42_ancde": " = '00'"}}
    />
    <FilterListItem label="resources.orders.filters.waiting"
      value={{gte_ce_datfac: undefined,
              ce_numfac: " IS NULL",
              ce_datimp: " = TO_DATE('31/12/' || EXTRACT(YEAR FROM CURRENT_DATE), 'DD/MM/YYYY')",
              ce_datenl: undefined,
              "com.t42_ancde": " = '00'" }}
    />
    <FilterListItem label="resources.orders.filters.wo_transport_info"
      value={{gte_ce_datfac: undefined,
              ce_numfac: undefined,
              ce_datimp: undefined,
              ce_datenl: " IS NULL",
              "com.t41_avcde": " in ('30', '35', '40', '55','60', '65', '68')",
              "com.t42_ancde": " = '00'" }}
    />
    <FilterListItem label="resources.orders.filters.blocked"
      value={{gte_ce_datfac: undefined,
              ce_numfac: undefined,
              ce_datimp: undefined,
              ce_datenl: undefined,
              "com.t42_ancde": " IN ('L0', 'L5')" }}
    />
  </FilterList>
)

const DepartureFilter = () => (
  <FilterList label="resources.orders.filters.departure" icon={<DepartureIcon />} >
    <FilterListItem label="resources.orders.filters.short"
      value={{gte_ce_datenl: "'" + new Date(new Date().getFullYear(),
                                                  new Date().getMonth(),
                                                  new Date().getDate(), 0)
                                                  .toISOString() + "'",
              lte_ce_datenl: "'" + addDays(new Date(new Date().getFullYear(),
                                              new Date().getMonth(),
                                              new Date().getDate(), 0), 2)
                                              .toISOString() + "'",
              "com.t32_typ": " IN ('01')"}}
    />
    <FilterListItem label="resources.orders.filters.week_dep"
      value={{gte_ce_datenl: "'" + new Date(new Date().getFullYear(),
                                        new Date().getMonth(),
                                        new Date().getDate(),
                                        new Date().getHours(), 0)
                                        .toISOString() + "'",
              lte_ce_datenl: "'" + addWeeks(new Date(new Date().getFullYear(),
                                              new Date().getMonth(),
                                              new Date().getDate(),
                                              new Date().getHours(), 0), 1)
                                              .toISOString() + "'",
              "com.t32_typ": " IN ('01')"}}
    />
    <FilterListItem label="resources.orders.filters.two_weeks_dep"
      value={{gte_ce_datenl: "'" + new Date(new Date().getFullYear(),
                                                  new Date().getMonth(),
                                                  new Date().getDate(),
                                                  new Date().getHours(), 0)
                                                  .toISOString() + "'",
              lte_ce_datenl: "'" + addWeeks(new Date(new Date().getFullYear(),
                                              new Date().getMonth(),
                                              new Date().getDate(),
                                              new Date().getHours(), 0), 2)
                                              .toISOString() + "'",
              "com.t32_typ": " IN ('01')"}}
    />
    <FilterListItem label="resources.orders.filters.month_dep"
      value={{gte_ce_datenl: "'" + new Date(new Date().getFullYear(),
                                                  new Date().getMonth(),
                                                  new Date().getDate(),
                                                  new Date().getHours(), 0)
                                                  .toISOString() + "'",
              lte_ce_datenl: "'" + addMonths(new Date(new Date().getFullYear(),
                                              new Date().getMonth(),
                                              new Date().getDate(),
                                              new Date().getHours(), 0), 1)
                                              .toISOString() + "'",
              "com.t32_typ": " IN ('01')"}}
    />
  </FilterList>
)

const Aside = (props) => {
    return(
    <Card>
      <CardContent>
{/* ***********     suite remarques sprint 7
        <ActiveFilter />
        <TypeFilter />
***********************  */}
        <StatusFilter />
        <DepartureFilter />
      </CardContent>
    </Card>
)};

export default Aside;
