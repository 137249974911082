import * as React from "react";
import {
  Box,
} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {useState, useEffect} from 'react';
import {
  useDataProvider,
  useLocale,
  useTranslate  } from 'react-admin';
import Loader from 'react-loader-spinner';

import {
  FormDataConsumer,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import {graphiqueStyles} from '../style.js';

const util = require('../utils');

const Graphique = (props) => {
  const classes = graphiqueStyles();
  const dataProvider= useDataProvider();
  const locale = useLocale();
  const translate = useTranslate();
  const [state, setState] = useState({
      primeur: true,
    });

  const [data, setData] = useState({
    isLoading:true,
    isError: false,
    data:[]
  });
  const [margins, setMargins] = useState({
    isLoading:true,
    isError: false,
    data:[]
  });

  const [dataFilter, setDataFilter] = useState({});      // filter on data retreive from server
  var componentFilter = {}; // filter on component to link each other

  let userFilter =util.getUserFilter();

  const numberFormatter = item => new Intl.NumberFormat(locale, {maximumFractionDigits: 0, style: 'currency', currency: 'EUR'}).format(item);

  const getSales = () => {
    setData({
      isLoading:true,
      isError: false
    });
    dataProvider.getList(('sales_data'),{
      pagination:{
          page:1,
          perPage:25
      },
      sort:{
          field:null,
          order:null
      },
      filter: Object.assign({}, userFilter, dataFilter)
    })
    .then((results)=>{
      setData({
        isLoading:false,
        isError: false,
        data: results.data
      });
    })
    .catch((erreur)=>{
      //console.log(erreur);
      setData({
        isLoading:false,
        isError: true,
        data:[{id:0, count:0}]
      });
    });
  };

  // margin data
  const getMargins = () => {
    setMargins({
      isLoading:true,
      isError: false
    });
    dataProvider.getList(('margin_data'),{
      pagination:{
          page:1,
          perPage:25
      },
      sort:{
          field:null,
          order:null
      },
      filter: Object.assign({}, userFilter, dataFilter)
    })
    .then((results)=>{
      setMargins({
        isLoading:false,
        isError: false,
        data: results.data
      });
    })
    .catch((erreur)=>{
      //console.log(erreur);
      setMargins({
        isLoading:false,
        isError: true,
        data:[{id:0, count:0}]
      });
    });
  };


  const getData = () => {
    // if(userFilter.profile.toLowerCase() === "com")
    getMargins();
    getSales();
  };

  const onAssistantChange = (item) => {
    let tmpFilter = dataFilter;
    if(item === "" || item === null || item.target.value === undefined || item.target.value === "" || item.target.value === null) delete tmpFilter["eq_ta1_libre"];
    else {
      Object.assign(tmpFilter, {"eq_ta1_libre": "'" + item.target.value + "'"}); // eslint-disable-line
    }
    setDataFilter(tmpFilter);
    //console.log("assist", dataFilter)
   getData();
  }

  const onCountryChange = (item) => {
    let tmpFilter = dataFilter;
    if(item === "" || item === null || item.target.value === undefined || item.target.value === "" || item.target.value === null) delete tmpFilter["eq_t76_pays"];
    else {
      Object.assign(tmpFilter, {"eq_t76_pays": "'" + item.target.value + "'"}); // eslint-disable-line
    }
    setDataFilter(tmpFilter);
    getData();
  }

  const onPrimeurChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    let tmpFilter = dataFilter;
    if(event.target.checked) delete tmpFilter["notin_com.t32_typ"];
    else Object.assign(tmpFilter, {"notin_com.t32_typ": "('EX', 'AX', 'FX', 'RX', '04', '13', 'SX', 'XA', 'XR', 'XF', 'X4')"}); // eslint-disable-line
    setDataFilter(tmpFilter);
    getData();
  };

  const onSalesmanChange = (item) => {
    let tmpFilter = dataFilter
    if(item === "" || item === null || item.target.value === undefined || item.target.value === "" || item.target.value === null) delete tmpFilter["eq_ta7_libre"];
    else {
      Object.assign(tmpFilter, {"eq_ta7_libre": "'" + item.target.value + "'"}); // eslint-disable-line
    }
    setDataFilter(tmpFilter);
    getData();
  }

  const onSalesMgrChange = (item) => {
    let tmpFilter = dataFilter;
    if(item === "" || item === null || item.target.value === undefined || item.target.value === "" || item.target.value === null) delete tmpFilter["eq_ta3_libre"];
    else {
      Object.assign(tmpFilter, {"eq_ta3_libre": "'" + item.target.value + "'"}); // eslint-disable-line
    }
    setDataFilter(tmpFilter);
    getData();
  }

  useEffect(()=> {
    if(state.primeur) delete dataFilter["notin_com.t32_typ"];
    else Object.assign(dataFilter, {"notin_com.t32_typ": "('EX', 'AX', 'FX', 'RX', '04', '13', 'SX', 'XA', 'XR', 'XF', 'X4')"});
    getData();
    //eslitnt-ignore-next-line
  }, []);

  return (
      <FormWithRedirect {...props} render={(formProps) => (
          <form>
              <Box display={{ md: 'block', lg: 'flex' }} >
                <Box flex={12} mr={{ md: 0, lg: '1em' }} mt={{xs:0, sm: 0}}>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={3} ml={{ xs: 0, sm: '2em' }} >
                      {(localStorage.profile.toLowerCase() === "dcom" || localStorage.profile.toLowerCase() === "adm") &&
                        <ReferenceInput label="pos.dashboard.filters.salesmgr"
                          source="dcomm.ta_code" reference="sales_mgr"
                          onChange={onSalesMgrChange}
                          filter={userFilter}
                          allowEmpty={true} resettable={false}
                          emptyText= {`pos.all`}
                          formClassName={classes.inlineBlock}
                          sort={{field: "dcomm.ta_lib", order: "ASC"}}
                        >
                          <SelectInput optionText="ta_lib" />
                        </ReferenceInput>
                      }
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }} >
                      <FormDataConsumer>
                        {({formData, ...rest}) => {
                          if(formData.dcomm && formData.dcomm.ta_code) Object.assign(componentFilter, {"eq_ta3_libre": "'" + formData.dcomm.ta_code + "'"});// eslint-disable-line
                          else delete componentFilter["eq_ta3_libre"];
                          if(formData.comm && formData.comm.ta_code) Object.assign(componentFilter, {"eq_ta7_libre": "'" + formData.comm.ta_code + "'"});// eslint-disable-line
                          else delete componentFilter["eq_ta7_libre"];
                          if(formData.assist && formData.assist.ta_code) Object.assign(componentFilter, {"eq_ta1_libre": "'" + formData.assist.ta_code + "'"});// eslint-disable-line
                          else delete componentFilter["eq_ta1_libre"];
                          return(
                            <ReferenceInput label="pos.dashboard.filters.country"
                              source="t76_pays" reference="country"
                              onChange={onCountryChange}
                              filter={Object.assign({}, userFilter, componentFilter)}
                              formClassName={classes.inlineBlock}
                              allowEmpty={true} resettable={false}
                              emptyText= {`pos.all`}
                              sort={{field: "ta_lib", order: "ASC"}}
                              perPage={10000}
                            >
                              <SelectInput optionText="ta_lib" />
                            </ReferenceInput>
                        )}}
                      </FormDataConsumer>
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }}>
                      <FormDataConsumer>
                        {({formData, ...rest}) => {
                          if(formData.dcomm && formData.dcomm.ta_code) Object.assign(componentFilter, {"eq_ta3_libre": "'" + formData.dcomm.ta_code + "'"});// eslint-disable-line
                          else delete componentFilter["eq_ta3_libre"];
                          if(formData.comm && formData.comm.ta_code) Object.assign(componentFilter, {"eq_ta7_libre": "'" + formData.comm.ta_code + "'"});// eslint-disable-line
                          else delete componentFilter["eq_ta7_libre"];
                          if(formData.assist && formData.assist.ta_code) Object.assign(componentFilter, {"eq_ta1_libre": "'" + formData.assist.ta_code + "'"});// eslint-disable-line
                          else delete componentFilter["eq_ta1_libre"];
                          return(
                            <ReferenceInput label="pos.dashboard.filters.salesman"
                              source="comm.ta_code" reference="salesman"
                              allowEmpty={true} resettable={false}
                              emptyText= {`pos.all`}
                              onChange={onSalesmanChange}
                              filter={Object.assign({}, userFilter, componentFilter)}
                              formClassName={classes.inlineBlock}
                              sort={{field: "ta_lib", order: "ASC"}}
                              perPage={10000}
                            >
                              <SelectInput optionText="ta_lib" />
                            </ReferenceInput>
                          )}}
                      </FormDataConsumer>
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }}>
                      <FormDataConsumer>
                        {({formData, ...rest}) => {
                          if(formData.dcomm && formData.dcomm.ta_code) Object.assign(componentFilter, {"eq_ta3_libre": "'" + formData.dcomm.ta_code + "'"});// eslint-disable-line
                          else delete componentFilter["eq_ta3_libre"];
                          if(formData.comm && formData.comm.ta_code) Object.assign(componentFilter, {"eq_ta7_libre": "'" + formData.comm.ta_code + "'"});// eslint-disable-line
                          else delete componentFilter["eq_ta7_libre"];
                          if(formData.assist && formData.assist.ta_code) Object.assign(componentFilter, {"eq_ta1_libre": "'" + formData.assist.ta_code + "'"});// eslint-disable-line
                          else delete componentFilter["eq_ta1_libre"];
                          return(
                            <ReferenceInput label="pos.dashboard.filters.assist"
                              source="assist.ta_code" reference="assistant"
                              allowEmpty={true} resettable={false}
                              emptyText= {`pos.all`}
                              onChange={onAssistantChange}
                              filter={Object.assign({}, userFilter, componentFilter)}
                              formClassName={classes.inlineBlock}
                              sort={{field: "assist.ta_lib", order: "ASC"}}
                              perPage={10000}
                            >
                              <SelectInput optionText="ta_lib" />
                            </ReferenceInput>
                        )}}
                      </FormDataConsumer>
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: "2em"}}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={state.primeur}
                            onChange={onPrimeurChange}
                            name="primeur"
                            color="primary"
                          />
                        }
                        label={`${translate("pos.dashboard.filters.primeur")}`}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box flex={12} mr={{ md: 0, lg: '1em' }} mt={{xs:0, sm: 0}}>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={6} mr={{ xs: 0, sm: '2em' }}>
                    {data.isLoading ?
                      <Loader
                           type="Oval"
                           color="#392722"
                           height={100}
                           width={100}
                        />
                      :
                      <ResponsiveContainer width="99%" height={250}>
                        <LineChart data={data.data}>
                          <XAxis dataKey="id" />
                          <YAxis tickFormatter={numberFormatter} width={100} />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Line type="monotone" dataKey="sales_n_3" name={translate("pos.dashboard.salesYear", {year: new Date().getFullYear()-3})} stroke="#48d1cc"  />
                          <Line type="monotone" dataKey="sales_n_2" name={new Date().getFullYear()-2} stroke="#ff7f50"  />
                          <Line type="monotone" dataKey="sales_prec_year" name={new Date().getFullYear()-1} stroke="#82ca9d"  />
                          <Line type="monotone" dataKey="sales_year" name={new Date().getFullYear()} stroke="#8884d8" strokeWidth={2}/>
                          <Text> {translate("pos.dashboard.salesAmount")}</Text>
                          <Tooltip formatter={numberFormatter}/>
                          <Legend verticalAlign="top" height={36}/>
                        </LineChart>
                      </ResponsiveContainer>
                    }
                  </Box>
                  {/*{userFilter.profile.toLowerCase() === 'com' && */}
                    <Box flex={6} mr={{ xs: 0, sm: '0.5em' }} mt={{xs:0, sm: 0}} >
                      {margins.isLoading ?
                        <Loader
                             type="Oval"
                             color="#392722"
                             height={100}
                             width={100}
                          />
                        :
                        <ResponsiveContainer width="99%" height={250}>
                          <LineChart width="99%" height={250} data={margins.data}>
                            <XAxis dataKey="id" />
                            <YAxis tickFormatter={numberFormatter} width={100} />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Line type="monotone" dataKey="margin_n_3" name={translate("pos.dashboard.marginYear", {year: new Date().getFullYear()-3})} stroke="#48d1cc"  />
                            <Line type="monotone" dataKey="margin_n_2" name={new Date().getFullYear()-2}stroke="#ff7f50"  />
                            <Line type="monotone" dataKey="margin_prec_year" name={new Date().getFullYear()-1} stroke="#82ca9d"  />
                            <Line type="monotone" dataKey="margin_year" name={new Date().getFullYear()} stroke="#8884d8"  strokeWidth={2} />
                            <Text> {translate("pos.dashboard.marginAmount")}</Text>
                            <Tooltip formatter={numberFormatter}/>
                            <Legend verticalAlign="top" height={36}/>
                          </LineChart>
                        </ResponsiveContainer>
                      }
                    </Box>
                  {/*} */}
                </Box>
              </Box>
          </form>
      )}/>
    );
};


export default Graphique;
