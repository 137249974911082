import * as React from "react";
import MobileDashboard from "./mobileDashboard";
import Indicateur from "./indicateurs";
import Graphique from "./graphique";
import ListD from "./list";
import {
    Grid,
    useMediaQuery,
} from '@material-ui/core';

import { useAuthState, Loading } from 'react-admin';
const Dashboard =  (props) => {
  const isXsmall = useMediaQuery(theme =>theme.breakpoints.down('xs'));
  const isLarge = useMediaQuery(theme =>theme.breakpoints.up('lg'));
  const { loading, authenticated } = useAuthState();
  if (loading) {
        return <Loading />;
    }
    if (authenticated) {

  return (
      <div>
        {isXsmall ? (
          <MobileDashboard/>
        ) : (
          <div>
            <Grid item md >
              <Indicateur/>
            </Grid>
            {localStorage.getItem("profile").toLowerCase() !== 'assi' &&
              <Grid item md >
                  <Graphique isLarge={isLarge}/>
              </Grid>
            }
            <Grid container>
              <ListD/>
            </Grid>
        </div>
      )}
    </div>
  )};
};
export default Dashboard;
