import { makeStyles, withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { Card as MuiCard} from '@material-ui/core';

const textColor = grey['800'];

const ThemeName = 'light' | 'dark';
export const CHANGE_THEME = 'CHANGE_THEME';

export const changeTheme = (theme= ThemeName) => ({
    type: CHANGE_THEME,
    payload: theme,
});

export const darkTheme = {
    palette: {
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#FBBA72',
        },
        background: {
            default: '#000',
        },
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                color: '#ffffffb3',
                backgroundColor: '#616161e6',
            },
        },
    },
};

export const lightTheme = {
    palette: {
        primary: {
            main: '#4f3cc9',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        backgroundPage: {
            default: '#fff',
        },
        backgroundMenu:{
            color:'#f0ebe2'
        },
        logo:{
            color:'#3D3F3A',
        },
        texte:{
            color:'#3D3F3A',
        },
        type: 'light',
    },
    shape: {
        borderRadius: 0,
    },
    overrides: {
        RaAppBar:{
            toolbar:{
                backgroundColor:'#f0ebe2',
                color:'#3D3F3A',
            }
        },
        RaDatagrid:{
            tbody:{
                backgroundColor:'#fff'
            }
        },
        RaLayout:{
            content:{
                backgroundColor:'#fff'
            }
        },
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #4f3cc9',
            },
        },
        RaSidebar:{
            root:{
                backgroundColor:'#f0ebe2'
            }
        },
        MuiGrid:{
            item:{
                marginTop: 5,
            }
        },
        MuiBox:{
            item:{
                marginTop: 5,
            }
        },
        MuiCardActions:{
            spacing:{
                display: 'flex',
                justifyContent: 'space-around',
            }
        },
        MuiCardContent:{
            root:{
                backgroundColor:'#fff'
            }
        },
        MuiCardMedia:{
            root:{
                height: 140
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '3px solid #e0e0e3',
                backgroundClip: 'padding-box',
                backgroundColor:"#f0ebe2"
            },
        },
        MuiTypography:{
            body1:{
                color:'#6E6E6E',
            },
        },
        MuiTablePagination:{
            toolbar:{
                backgroundColor:'#fff',
                backgroundClip: 'padding-box',
            },
        },
        MuiCard:{
            root:{
                backgroundColor:'#fff',
            }
        },
        MuiSvgIcon:{
            root:{
                color:'#3D3F3A'
            }
        },
        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#4f3cc9',
                boxShadow: 'none',
            },
        },
        MuiAppBar: { //barre principale en haut de l'écran
            root:{
                borderColor:'#f0ebe2'
            },
            colorSecondary: {
                color: '#efeeee',
                backgroundColor: '#5d40ff',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
    },
};

export const testTheme = {
    palette: {
        primary: {
            main: '#f0ebe2',
        },
        secondary: {
            main: '#3D3F3A',
        },
        background: {
            default: '#fff',
        },
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
};

// Balise Card + style (AsideListCustomers.js, AsideListOrders.js et invoice.js)
export const Card = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '20em',
            height: 'max-content',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}))(MuiCard);

// Couleur des chiffres négatif/positif (ColoredField.js)
export const colorNumberStyles = makeStyles({
    positive: {
        color: 'green',
        textColor: "green"
    },
    negative: {
        color: 'red',
        textColor: "red"
    },
});

// Onglet configuration ( configuration.js)
export const configStyles = makeStyles({
    label: {
        width: '10em',
        display: 'inline-block'
    },
    button: {
        margin: '1em'
    },
});

// Indicateur graphique Dashboard (graphique.js)
export const graphiqueStyles = makeStyles((theme)=>({
    container: {
      paddingTop: 30,
    },
    inlineBlock: {
        display: 'inline-flex',
        marginRight: '1rem'
    },
}));

// Indicateur individuelle dashboard (infoBox.js)
export const infoBoxStyles = makeStyles(theme => ({
    value: props => ({
        display: "block",
        fontWeight: 500,
        fontSize: 18,
        textAlign: "right",
        color: props.spanBgColor
    }),
    label: {
        fontSize: 10,
        fontWeight:500,
        textAlign: "left",
        color: textColor
    },
    icon: {
        height: 35,
        width: 35,
        maxWidth: "100%",
    },
    card: {
        '& a': {
            textDecoration: 'none',
        },
    },
    main: (props) => ({
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }),
}));

// (LoginPage.js)
export const loginStyle = makeStyles(theme => ({
    avatar: { margin: '1em',
              display: 'flex',
              justifyContent: 'center',
    },
    card: { minWidth: 300,
            marginTop: '6em',
    },
    form:{ padding: '0 1em 1em 1em',},
    hint: { marginTop: '1em',
            display: 'flex',
            justifyContent: 'center',
            color: theme.palette.grey[500],
    },
    hint_forgot: {  marginTop: '1em',
                    display: 'flex',
                    justifyContent: 'center',
                    color: theme.palette.primary.main,
                    textDecoration:'underline',
                    cursor: 'pointer',
                    fontSize:'11px',
    },
    icon: { backgroundColor: theme.palette.secondary.main, },
    main: { display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            height: '1px',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundColor: 'rgba(35, 85, 244, 0.3)',
    },
}));

// Logo icône liste client
export const logoSocialMedia = makeStyles({
    logo:{
        color:'#4f3cc9'
    },
    disabled:{
      color:"gray"
    }
});

// Menu du haut, espace entre titre et bouton (appBar.js)
export const menu = makeStyles({
    spacer: {
        flex: 1,
    },
});

// Tout les styles pour le mobile ( tous les MobileXXX.js)
export const mobileStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body2,
        display: 'flex',
        flexDirection: 'column',
    },
}));

// Dashboard info (dashboard.js)
export const page = makeStyles({

    cell: {
        marginTop: 30,
        backgroundColor: "#fff",
        borderRadius: 0
    },
});

// Onglet vue commerciale (sales.js)
export const saleStyle = makeStyles((theme)=>({
    root: {
     flexGrow: 1,
   },
   paper: {
     padding: theme.spacing(2),
     textAlign: 'center',
     backgroundColor: 'white',
     color: theme.palette.text.secondary,
   },
}));

// Style dossier satistique (tous sauf getStats et ButtonStats)
export const statStyles = makeStyles((theme)=>({
    mainContainer: {
        textAlign: 'center',
        maxWidth: (!theme.breakpoints.up('sm') && !theme.breakpoints.up('xs')?'90vw':'100vw'),
        width:'100%',
        margin:'0',
    },
    container: {
        textAlign: 'center',
        backgroundColor: '#FFFFFF40',
        border : 'solid 1px',
        borderRadius : '0px',
    },
    label: {
        display: 'inline-block',
        width: '30%',
    },
    input: {
        display: 'inline-block',
        width:'auto',
    },
    unit:{
        display:'inline-block',
        width:'20%',
        marginLeft:'5px',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

// ??? (TopToolbar.js)
export const topToolbarStyles = makeStyles(
    theme => ({
        root: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(1),
            minHeight: theme.spacing(5),
            [theme.breakpoints.up('xs')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
            [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(2),
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
                backgroundColor: theme.palette.background.paper,
            },
        },
    }),
    { name: 'RaTopToolbar' }
);
