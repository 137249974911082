/**************************************************************************
Pagination component to be used in list embedded in complex forms
****************************************************************************/
import { useListContext } from 'react-admin';
import { Button, Toolbar } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

export const SubListPagination = () => {
    const { page, perPage, total, setPage } = useListContext();
    const nbPages = Math.ceil(total / perPage) || 1;
    return (
        nbPages > 1 &&
            <Toolbar>
                {page > 1 &&
                    <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
                        <ChevronLeft />
                    </Button>
                }
                <span style={{marginLeft:'0px', marginRight:'0px'}}>{1+((page-1)*perPage)} - {Math.min(page*perPage, total)} / {total}</span>
                {page !== nbPages &&
                    <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
                        <ChevronRight />
                    </Button>
                }
            </Toolbar>
    );
}
