import * as React from "react";

import Graphique from "./graphique";
import { Grid } from '@material-ui/core';
import Indicateur from "./indicateurs";

const MobileDashboard = (props) => {

  return (
    <div>
      <Grid item md >
        <Indicateur/>
      </Grid>
      {localStorage.getItem("profile").toLowerCase() !== 'assi' &&
        <Grid item md >
            <Graphique/>
        </Grid>
      }
    </div>
  );
};
export default MobileDashboard;
