import * as conf from './confProvider';

let authProvider = {
    // called when the user attempts to log in
    login: ({ username, password }) =>  {
        const request = new Request(conf.URL_SERVER_REST+'/login', {
            method: 'POST',
            body: JSON.stringify({ identifiant:username, mot_de_passe:password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials:"include"
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                //console.log(response);
                return response.json();
            })
            .then(auth => {
              //console.log(auth);
                localStorage.setItem('profile',auth.profil);
                localStorage.setItem('id', auth.id);
                localStorage.setItem('username',auth.identifiant);
                localStorage.setItem('preferences',JSON.stringify({favourites:{}}));
                localStorage.setItem("firstName", auth.prenom);
                localStorage.setItem("lastName", auth.nom);
                localStorage.setItem("mail", auth.mail_user);
            })
            .catch((e) => {
                throw new Error(e)
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('profile');
        localStorage.removeItem('username');
        localStorage.removeItem('profile');
        localStorage.removeItem('preferences');
        localStorage.removeItem("firstName");
        localStorage.removeItem("lastName");
        localStorage.removeItem('id');
        localStorage.removeItem("mail");
        localStorage.removeItem('history');
        localStorage.removeItem('pathHistory');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('profile');
            localStorage.removeItem('username');
            localStorage.removeItem('id');
            localStorage.removeItem('preferences');
            localStorage.removeItem("firstName");
            localStorage.removeItem("mail");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('username') && localStorage.getItem('profile') && localStorage.getItem('id')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        return localStorage.getItem('username')
            ? Promise.resolve(localStorage.getItem('username'))
            : Promise.reject();
    },

};
export default authProvider;
