import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField
} from 'react-admin';

import {NoResult} from "../customComponents/NoResult";

export const MSList = (props) => {
    //console.log(props);
    return(
        <List
        filter={{"@ID":props.id}}
        resource='matieresSeches'
        basePath='/matieresSeches'
        title={" "}
        hasList hasEdit hasShow
        empty={<NoResult/>}
        >
          <Datagrid>
            <TextField label='' source='t81_fam' />
            <TextField label='' source='ms_code'/>
            <TextField label='' source='ms_lib'/>
            <NumberField label="resources.products.fields.Qte" source=''/>
            <NumberField label="resources.products.fields.PoidU" source='ms_poids'/>
            <NumberField label="resources.products.fields.PoidT" source=''/>
            <NumberField label="resources.products.fields.Haut"source='hauteur'/>
            <NumberField label="resources.products.fields.Long"source='longueur'/>
            <NumberField label="resources.products.fields.Lar"source='largeur'/>
          </Datagrid>
    </List>


)};
