import React,{useState} from 'react';
import {
  useTranslate,
  Edit ,
  Toolbar ,
  TabbedForm ,
  FormTab
} from 'react-admin';
import {
  Typography,
  Slide,
  makeStyles,
  Dialog,
  IconButton,
  AppBar,
  Button,
} from '@material-ui/core';

import {MSList} from './matieres_seches';
import {PalList} from './palette';
import {Details} from './details';
// import {Images} from "./images";
// import {Documents} from "./documents";
import AddOffer from './addProdButton';
import CreateOffer from './offer_CreateButton';
import IconClose from "@material-ui/icons/Close";
import IconContentAdd from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  page: {
  flexDirection: 'row',
  backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
}));

const ProdTitle = ({ record }) => {
  return <div>{record ? `${record.libelle} ${record.millesime}` : ''}</div>;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ActionsOffres = props => {
  const [showDialogAdd, setShowDialogAdd] = useState(false);
  const [showDialogCreate, setShowDialogCreate] = useState(false);
  const handleClickCreate = () => {
      setShowDialogCreate(true);
  };
  const handleCloseClickCreate = () => {
      setShowDialogCreate(false);
  };
  const handleClickAdd = () => {
      setShowDialogAdd (true);
  };
  const handleCloseClickAdd = () => {
      setShowDialogAdd (false);
  };
  const translate = useTranslate();
  const classes = useStyles();
  return(
    <Toolbar {...props}>
    <div>
    <Button onClick={handleClickAdd} variant="contained" size="medium" >
    <IconContentAdd />
      {"Ajouter le produit à une offre"}
    </Button>
    <Dialog maxWidth open={showDialogAdd} onClose={handleCloseClickAdd} TransitionComponent={Transition}>
       <AppBar className={classes.appBar}>
         <Toolbar>
           <IconButton edge="start" onClick={handleCloseClickAdd} aria-label={translate("ra.actions.close")}>
             <IconClose />
           </IconButton>
           <Typography variant="h6" className={classes.title} color="primary"> {translate("resources.offres.title.AddProd")} </Typography>
         </Toolbar>
       </AppBar>
    <AddOffer {...props} />
    </Dialog>
    </div>

    <div>
    <Button onClick={handleClickCreate} variant="contained" size="medium" >
    <IconContentAdd />
      {"Créer une nouvelle offre"}
    </Button>
    <Dialog maxWidth open={showDialogCreate} onClose={handleCloseClickCreate} TransitionComponent={Transition}>
       <AppBar className={classes.appBar}>
         <Toolbar>
           <IconButton edge="start" onClick={handleCloseClickCreate} aria-label={translate("ra.actions.close")}>
             <IconClose />
           </IconButton>
           <Typography variant="h6" className={classes.title} color="primary"> {translate("resources.offres.title.AddProd")} </Typography>
         </Toolbar>
       </AppBar>
    <CreateOffer {...props} />
    </Dialog>
    </div>
    </Toolbar>
);}

export const ProdEdit = (props) => {
  //console.log("ProdEdit props", props)
  const productResource = props.resource;
  return(
    <Edit
      resource={'articles'}
      basePath={props.location.pathname}
      {...props}
      title={<ProdTitle/>}
    >
      <TabbedForm syncWithLocation={false}
        toolbar={<ActionsOffres {...props} porductResource={productResource}/> } {...props}>

          <FormTab label="resources.products.fields.fp" {...props}>
            <Details fullWidth {...props}/>
          </FormTab>

          <FormTab label="resources.products.fields.pal">
            <PalList id={props.Artcode} {...props}/>
          </FormTab>

          <FormTab label="resources.products.fields.MS">
            <MSList id={props.Artcode} {...props}/>
          </FormTab>

      </TabbedForm>
  </Edit>
  )
};

export const ProdHTEdit = (props) => {
  const productResource = props.resource;
  return(
    <Edit
      resource={'articlesHorsTarif'}
      basePath={props.location.pathname}
      {...props}
      title={<ProdTitle/>}
    >
      <TabbedForm syncWithLocation={false}
      toolbar={<ActionsOffres {...props} productResource={productResource}/> } {...props}>

          <FormTab label="resources.products.fields.fp" {...props}>
            <Details fullWidth {...props}/>
          </FormTab>

          <FormTab label="resources.products.fields.pal">
            <PalList id={props.Artcode} {...props}/>
          </FormTab>

          <FormTab label="resources.products.fields.MS">
            <MSList id={props.Artcode} {...props}/>
          </FormTab>

      </TabbedForm>
  </Edit>
  )
}
