import {useState, useEffect, useMemo} from 'react';
import { ModalMails, ModalCreateMails, ReplyMails } from '../modals/modals';
import {Modal} from '../modals/modals';
import {
  useGetOne,
  List,
  Datagrid,
  TextField,
  DateField,
  Loading,
  Error,
  Pagination,
  useGetList,
  TopToolbar
  } from 'react-admin';
import keyBy from 'lodash/keyBy';
import parse from 'html-react-parser';
import { grey, lightGreen } from '@material-ui/core/colors';
import SendIcon from '@material-ui/icons/Send';
import LoupeIcon from '@material-ui/icons/Loupe';


const ReplyButton = (props) => {
  return(
    <Modal title={"Répondre"} buttonText={"Répondre"} width="md" {...props}>
      <ModalCreateMails mailType={"ReplyMail"} {...props} />
    </Modal>
)}

const ReplyAllButton = (props) =>{
  //console.log(MailClient);
  return(
    <Modal title={"Répondre à tous"} buttonText={"Répondre à tous"} width="md" >
      <ModalCreateMails mailType={'ReplyToAllMail'} {...props} />
    </Modal>
)}

const ForwardButton = (props,{MailClient}) =>{
  return(
    <Modal title={"Transférer"} buttonText={"Transférer"} width="md" >
      <ModalCreateMails mailType={'ForwardMail'} {...props}/>
    </Modal>
)}

const ShowButton = (props) => (
  <Modal title={"Voir"} buttonText={"Voir"} width="md" >
    <MailShow {...props}/>
  </Modal>
)

const mailRowStyle = (record) => {
  return({
    backgroundColor: record.from === localStorage.mail ? lightGreen[200]:grey[300]
})};

const NewEmail = (props) => {
  return(
    <TopToolbar>
      <Modal  buttonIcon={<SendIcon/>}
              title={"Nouveau courrier"}
              buttonText={"Nouveau courrier"}
              width="md"
      >
        <ModalCreateMails mailType="newMail" {...props} />
      </Modal>
    </TopToolbar>
  );
}

export const ClientMails = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState({ field: 'id', order: 'ASC' })
  const { data: MailClient } = useGetOne(props.type, props.id);

  const { data:emails, ids, loading, error, total  } = useGetList(
      'mail',
      { page: 1, perPage: 10 },
      { field: 'date', order: 'ASC' },
      { mail:MailClient.cl_email}
  );
  if (loading) { return <Loading />; }
  if (error) { return <Error/>; }

  return(
    <>
        <TopToolbar>
          <NewEmail {...props} />
        </TopToolbar>
            {/* <Datagrid
                data={emails}
                ids={ids}
                currentSort={sort}
                setSort={(field, order) => setSort({ field, order })}
                rowStyle={mailRowStyle}
            >
                <TextField label={"resources.offres.email.from"} source="from" />
                <TextField label={"resources.offres.email.to"} source="to" />
                <TextField label={"resources.offres.email.subject"} source="subject" />
                <DateField source="date" />
                <ReplyButton {...props}/>
                <ReplyAllButton {...props}/>
                <ForwardButton {...props}/>
                <ShowButton {...props}/>
            </Datagrid>
            <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
            /> */}
        </>
  )
}

export const MailShow = ({record}) => {
  let date = new Date(record.date).toLocaleString();
  return (
    <div>
      {record.from && <><b>{"De : "}</b>{record.from}<br /></>}
      {record.to && <><b>{"A : "}</b>{record.to}<br /></>}
      {record.cc && <><b>{"Cc : "}</b>{record.cc}<br /></>}
      //{record.bcc && <><b>{"Cci : "}</b>{record.bcc}<br /></>}
      {date && <><b>{"Le : "}</b>{date}<br /></>}
      {record.body && <><hr />{parse(record.body)}</>}
    </div>
  );
}
