import React, { useState, useEffect } from 'react';
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  Edit,
  FunctionField,
  Link,
  List,
  NumberField,
  SimpleForm,
  SimpleShowLayout,
  TabbedForm,
  TabbedFormTabs,
  TextField,
  Toolbar,

  useTranslate,
  useDataProvider,
  useLocale,
} from 'react-admin';

import Loader from 'react-loader-spinner';

import { DOC_ROOT } from "../confProvider.js";

import {LinkField, LinkToFile} from '../customComponents/LinkField';
import {SubListPagination} from "../customComponents/SubListPagination";

import IconClose from "@material-ui/icons/Close";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PageviewIcon from "@material-ui/icons/Pageview";
import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Box from "@material-ui/core/Box";

import Dialog from '@material-ui/core/Dialog';

import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { makeStyles } from '@material-ui/core/styles';

const util = require('../utils');

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const FilesButton = ({record, ...props}) => {
    const [showDialog, setShowDialog] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    }
    return (
        <>
            <Button onClick={handleClick}>
                <AttachFileIcon />
            </Button>
            <Dialog fullScreen open={showDialog} onClose={handleCloseClick} TransitionComponent={Transition}>
               <AppBar className={classes.appBar}>
                 <Toolbar>
                   <IconButton edge="start" onClick={handleCloseClick} aria-label={translate("ra.actions.close")}>
                     <IconClose />
                   </IconButton>
                   <Typography variant="h6" className={classes.title} color="primary"> {translate("resources.products.fields.prodFiles")} {record.libelle} {record.appel} {record.ta_libr} {record.millesime} </Typography>
                 </Toolbar>
               </AppBar>
               <AutoGrid st_code= {record[props.linkValue]} {...props} product={record.libelle} millesime={record.millesime} prodFolder={record.dossier}/>
             </Dialog>
        </>
    );
}

const SubDirButton = ({record, ...props}) => {
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const handleClick = () => {
      setShowDialog(true);
  };

  const handleCloseClick = () => {
      setShowDialog(false);
  }
  return (
      <>
          <Button onClick={handleClick}>
              <FolderOpenIcon />
          </Button>
          <Dialog fullScreen open={showDialog} onClose={handleCloseClick} TransitionComponent={Transition}>
             <AppBar className={classes.appBar}>
               <Toolbar>
                 <IconButton edge="start" onClick={handleCloseClick} aria-label={translate("ra.actions.close")}>
                   <IconClose />
                 </IconButton>
                 <Typography variant="h6" className={classes.title} color="primary"> {translate("resources.products.fields.prodFiles")} {record.libelle} {record.appel} {record.ta_libr} {record.millesime} </Typography>
               </Toolbar>
             </AppBar>
             <FileList {...props}/>
           </Dialog>
      </>
  );
}

export default FilesButton;

const FileOrDir = (props) => {
  return (
    props.record.isDirectory?
      <>
      <TextField {...props} />
      </>
    :
      <LinkToFile
        label="resources.products.fields.fileName"
        url={`${DOC_ROOT}CHATEAUX_et_VINS/${props.product}/${props.subFolder}/`}
        source="name" type=""
        target="_blank"
      />
  )
}

const FileList = (props) => {
  const userFilter = Object.assign({}, {product: props.product, millesime: props.millesime, subFolder: props.subFolder});
  const translate = useTranslate();
  return (
    <List
      {...props}
      resource="fileList"
      filter={userFilter}
      empty={false}
      pagination={false}
    >
      <Datagrid>
        <BooleanField source="isDirectory" label=""
          TrueIcon={FolderIcon} FalseIcon={PageviewIcon}
          valueLabelFalse= {translate("resources.products.fields.isFile")}
          valueLabelTrue= "resources.products.fields.isDirectory"
        />
        <FileOrDir {...props} label="resources.products.fields.fileName"
          {...props}
          source="name"
        />
      </Datagrid>
    </List>
)}

const TabPanel = (props) => {
  const { value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <FileList {...props} />
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AutoGrid = (props) => {
  const dataProvider= useDataProvider();
  const translate = useTranslate();
  const locale = useLocale();

  const folder = (props.prodFolder !== undefined && props.prodFolder !== null && props.prodFolder !== "")?
    props.prodFolder: props.product;

  const userFilter = Object.assign({}, {product: folder, millesime: props.millesime, subFolder: props.subFolder});

  const [files, setFiles] = useState({
    isLoading:true,
    isError: false,
    data:[{id:0, count:0}]
  });

  const [dataFilter, setDataFilter] = useState({});      // filter on data retreive from server
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = () => {
    dataProvider.getList(('fileList'),{
      pagination:{
          page:1,
          perPage:2048
      },
      filter: Object.assign({}, userFilter,  dataFilter),
      sort:{
          // field:'',
          // order:''
      }
    })
    .then((data)=>{
      //console.log(data);
      setFiles({
          isLoading:false,
          isError: false,
          data: data.data
        });
    })
    .catch((erreur)=>{
      //console.log("erreur", erreur);
      setFiles({
        isError: true,
        isLoading:false,
        data:[{id:0, count:0}]
      });
    });
  };

  useEffect(()=> {
    getData();
  },[]);

  const dirEntries = files.data.filter(dirEntry => dirEntry.isDirectory && !dirEntry.isSymbolicLink);
  return (
    <>
    {files.isLoading ?
      <Loader
           type="Oval"
           color="#392722"
           height={100}
           width={100}
        />
    :
      <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {dirEntries.map(item => (
            <Tab label= {item.name}  />
          ))}
        </Tabs>
      </Box>
      {dirEntries.map(item => (
        <TabPanel value={value} index={item.id} subFolder={item.name} {...props} product={folder} />
      ))}
      </>
    }
    </>
)}
