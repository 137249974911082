import * as React from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  Edit,
  Filter,
  FormWithRedirect,
  FunctionField,
  List,
  ListButton,
  ReferenceInput,
  SelectInput,
  NumberField,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';

import MobileOrderList from './mobileOrders';
import { useMediaQuery } from '@material-ui/core';
import AsideListOrders from './AsideListOrders';
import {LinkField} from '../customComponents/LinkField';
import {
  Box,
  Card,
  CardContent
} from '@material-ui/core';


import {useTranslate} from 'react-admin';
import {useState} from 'react';

import { DatagridTotalLine } from '../customComponents/CustomTotalLine';

import {
  subYears,
} from 'date-fns';
const util = require('../utils');


// xxxFieldInForm is used to avoid react warning with variant props.
// TODO: see how to export this from a customComponents file.
const TextFieldInForm = ({ variant, ...props }) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;

const CustomTitle = ({ record, embedded }) => {
  const translate=useTranslate();
  return <span>{embedded === true? " ": record ?
    `${translate("resources.orders.title", {reference: record.ce_num, client: record.cl_rsoc1, liv: record.deliveredCustomer.cl_rsoc1})}` : ' '}</span>;
}

const CustomToolbar = props => (
  <Toolbar {...props} >
    <ListButton basePath={props.basePath}/>
  </Toolbar>
);


const OrderFilter = (props) => {
  const [dataFilter, setDataFilter] = useState({});      // filter on data retreive from server
  const isLarge = useMediaQuery(theme =>theme.breakpoints.up('lg'));

  const onSalesMgrChange = (item) => {
    let tmpFilter = dataFilter;
    if(item === "" || item === null || item.target.value === undefined || item.target.value === "" || item.target.value === null) delete tmpFilter["eq_ta3_libre"];
    else {
      Object.assign(tmpFilter, {"eq_ta3_libre": "'" + item.target.value + "'"});
    }
    setDataFilter(tmpFilter);
  }
  const userFilter = util.getUserFilter();

  return  (
    <Filter {...props}>
  {/* note Sprint 7
      <ReferenceInput label="resources.customers.fields.zone" source="likeic_ta4_libre" filter={userFilter} reference="zone" allowEmpty={true} resettable={true} alwaysOn sort={{field:"ta_libr", order:"ASC"}} >
        <AutocompleteInput optionText="ta_libr" />
      </ReferenceInput>
  */}
      {(isLarge && (userFilter.profile === "dcom" || userFilter.profile === "adm")) &&
        <ReferenceInput label="resources.customers.fields.sales_mgr" source="likeic_cli.ta3_libre" filter={userFilter}
          reference="sales_mgr" allowEmpty={true} resettable={false} emptyText= {`pos.all`}
          alwaysOn sort={{field:"ta_lib", order:"ASC"}}
          onChange={onSalesMgrChange}
        >
          <SelectInput optionText="ta_lib" />
        </ReferenceInput>
      }
      {isLarge &&
        <ReferenceInput label="resources.customers.fields.country" source="likeic_cli.t76_pays" filter={Object.assign({}, userFilter, dataFilter)}
          reference="country" allowEmpty={true} resettable={false} emptyText= {`pos.all`}
          alwaysOn sort={{field:"ta_lib", order:"ASC"}}
          perPage={10000}
        >
          <SelectInput optionText="ta_lib" />
        </ReferenceInput>
      }
      {isLarge &&
        <ReferenceInput label="resources.customers.fields.commercial" source="likeic_cli.ta7_libre"
          filter={Object.assign({}, userFilter, dataFilter)} reference="salesman" allowEmpty={true} resettable={false} emptyText= {`pos.all`}
          alwaysOn sort={{field:"comm.ta_lib", order:"ASC"}}
          perPage={10000}
        >
          <SelectInput optionText="ta_lib" />
        </ReferenceInput>
      }
      <TextInput label="resources.orders.fields.customer" source="likeic_cli.cl_rsoc1" alwaysOn />
      {isLarge &&  <TextInput label="resources.orders.fields.deliv_customer" source="likeic_deliveredCustomer.cl_rsoc1" alwaysOn /> }
    </Filter>
  );
}

const AllOrderEdit = (props) => {
  return (
    <Edit title={<CustomTitle {...props}/>}
      undoable={true} component="div"
      actions={<CustomToolbar {...props}/>}
      {...props}
    >
      <FormWithRedirect {...props} render={(formProps) => (
         <Card>
          <form>
            <CardContent>
              {/*-- First line  --*/}
              <Box display={{ md: 'block', lg: 'flex' }} >
                <Box flex={12} mr={{ md: 0, lg: '1em' }} mt={{xs:0, sm: 0}}>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }} >
                      <TextInput source="ce_num" label="resources.orders.fields.reference" inputProps={{disabled: true}} fullWidth/>
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }} >
                      <TextInput source="ce_refcli" label={`resources.orders.fields.customer_order_num`} inputProps={{disabled: true}} fullWidth />
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }}>
                      <TextInput source="b0100.cl_rsoc1" label={`resources.orders.fields.customer`} inputProps={{disabled: true}} fullWidth />
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }}>
                      <TextInput source="deliveredCustomer.cl_rsoc1" label={`resources.orders.fields.deliv_customer`}  inputProps={{disabled: true}} fullWidth />
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/*-- Second line  --*/}
              <Box display={{ md: 'block', lg: 'flex' }} >
                <Box flex={12} mr={{ md: 0, lg: '1em' }} mt={{xs:0, sm: 0}}>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }} >
                      <DateInput inputProps={{disabled: true}} source="datsais" label="resources.orders.fields.dates.input" fullWidth/>
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }} >
                      <DateInput source="datenl" label={`resources.orders.fields.dates.takeoff`} inputProps={{disabled: true}} fullWidth />
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }}>
                      <TextInput inputProps={{disabled: true}} source="ce_cacdeht" label='resources.orders.fields.turnover' fullWidth
                        format={v => new Intl.NumberFormat({useGrouping: true}).format(v)}
                      />
                    </Box>
                    <Box flex={3} mr={{ xs: 0, sm: '2em' }}>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/*-- Third line with vertical fields and a list  --*/}
              <Box flex={12} mr={{ md: 0, lg: '1em' }} mt={{xs:0, sm: 0}}>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={3} mr={{ xs: 0, sm: '2em' }}>
                    <TextInput source="step.ta_lib" label="resources.orders.fields.step"  inputProps={{disabled: true}} fullWidth/>
                    <TextInput source="anom.ta_lib" label="resources.orders.fields.anomaly"  inputProps={{disabled: true}} fullWidth/>
                    <TextInput source="bloc.ta_lib" label="resources.orders.fields.locked"  inputProps={{disabled: true}} fullWidth/>
                  </Box>
                  <Box flex={9} mr={{ xs: 0, sm: '0.5em' }} mt={{xs:0, sm: 0}} >
                    <OrderLines embedded={true} id={props.id}/>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </form>
        </Card>
      )}/>
    </Edit>
)};

export const OrderLines = (props) => {

  return(
      <List
          resource="orderLines"
          basePath="/orderLines"
          filter={{eq_ce_num: "'" + props.id + "'"}}
          filters={null}
          perPage={5}
          exporter={false}
          actions={false}
          currentSort={{field:"ce_ordlig",order:"ASC"}}
          title=" "
      >
        <OrderLinesGrid {...props}/>
      </List>
  )
};

export const OrderLinesGrid = (props) => {

  return(
    <Datagrid >
      <TextField source="product.ba_lib" label="resources.order_lines.fields.product"/>
      <TextField source="millesime" label="resources.order_lines.fields.millesime"/>
      <TextField source="article.st_desi" label="resources.order_lines.fields.format"/>
      <NumberField source="ce_pu" label="resources.order_lines.fields.unit_price"
        options={{style: "currency", currency:"EUR", maximumFractionDigits: 3}} />
      <NumberField source="ce_qte" label="resources.order_lines.fields.quantity" />
      <NumberField source="ce_pvht" label="resources.order_lines.fields.total_price"
        options={{style: "currency", currency:"EUR", maximumFractionDigits: 2}} />
    </Datagrid>
  );
};



export const AllOrderShow = (props) => {
  return (
    <AllOrderEdit/>
  );
}

export const AllOrderList = props =>{
  const isXsmall = useMediaQuery(theme =>theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isLarge = useMediaQuery(theme =>theme.breakpoints.up('lg'));
  const totalFields =
  [
    {
      propriete:"SUM(com.ce_cacdeht * CASE typ.tb_nature WHEN 'A' THEN -1 ELSE 1 END  * COALESCE(com.CE_TAUDEV_SAIFAC, com.CE_TAUDEV_SAICOM)) ",
      as:"ce_cacdeht",
      resource: "sommeProprietesOrders"
    }
  ];

  let userFilter =util.getUserFilter();
  // userFilter["eq_b0100.cl_acti"] = "'0'";
  // userFilter["isnull_b1010.ce_datdepart"] = true; // have to put something
  userFilter["eq_ce_acti"] = "'0'";

  return(
    <List
      filter={userFilter}
      filters={<OrderFilter/>}
      {...props}
      aside={isLarge? <AsideListOrders/>: undefined}
      sort={{field: "ce_datsais", order: "DESC"}}
      bulkActionButtons={false}
      title={`resources.orders.listTitle`}
    >
      {!isLarge ? (
        <MobileOrderList userFilter={userFilter} fieldsToTotalize={totalFields} {...props} />
      ) : (
        <DatagridTotalLine
          filter={userFilter}
          fieldsToTotalize={totalFields}
        >
          <LinkField source="ce_num" label="resources.orders.fields.order_num" linkValue="id" {...props}/>
{/* suite remarques sprint 7          <TextField source="typeBon.ta_lib" label={`resources.orders.fields.type`} />  */}
          <LinkField source="cli.cl_rsoc1" label={`resources.orders.fields.customer`} {...props} basePath="/customers" linkValue="cl_code_fac" />
          <FunctionField label={`resources.orders.fields.deliv_customer`}
            render={record => {if(record.deliveredCustomer && record.deliveredCustomer.cl_rsoc1 && record.cl_code_liv){return (`${record.deliveredCustomer.cl_rsoc1} (${record.cl_code_liv})`);}else{return null;}}}
          />
          <NumberField source='ce_cacdeht' label={`resources.orders.fields.turnover`}
            options={{style: "currency", currency:"EUR", maximumFractionDigits: 2}} />
          <TextField source="ce_refcli" label={`resources.orders.fields.customer_order_num`} />
          <TextField source="comm.ta_lib" label={'resources.customers.fields.commercial'} />
          <TextField source='pays.ta_lib' label={'resources.customers.fields.country'} />
          <DateField source="ce_datsais" label={`resources.orders.fields.dates.input`} />
          <DateField source="ce_datenl" label={`resources.orders.fields.dates.takeoff`} />
        </DatagridTotalLine>
      )}
    </List>
  )
}

const exports = {
  list: AllOrderList,
  edit: AllOrderEdit,
  show: AllOrderShow,
};
export default exports
