import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    SaveButton,
    useCreate,
    useNotify,
    FormWithRedirect,
    SelectInput,
    useTranslate,
    useRefresh,
    useRedirect,
    ReferenceInput,
    TextInput,
    AutocompleteInput
} from 'react-admin';
import {
  CardContent,
  Card,
  DialogActions,
  Grid
} from '@material-ui/core';

const util = require('../utils');
let userFilter = util.getUserFilter();

function AddCustomers(props) {
  //console.log(props);
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('offres_clients');
  const notify = useNotify();
  const form = useForm();
  const userFilter =util.getUserFilter();
  const translate = useTranslate();
  const refresh = useRefresh();
  const handleClick = () => {
      setShowDialog(true);
  };
  const handleCloseClick = () => {
      setShowDialog(false);
  };
  const handleSubmit = async values => {
      create(
          { payload: { data: values } },
          {
              onSuccess: ({ data }) => {
                  setShowDialog(false);
                  notify("Client ajouté");
                  form.change('id', data.id);
                  refresh();
              },
              onFailure: ({ error, data }) => {
                  notify(error.message, "Le client n\'a pas pu être ajouté");
                  refresh()
              }
          }
      );
  };
  return (
    <FormWithRedirect
      resource="offres_clients"
      save={handleSubmit}
      render={({
          handleSubmitWithRedirect,
          pristine,
          saving
      }) => (
        <>
          <Card fullWidth>
            <form>
              <CardContent>
                <Grid item xs={12} sm={12} md={4}>
                  <TextInput disabled label="resources.offres.field.cli" source="cl_code" defaultValue={props.id}/>
                </Grid>

                <ReferenceInput
                  perPage={30}
                  fullWidth
                  label="resources.offres.offres" source="id_offre" reference="offres"
                  sort={{field:"libelle", order:"ASC"}}
                  filterToQuery={searchText => ({likeic_libelle: [searchText]})}
                >
                  <AutocompleteInput optionText="libelle" />
                </ReferenceInput>
              </CardContent>
            </form>
          </Card>
          <DialogActions>
            <SaveButton
                handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                }
                pristine={pristine}
                saving={saving}
                disabled={loading}
            />
          </DialogActions>
        </>
      )}
    />
  );
}
export default AddCustomers;
