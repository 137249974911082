import * as conf from '../confProvider';
var W3CWebSocket = require('websocket').w3cwebsocket;
const wsUrl = conf.URL_SERVER_WS;

const websocket = {isInitiated:false};

export const initWebsocket = () => {
  //Attributes of the websocket
  websocket.pending = false; //Are we waiting for an answer ?
  websocket.client = new W3CWebSocket(wsUrl, null);
  websocket.isInitiated = true;

  //Custom behaviour for the notifications
  websocket.notifications = {};
  websocket.notifications.userList = {};
  websocket.notifications.notifications = [];
  websocket.notifications.alert = "false";

  //Add memoization to websocket
  websocket.memory = {};
  websocket.memory.clientNames = {};

  return websocketAddMethods(websocket); //Add the methods here to avoid code duplication
}

export const websocketAddMethods = (websocket) => {
  //Reviving the core functions of the websocket
  websocket.client.onerror = () => {
    //console.log('Connection Error');
  };
  websocket.client.onopen = () => {
    let pseudo = localStorage.getItem('username');
    websocket.methods.sendMessage({raison:"CONNECTION", emitter:pseudo},"CONNECTION");
  };
  websocket.client.onclose = () => {
    //console.log('echo-protocol Client Closed');
  };
  websocket.client.onmessage = (e) => {
    let message = JSON.parse(e.data);
    if(typeof message === 'string'){
      //console.log("Received: '" + e.data + "'");
    }else if(typeof message === 'object'){
      let data = null;
      switch (message.raison) {
        case "CONNECTION":
          data = message.data;
          websocket.methods.setPending(false);
          if(data !== "OK" && websocket.client.readyState === websocket.client.OPEN){
            setTimeout(()=>{
              let pseudo = localStorage.getItem('username');
              websocket.methods.sendMessage({raison:"CONNECTION", emitter:pseudo},"CONNECTION");
            }, 1000);
          }else if (websocket.client.readyState === websocket.client.OPEN) {
            websocket.methods.refreshData();
          }
        break;
        case "FEEDBACK":
          data = message.data;
          websocket.methods.setPending(false);
          if(typeof data != "object"){
            console.error("Le message n'a pas été délivré : "+data);
          }else{
            websocket.methods.refreshData();
          }

        break;
        case "RECEIVED":
          data = message.data;
          websocket.methods.setPending(false);
          websocket.notifications.alert = "true";
          websocket.methods.refreshData();
        break;
        case "GETALL DATA":
          data = message.data;
          //console.log("GetAll feedback arrived");
          if(typeof data == "object"){
            //console.log(JSON.stringify(data));
            let pseudo = localStorage.getItem('username');

            websocket.methods.setUserList({...data.userList, [pseudo]: true});
            localStorage.setItem("notifications", JSON.stringify(data.conversations));
            websocket.methods.setNotifications(data.conversations);
          }else{
            //console.log("Les données n'ont pas pu être récupérées : "+data);
          }
          websocket.methods.setPending(false);
        break;
        case "SUBSCRIPTION FEEDBACK":
          data = message.data;
          websocket.methods.setPending(false);
          //console.log("Subscription feedback arrived");
          if(data !== "OK"){
            //console.log("Error : Subscription failed");
          }
          websocket.methods.refreshData();
        break;
        case "UNSUBSCRIPTION FEEDBACK":
          data = message.data;
          websocket.methods.setPending(false);
          //console.log("Unsubscription feedback arrived");
          if(data !== "OK"){
            //console.log("Error : Unsubscription failed");
          }
          websocket.methods.refreshData();
        break;
        case "REQUEST_NAME DATA":
          data = message.data;
          websocket.methods.setPending(false);
          if(typeof data != "object"){
            console.error("Can't get this client name, will retry in a few seconds");
          }else{
            websocket.memory.clientNames[data.numero]=data.name;
          }
        break;
        default:
        break;
      }
    }
  };

  websocket.methods = {};

  //Methods to change the behaviour of the websocket
  websocket.methods.setOnError = (action) => {
    if(websocket.client){
      websocket.client.onerror = action;
    }
  };
  websocket.methods.setOnOpen = (action) => {
    if(websocket.client){
      websocket.client.onopen = action;
    }
  };
  websocket.methods.setOnClose = (action) => {
    if(websocket.client){
      websocket.client.onclose = action;
    }
  };
  websocket.methods.setOnMessage = (action) => {
    if(websocket.client){
      websocket.client.onmessage = action;
    }
  };

  //Reviving the additional methods to simplify code
  websocket.methods.refreshData = () => {
    let pseudo = localStorage.getItem('username');
    websocket.methods.sendMessage({raison:"REFRESH", emitter:pseudo, with:"*", salon:"*"},"REFRESH");
  };
  websocket.methods.waitForFinish = (action, debug, count)=>{
    if(websocket.pending===false || count > 5){
      action();
    }else{
      //console.log("Pending action " + debug);
      setTimeout(()=>{
        websocket.methods.waitForFinish(action, debug, count+1);
      },200);
    }
  };
  websocket.methods.waitForReady = (action) => {
    if (websocket.client.readyState === websocket.client.OPEN) {
      action();
    }else{
      //console.log("Websocket not ready, retrying");
      setTimeout(()=>{
        websocket.methods.waitForReady(action);
      },250);
    }
  };
  websocket.methods.sendMessage = (objet, debug) => {
    websocket.methods.waitForReady(()=>{
      websocket.methods.waitForFinish(()=>{
        websocket.client.send(JSON.stringify(objet));
        websocket.methods.setPending(true);
      },debug,0);
    });
  };
  websocket.methods.setPending = (isPending) =>{
    websocket.pending = isPending;
  };
  websocket.methods.read = () =>{
    websocket.notifications.alert = "false";
  };

  //Reviving the notification methods
  websocket.methods.setUserList = (newUserList) => {
    websocket.notifications.userList = newUserList;
  };
  websocket.methods.setNotifications = (newNotifications) => {
    websocket.notifications.notifications = newNotifications;
  };
  websocket.methods.modifyFollowing = (user, isFollowing) => {
    let pseudo = localStorage.getItem('username');
    let debug = (isFollowing?"SUBSCRIPTION":"UNSUBSCRIPTION");
    let objet = {raison:debug, emitter:pseudo, with:user};
    websocket.methods.sendMessage(objet,debug);
  };
  websocket.methods.newMessage = (message, salon) => {
    let pseudo = localStorage.getItem('username');
    let debug = "SEND";
    let objet = {raison:debug, sender:pseudo, msg:message, salon:salon};
    websocket.methods.sendMessage(objet,debug);
  };

  return websocket;
}

export const getWebsocket = () => {
  return websocket;
}
export const getAlert = () => {
  return (websocket.isInitiated ? websocket.notifications.alert : "false");
};
export const read = () => {
  websocket.notifications.alert = "false";
}
export const getClientName = (clientNo) => {
  if(websocket.memory.clientNames[clientNo] && websocket.memory.clientNames[clientNo]!=null){
    return websocket.memory.clientNames[clientNo];
  }else if(!websocket.pending){
    let pseudo = localStorage.getItem('username');
    websocket.methods.sendMessage({raison:"REQUEST_NAME", emitter: pseudo, cliNo: clientNo}, "REQUEST_NAME");
    return "Waiting...";
  }
  return "Waiting...";
}
