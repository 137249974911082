import React, { useState, useEffect } from 'react';

import {
  Button,
  Toolbar,

  useTranslate} from 'react-admin';
import { createElement } from 'react';
import { Link } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';
import LoupeIcon from '@material-ui/icons/Loupe';

import IconClose from "@material-ui/icons/Close";
import BarChartIcon from '@material-ui/icons/BarChart';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';

import {ProdList} from "./products_List";

import { makeStyles } from '@material-ui/core/styles';
import {
  useMediaQuery
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

///////// Boutton pour aller sur la page email /////////
export const ButtonSendProd = (props) => {
  const translate=useTranslate();
  return(
        <Button
          component={Link}
          to={`/mail`}
          label={translate("resources.products.button.SendProd")}
        >
          {createElement(SendIcon)}
        </Button>
)};

///////// Boutton pour aller sur la page du détail de l'article /////////
export const ButtonConditProdHT = (props) => {
  const translate=useTranslate();
  return(
        <Button
          component={Link}
          to={`/products/details/${props.categorie}/${props.record.id}`}
        >
          {createElement(LoupeIcon)}
        </Button>
)};

///////// Boutton pour aller sur la page du détail de l'article /////////
export const ButtonCondit = (props) => {
  const translate=useTranslate();
  return(
        <Button
          component={Link}
          to={`/products${props.categorie}/${props.record.id}`}
          {...props}
        >
          {createElement(LoupeIcon)}
        </Button>
)};



export const ButtonConditProd = (props) => {
  const translate=useTranslate();
  return(
        <Button
          component={Link}
          to={`/products/details/${props.record.st_code}`}
        >
          {createElement(LoupeIcon)}
        </Button>
)};

///////// Boutton pour aller sur la page Hors Tarif /////////
const ButtonProduct = ({record, ...props}) => {
    const [showDialog, setShowDialog] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const isSmall = useMediaQuery(theme =>theme.breakpoints.down('md'));
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        <>
            <Button onClick={handleClick}
              label={translate("resources.products.button.ListProd")} >
            </Button>
            <Dialog maxWidth="xl" fullWidth={true} fullScreen={isSmall} open={showDialog} onClose={handleCloseClick} TransitionComponent={Transition}>
               <AppBar className={classes.appBar}>
                 <Toolbar>
                   <IconButton edge="start" onClick={handleCloseClick} aria-label={translate("ra.actions.close")}>
                     <IconClose />
                   </IconButton>
                   <Typography variant="h6" className={classes.title} color="primary"> {translate("resources.products.button.ListProd")} {props.label} </Typography>
                 </Toolbar>
               </AppBar>
               <ProdList categorie= {props.categorie} {...props}/>
             </Dialog>
        </>
    );
}

export const ButtonHorsTarif = (props) => (
  <ButtonProduct categorie="HorsTarif" label="Historique" {...props} />
)
export const ButtonTarif = (props) => (
  <ButtonProduct categorie="Tarif" label="Tarifés (sans FWD)" {...props} />
)
export const ButtonFWD = (props) => (
  <ButtonProduct categorie="FWD" label="Fine Wine Desk" {...props} />
)

///////// Boutton pour aller sur la page des Primeurs /////////
export const ButtonPrim = (props) => {
  const translate=useTranslate();
  return(
        <Button
          component={Link}
          to={`/Primeurs`}
          label={translate("resources.products.button.ListProd")}
        >
        </Button>
)};
