import * as React from 'react';
import { useTranslate } from 'react-admin';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    makeStyles
} from '@material-ui/core';
import {ButtonFWD} from './buttons';
import {ButtonTarif} from './buttons';
import {ButtonHorsTarif} from './buttons';
//import {ButtonPrim} from './buttons';

import draw0 from '../assets/img/crus-classs-fond-noir.jpg';
import draw1 from '../assets/img/Chateau.png';
//import draw2 from '../assets/img/commercialisation3.jpg';

const useStyles = makeStyles({
    root: {
        marginTop: '1em',
    },
    media: {
        height: 140,
    },
    title: {
        paddingBottom: '0.5em',
    },
    actionSpacer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
});

const Categories = (props) => {
    const translate = useTranslate();
    const classes = useStyles(props);
    return (
      <Grid container spacing={2} className={classes.root} >
        <Grid  xs={12} sm={6} md={4} lg={3} xl={2} item>
          <Card>
            <CardMedia component="img" image={draw0} className={classes.media}></CardMedia>
            <CardContent className={classes.title}>
              <Typography
                variant="h5"
                component="h2"
                align="center"
              >
                {translate("resources.products.fields.FWD")}
              </Typography>
            </CardContent>
            <CardActions classes={{ spacing: classes.actionSpacer }}>
              <ButtonFWD />
            </CardActions>
          </Card>
        </Grid>

        <Grid  xs={12} sm={6} md={4} lg={3} xl={2} item >
          <Card>
            <CardMedia component="img" image={draw1} className={classes.media} ></CardMedia>
            <CardContent className={classes.title}>
              <Typography
                  variant="h5"
                  component="h2"
                  align="center"
              >
                  {translate("resources.products.fields.Tarif")}
              </Typography>
            </CardContent>
            <CardActions classes={{ spacing: classes.actionSpacer }}>
              <ButtonTarif />
            </CardActions>
          </Card>
        </Grid>

        <Grid  xs={12} sm={6} md={4} lg={3} xl={2} item >
          <Card>
            <CardMedia component="img" image={draw1} className={classes.media} ></CardMedia>
            <CardContent className={classes.title}>
              <Typography
                  variant="h5"
                  component="h2"
                  align="center"
              >
                  {translate("resources.products.fields.HorsTarif")}
              </Typography>
            </CardContent>
            <CardActions classes={{ spacing: classes.actionSpacer }}>
              <ButtonHorsTarif />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    )
};
const exports = {
  list: Categories
}
export default exports;
