import * as React from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';

import { useMediaQuery } from '@material-ui/core';

import {LinkField} from '../customComponents/LinkField';
import {NoResult} from "../customComponents/NoResult";
import {SubListPagination} from "../customComponents/SubListPagination";

import { makeStyles } from '@material-ui/core/styles';
import {orange} from "@material-ui/core/colors";

import {
  subMonths,
} from 'date-fns';

const useStyles = makeStyles({
    header: {
        background: orange[300],
    },
    cell: {
      background: orange[300],
    },
});


export const ClientOrderList = (props) =>{

  return(
      <List
          resource="orders"
          basePath="/orders"
          filter={{eq_cl_code_fac: "'" + props.cl_code + "'",
                  eq_ce_acti: "'0'",
                  "com.t32_typ": " IN ('01', '02', '05', '07', 'EX', 'FS', 'SX', 'X5')",
                  gte_ce_datmodif: "'" + subMonths(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours()), 24).toISOString() +"'"
                }}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          hasEdit={false}
          hasList={false}
          hasShow={false}
          empty={<NoResult/>}
          pagination={<SubListPagination/>}
          sort={{field: "ce_datsais", order: "DESC"}}
          title=" "
      >
          <Datagrid >
            <LinkField source="ce_num" label="resources.orders.fields.order_num" linkValue="id" {...props}/>
            <TextField source="typeBon.ta_lib" label={`resources.orders.fields.type`} />
            <DateField source="ce_datsais" label={"resources.orders.fields.dates.input"} />
            <DateField source="ce_datenl" label={"resources.orders.fields.dates.takeoff"} />
            <NumberField source='ce_cacdeht' label={`resources.orders.fields.turnover`} />
            <FunctionField {...props}
              render={record => `${record.deliveredCustomer.cl_rsoc1} (${record.cl_code_liv})`}
              label={`resources.orders.fields.deliv_customer`}  />
          </Datagrid>
      </List>
  )
}

export const ClientSampleList = (props) =>{

  return(
      <List
        {...props}
          resource="clientSamples"
          basePath="/clientSamples"
          filter={{"eq_com.cl_code_fac": "'" + props.cl_code + "'",
                  eq_ce_acti: "'0'"
                  }}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          pagination={<SubListPagination/>}
          sort={{field: "com.ce_datenl", order: "DESC"}}
          title=" "
          empty={<NoResult/>}
      >
          <Datagrid >
            <TextField source="ce_num" label="resources.orders.fields.order_num" sortBy='"ce_num"'/>
            <DateField source="com.ce_datfac" label={"resources.orders.fields.dates.invoice"} sortBy='"com.ce_datfac"'/>
            <TextField source='product.ba_lib' label={`resources.orders.fields.product_name`} sortBy='"product.ba_lib"'/>
            <TextField source='millesime' label={`resources.orders.fields.millesime`} sortBy='"millesime"'/>
            <NumberField source='ce_qte' label={`resources.orders.fields.qty`} sortBy='"ce_qte"'/>
          </Datagrid>
      </List>
  )
}

export const ClientLastProductsList = (props) =>{

  return(
      <List
          resource="clientLastProducts"
          basePath="/clientLastProducts"
          filter={{"eq_com.cl_code_fac": "'" + props.cl_code + "'",   eq_ce_acti: "'0'"}}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          hasEdit={false}
          hasList={false}
          hasShow={false}
          pagination={<SubListPagination/>}
          sort={{field: "ba_lib", order: "ASC"}}
          title=" "
          empty={<NoResult/>}
      >
          <Datagrid expand={<LastProductExpand cl_code={props.cl_code} />}>
            <TextField source='ba_lib' label={`resources.orders.fields.product_name`} />
            <TextField source='millesime' label={`resources.orders.fields.millesime`} sortBy='"millesime"'/>
            <NumberField source='eq75' label={`resources.orders.fields.eq75`} sortBy='"eq75"'/>
          </Datagrid>
      </List>
  )
}

export const ClientProductOrdersList = (props) =>{
  return(
      <List
          resource="clientProductOrders"
          basePath="/clientProductOrders"
          filter={{"eq_b1010.cl_code_fac": "'" + props.cl_code + "'",
                  "eq_ba_lib": "'"+props.id.substring(2).replace("'", "''")+"'",
                  "eq_ba_mil": "'"+props.id.substring(0,2)+"'",
                  gte_ce_datsais: "'" + subMonths(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0), 36).toISOString() +"'"

                  }}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          hasEdit={false}
          hasList={false}
          hasShow={false}
          empty={<NoResult/>}
          pagination={<SubListPagination/>}
          sort={{field: "ce_datsais", order: "DESC"}}
          title=" "
      >
          <Datagrid fullWidh>
            <DateField source="ce_datsais" label="resources.orders.fields.dates.input" />
            <TextField source="st_code" label={`resources.orders.fields.product`} />
            <LinkField source='ce_num' label={`resources.orders.fields.order_num`} linkValue="ce_num" {...props} basePath="/orders" />
            <NumberField source='eq75' label={`resources.orders.fields.eq75`} />
            <NumberField source="ce_pu" label="resources.orders.fields.unit_price" />
          </Datagrid>
      </List>
  )
}

const LastProductExpand = (props) => {
  return(
    <SimpleForm toolbar={false} >
      <SimpleShowLayout>
        <ClientProductOrdersList {...props} />
      </SimpleShowLayout>
    </SimpleForm>
  )
}

export const ClientReservesList = (props) =>{

  return(
      <List
          resource="clientReserves"
          basePath="/clientReserves"
          filter={{"eq_B1010.cl_code_fac": "'" + props.cl_code + "'",   "eq_b1010.ce_acti": "'0'"}}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          empty={<NoResult/>}
          pagination={<SubListPagination/>}
          sort={{field: "\"id\"", order: "ASC"}}
          title=" "
      >
          <Datagrid >
            <DateField source="CE_DATSAIS" label={`resources.orders.fields.dates.input`} />
            <DateField source="CE_DATEXP" label={`resources.orders.fields.dates.expiration`} />
            <TextField source="CE_NUM" label={`resources.orders.fields.reserve_number`} />
            <TextField source='BA_LIB' label={`resources.orders.fields.product_name`} sortBy='"BA_LIB"'/>
            <TextField source='millesime' label={`resources.orders.fields.millesime`} sortBy='"millesime"'/>
            {/*<TextField source="COULEUR" label={`resources.products.fields.libCoul`} /> (ticket 369)*/}
            <TextField source="ST_DESI" label={`resources.products.fields.libCondit`} />
            <NumberField source='SOLDE' label={`resources.orders.fields.solde`} sortBy="SOLDE" />
            <NumberField source="PRMP" label={`resources.orders.fields.prmp`} options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}} />
            <NumberField source="TOTAL_PR" label={`resources.orders.fields.total_pr`} options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 0}} />
          </Datagrid>
      </List>
  )
}

const ClientHistoryListDetail = (props) => {
  const translate = useTranslate();
  const style = useStyles();
  const isLarge = useMediaQuery(theme =>theme.breakpoints.up('lg'));

  return(
      <List
          resource="customerHistoryDetail"
          basePath="/customerHistoryDetail"
          filter={{"@cl_code_fac": "'" + props.cl_code + "'", "@ba_prod": "'" + props.id + "'"}}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          empty={<NoResult/>}
          sort={{field: '"n0.volume"', order: "DESC"}}
          title=" "
      >
          <Datagrid >
            <TextField source='st_desi' label={`resources.products.fields.condit`} />
            <TextField source='millesime' label={`resources.products.fields.mill`} />
            {isLarge &&
              <NumberField source="n4.ca" label={translate("resources.orders.fields.sale_price_eq75_year", {year: new Date().getFullYear()-4})}
                options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}} />
            }
            <NumberField source="n3.ca" label={translate("resources.orders.fields.sale_price_eq75_year", {year: new Date().getFullYear()-3})}
              options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}} />
            <NumberField source="n2.ca" label={translate("resources.orders.fields.sale_price_eq75_year", {year: new Date().getFullYear()-2})}
              options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}} />
            <NumberField source="n1.ca" label={translate("resources.orders.fields.sale_price_eq75_year", {year: new Date().getFullYear()-1})}
              options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}} />
            <NumberField source="n0.ca" label={translate("resources.orders.fields.sale_price_eq75_year", {year: new Date().getFullYear()})}
              options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}} />
            {isLarge && <NumberField source='n4.volume' label={translate("resources.orders.fields.qty_by_year", {year: new Date().getFullYear()-4})}
              headerClassName={style.header} cellClassName={style.cell}/>}
            <NumberField source='n3.volume' label={translate("resources.orders.fields.qty_by_year", {year: new Date().getFullYear()-3})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n2.volume' label={translate("resources.orders.fields.qty_by_year", {year: new Date().getFullYear()-2})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n1.volume' label={translate("resources.orders.fields.qty_by_year", {year: new Date().getFullYear()-1})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n0.volume' label={translate("resources.orders.fields.qty_by_year", {year: new Date().getFullYear()})}
              headerClassName={style.header} cellClassName={style.cell}/>
          </Datagrid>
      </List>
  )
}
export const ClientHistoryList = (props) => {
  const translate = useTranslate();
  const style = useStyles();
  const isLarge = useMediaQuery(theme =>theme.breakpoints.up('lg'));

  return(
      <List
          resource="customerHistory"
          basePath="/customerHistory"
          filter={{"@cl_code_fac": "'" + props.cl_code + "'"}}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          empty={<NoResult/>}
          sort={{field: '"n0.volume"', order: "DESC"}}
          title=" "
      >
          <Datagrid expand={<ClientHistoryListDetail cl_code={props.cl_code} {...props} />}>
            <TextField source='ba_lib' label={`resources.orders.fields.product_name`} />
            <TextField source='ta_libr' label={`resources.orders.fields.colour`} />
            {isLarge && <NumberField source="n4.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-4})} />}
            <NumberField source="n3.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-3})} />
            <NumberField source="n2.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-2})} />
            <NumberField source="n1.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-1})} />
            <NumberField source="n0.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()})} />
            {isLarge && <NumberField source='n4.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-4})}
              headerClassName={style.header} cellClassName={style.cell}/>}
            <NumberField source='n3.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-3})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n2.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-2})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n1.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-1})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n0.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()})}
              headerClassName={style.header} cellClassName={style.cell}/>
          </Datagrid>
      </List>
  )
}
