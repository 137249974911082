import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { withRouter } from "react-router";
import {useTranslate} from 'react-admin';

const TmpCustomBreadcrumbs = (props) => {
  const translate = useTranslate();
  const { location, history } = props;
  const [links, setLinks] = useState([]);
  const [estRetourArriere, setEstRetourArriere] = useState(false);
  useEffect(()=>{

    let pathnames = JSON.parse(localStorage.getItem('pathHistory')) || [];
    function camelize(str) {
      return str.replace(/(?:^\w|[A-Z])/g, function(word, index) {
        return word.toUpperCase();
      });
    }
    let toReturn = location.pathname.split('/').filter(x=>x);
  
    /*//Page conditionnement d'un produit
    if(toReturn.includes("conditionnement")){
      toReturn = "Conditionnement n°"+toReturn[toReturn.length-1];
    }
  
    //Page détail d'un produit
    if(toReturn.includes("products") && toReturn.includes("details") && toReturn.indexOf("products")===toReturn.length-3){
      toReturn = "Produit n°"+toReturn[toReturn.length-1];
    }
  
    //Pages liste selon la catégorie
    if(toReturn.includes("FWD") && toReturn.indexOf("FWD")===toReturn.length-1){
      toReturn = "Fine Wine Desk";
    }
    if(toReturn.includes("Tarif") && toReturn.indexOf("Tarif")===toReturn.length-1){
      toReturn = "Tarifs";
    }*/
  
    //Pages détails - Clic dans une liste
    if(toReturn.includes("customers") && toReturn.indexOf("customers")<=toReturn.length-2){
      toReturn = translate("resources.customers.customer")+" n°"+toReturn[toReturn.indexOf("customers")+1];
    }
    if(toReturn.includes("offres") && toReturn.indexOf("offres")<=toReturn.length-2){
      toReturn = translate("resources.offres.offre")+" n°"+toReturn[toReturn.indexOf("offres")+1];
    }
    if(toReturn.includes("orders") && toReturn.indexOf("orders")<=toReturn.length-2){
      toReturn = translate("resources.order.order")+" n°"+toReturn[toReturn.indexOf("orders")+1];
    }
    if(toReturn.includes("prospect") && toReturn.indexOf("prospect")<=toReturn.length-2){
      toReturn = translate("pos.prospect")+" n°"+toReturn[toReturn.indexOf("prospect")+1];
    }
  
    //Pages listes - Clic dans le menu
    if (toReturn.includes("customers") && toReturn.indexOf("customers")===toReturn.length-1) {
      toReturn = translate("pos.menu.customers");
    }
    if(toReturn.includes("categories") && toReturn.indexOf("categories")===toReturn.length-1){
      toReturn = translate("pos.menu.categories");
    }
    if (toReturn.includes("invoices") && toReturn.indexOf("invoices")===toReturn.length-1) {
      toReturn = translate("pos.menu.invoices");
    }
    if (toReturn.includes("orders") && toReturn.indexOf("orders")===toReturn.length-1) {
      toReturn = translate("pos.menu.orders");
    }
    if (toReturn.includes("prospect") && toReturn.indexOf("prospect")===toReturn.length-1) {
      toReturn = translate("pos.menu.prospect");
    }
    if (toReturn.includes("offres") && toReturn.indexOf("offres")===toReturn.length-1) {
      toReturn = translate("pos.menu.offres");
    }
    if (toReturn.includes("profile") && toReturn.indexOf("profile")===toReturn.length-1) {
      toReturn = translate("pos.menu.profile");
    }
    if (toReturn.includes("configuration") && toReturn.indexOf("configuration")===toReturn.length-1) {
      toReturn = translate("pos.menu.configuration");
    }
  
    if(!toReturn || Array.isArray(toReturn)){toReturn=toReturn[0]||translate("pos.dashboard.name");}
    //console.log("toReturn : " , toReturn);

    let urlName =camelize(toReturn);
    if(pathnames.length === 0){
      pathnames.push({to:'/', params:null, name: translate("pos.dashboard.name"), nbBacks:0});
    }
    if ( !pathnames.map(item=>item.to).includes(location.pathname) && !pathnames.map(item=>item.name).includes(urlName) ) {
      let paths = location.pathname.split('/').filter(x=>(x));
      ["customers","categories","invoices","orders","prospect","offres","profile","configuration"].forEach((key, index) => {
        if(paths.includes(key) && paths.indexOf(key)===paths.length-1){
          pathnames=pathnames.slice(0,1);
        }
      });

      pathnames=pathnames.map((item)=>{
        return {...item, nbBacks:item.nbBacks+1};
      });
      //nbBacks désigne le nombre de retours nécessaires pour atteindre la page
      //A chaque changement de page, augmente de 1
      pathnames.push({to:location.pathname, params:location.search, name: urlName, nbBacks:0});

    }else if(pathnames.map(item=>item.to).includes(location.pathname) || pathnames.map(item=>item.name).includes(urlName)){
      let index = pathnames.map(item=>item.to).indexOf(location.pathname);
      if(index === -1){
        index = pathnames.map(item=>item.name).indexOf(urlName);
      }
      let deltaBack = pathnames[index].nbBacks;
      pathnames = pathnames.slice(0,index);
      if(estRetourArriere){
        pathnames=pathnames.map((item)=>{
          return {...item, nbBacks:item.nbBacks-deltaBack};
        });
        setEstRetourArriere(false);
      }else if(deltaBack>0){
        pathnames=pathnames.map((item)=>{
          return {...item, nbBacks:item.nbBacks+1};
        });
      }
      pathnames.push({to:location.pathname, params:location.search, name: urlName, nbBacks:0});
    }
    localStorage.setItem('pathHistory', JSON.stringify(pathnames));
    const tmpLinks = pathnames.map((item, index)=>{
      let isLast = (index === pathnames.length - 1);
      let name = item.name;
      let to = item.to;
      let nbBacks = item.nbBacks;

      return isLast || !to ?
      (
        <Typography color="textPrimary" key={to}>
          {name}
        </Typography>
      ) : (
        <Link color="inherit" style={{cursor:"pointer", textDecoration: "none"}} nohref onClick={()=>{setEstRetourArriere(true);history.go(-nbBacks);return false;}} key={to}>
          {name}
        </Link>
      );
    });
    setLinks(tmpLinks);
  },[location]);

  return(
    <Breadcrumbs aria-label="breadcrumb" style={{margin:"10px 0 0 0"}}>
      {links}
    </Breadcrumbs>
  );
}

export const CustomBreadcrumbs = withRouter(TmpCustomBreadcrumbs);
