import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    useCreate,
    useNotify,
    FormWithRedirect,
    NumberInput,
    useTranslate,
    useRefresh,
    ReferenceInput,
    FormDataConsumer,
    AutocompleteInput,
    TextInput,
    useGetMany ,
    Loading,
    Error
  } from 'react-admin';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  DialogActions,
  Grid,
  Table
} from '@material-ui/core';
import {TextField as MuiTextField } from '@material-ui/core';

const useStyles = makeStyles({
    rightAlignedCell: { textAlign: 'right' },
});

function CreateOffreFWD(props) {
  const translate = useTranslate();
  const code = props.selectedIds;
  const { data,loading, error } = useGetMany (
        'addProductsLookup',
         props.selectedIds
    );
  const classes= useStyles;
  const [create] = useCreate('lignes_offres');
  const notify = useNotify();
  const form = useForm();
  const refresh = useRefresh();
  if (loading) { return <Loading />; }
  if (error) { return <Error/>; }
  const handleSubmit = async values => {
    values.id = null;
      create(
          { payload: { data: values } },
          {
              onSuccess: ({ data }) => {
                // console.log(data);
                  notify(translate("resources.offres.prod.Succesprod"), 'success')
                  form.change('id', data.id);
                  refresh();
              },
              onFailure: ({ error }) => {
                  notify(translate("resources.offres.prod.Failprod"), 'warning')
              },
          }
      );
  };
  return (
    <FormWithRedirect
      resource="lignes_offres"
      save={handleSubmit}
      render={({
          handleSubmitWithRedirect,
          pristine,
          saving
      }) => (
        <>
          <Grid item xs={12} sm={12} md={4}>
            <TextInput disabled label="resources.offres.offres" source="id_offre" defaultValue={props.id}/>
          </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                      {translate("resources.offres.field.codeProd")}
                  </TableCell>
                  <TableCell>
                      {translate("resources.offres.field.lib")}
                  </TableCell>
                  <TableCell className={classes.rightAlignedCell}>
                      {translate("resources.offres.field.mill")}
                  </TableCell>
                  <TableCell className={classes.rightAlignedCell}>
                      {translate("resources.offres.field.format")}
                  </TableCell>
                  <TableCell className={classes.rightAlignedCell}>
                      {translate("resources.offres.field.prix")}
                  </TableCell>
                  <TableCell className={classes.rightAlignedCell}>
                      {translate("resources.offres.field.qte")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow >
                  <TableCell>
                    <TextInput label=" " source="code_produit" required/>
                  </TableCell>
                  <TableCell>
                    <TextInput label=" " source="libelle_produit" required/>
                  </TableCell>
                  <TableCell>
                    <TextInput label=" " source="millesime" required/>
                  </TableCell>
                  <TableCell>
                    <TextInput label=" " source="st_desi" required/>
                  </TableCell>
                  <TableCell>
                    <TextInput label=" " source="prix"/>
                  </TableCell>
                  <TableCell>
                    <NumberInput label=" " source="qte" />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <DialogActions>
                <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    disabled={loading}
                />
            </DialogActions>
          </>
        )}
    />
  );
}
export default CreateOffreFWD;

// <FormDataConsumer>
//   {({formData, ...rest}) => {
//     const componentFilter = {}; // filter on component to link each other
//     if(formData.libelle_produit) Object.assign(componentFilter, {["eq_libelle"]: "'" + formData.libelle_produit + "'"}); // eslint-disable-line
//     else delete componentFilter["libelle_produit"];
//     if(formData.millesime) Object.assign(componentFilter, {["eq_millesime"]: "'" + formData.millesime+ "'"}); // eslint-disable-line
//     else delete componentFilter["millesime"];
//     if(formData.st_desi) Object.assign(componentFilter, {["eq_st_desi"]: "'" + formData.st_desi+ "'"}); // eslint-disable-line
//     else delete componentFilter["st_desi"];
//     if(formData.prix) Object.assign(componentFilter, {["eq_prix_vente"]: "'" + formData.prix+ "'"}); // eslint-disable-line
//     else delete componentFilter["prix"];
//
//     return(
//       <ReferenceInput
//         fullWidth label=' '
//         source="code_produit" reference="codeLookup"
//         perPage={1500}
//         filter={Object.assign({}, componentFilter)}
//         filterToQuery={searchText => ({likeic_st_code: [searchText]})}
//         resettable
//       >
//         <AutocompleteInput validate={required()} optionText="id" resettable {...rest}/>
//       </ReferenceInput>
//     )
//
//   }}
// </FormDataConsumer>
// </TableCell>
//
// <TableCell>
// <FormDataConsumer>
//   {({formData, ...rest}) => {
//     const componentFilter = {}; // filter on component to link each other
//
//     if(formData.code_produit) Object.assign(componentFilter, {["eq_st_code"]: "'" + formData.code_produit+ "'"}); // eslint-disable-line
//     else delete componentFilter["code_produit"];
//     if(formData.millesime) Object.assign(componentFilter, {["eq_millesime"]: "'" + formData.millesime+ "'"}); // eslint-disable-line
//     else delete componentFilter["millesime"];
//     if(formData.st_desi) Object.assign(componentFilter, {["eq_st_desi"]: "'" + formData.st_desi+ "'"}); // eslint-disable-line
//     else delete componentFilter["st_desi"];
//     if(formData.prix) Object.assign(componentFilter, {["eq_prix_vente"]: "'" + formData.prix+ "'"}); // eslint-disable-line
//     else delete componentFilter["prix"];
//     return(
//       <ReferenceInput
//         fullWidth label=' '
//         source="libelle_produit" reference="libelleLookup"
//         perPage={500} sort={{ field: 'id', order: 'ASC' }}
//         filter={Object.assign({}, componentFilter)}
//         filterToQuery={searchText => ({likeic_libelle: [searchText]})}
//       >
//         <AutocompleteInput validate={required()} optionText="id" resettable/>
//       </ReferenceInput>
//     )
//   }}
// </FormDataConsumer>
// </TableCell>
//
// <TableCell className={classes.rightAlignedCell}>
// <FormDataConsumer>
//   {({formData, ...rest}) => {
//     const componentFilter = {}; // filter on component to link each other
//
//     if(formData.libelle_produit) Object.assign(componentFilter, {["eq_libelle"]: "'" + formData.libelle_produit+ "'"}); // eslint-disable-line
//     else delete componentFilter["libelle_produit"];
//     if(formData.code_produit) Object.assign(componentFilter, {["eq_st_code"]: "'" + formData.code_produit+ "'"}); // eslint-disable-line
//     else delete componentFilter["code_produit"];
//     if(formData.st_desi) Object.assign(componentFilter, {["eq_st_desi"]: "'" + formData.st_desi+ "'"}); // eslint-disable-line
//     else delete componentFilter["st_desi"];
//     if(formData.prix) Object.assign(componentFilter, {["eq_prix_vente"]: "'" + formData.prix+ "'"}); // eslint-disable-line
//     else delete componentFilter["prix"];
//     return(
//       <ReferenceInput
//         fullWidth label=' '
//         source="millesime" reference="millesimeLookup"
//         perPage={500} sort={{ field: 'id', order: 'DESC' }}
//         filter={Object.assign({}, componentFilter)}
//         filterToQuery={searchText => ({likeic_millesime: [searchText]})}
//       >
//         <AutocompleteInput optionText="id" validate={required()} resettable/>
//       </ReferenceInput>
//     )
//   }}
// </FormDataConsumer>
// </TableCell>
//
// <TableCell className={classes.rightAlignedCell}>
// <FormDataConsumer>
//   {({formData, ...rest}) => {
//     const componentFilter = {}; // filter on component to link each other
//
//     if(formData.libelle_produit) Object.assign(componentFilter, {["eq_libelle"]: "'" + formData.libelle_produit+ "'"}); // eslint-disable-line
//     else delete componentFilter["libelle_produit"];
//     if(formData.millesime) Object.assign(componentFilter, {["eq_millesime"]: "'" + formData.millesime+ "'"}); // eslint-disable-line
//     else delete componentFilter["millesime"];
//     if(formData.code_produit) Object.assign(componentFilter, {["eq_st_code"]: "'" + formData.code_produit+ "'"}); // eslint-disable-line
//     else delete componentFilter["code_produit"];
//     if(formData.prix) Object.assign(componentFilter, {["eq_prix_vente"]: "'" + formData.prix+ "'"}); // eslint-disable-line
//     else delete componentFilter["prix"];
//     return(
//       <ReferenceInput
//         fullWidth label=' '
//         source="st_desi" reference="conditLookup"
//         perPage={500} sort={{ field: 'id', order: 'ASC' }}
//         filter={Object.assign({}, componentFilter)}
//         filterToQuery={searchText => ({likeic_st_desi: [searchText]})}
//       >
//         <AutocompleteInput optionText="id" validate={required()} resettable/>
//       </ReferenceInput>
//     )
//   }}
// </FormDataConsumer>
// </TableCell>
//
// <TableCell className={classes.rightAlignedCell}>
// <FormDataConsumer>
//   {({formData, ...rest}) => {
//     const componentFilter = {}; // filter on component to link each other
//
//     if(formData.libelle_produit) Object.assign(componentFilter, {["eq_libelle"]: "'" + formData.libelle_produit+ "'"}); // eslint-disable-line
//     else delete componentFilter["libelle_produit"];
//     if(formData.millesime) Object.assign(componentFilter, {["eq_millesime"]: "'" + formData.millesime+ "'"}); // eslint-disable-line
//     else delete componentFilter["millesime"];
//     if(formData.st_desi) Object.assign(componentFilter, {["eq_st_desi"]: "'" + formData.st_desi+ "'"}); // eslint-disable-line
//     else delete componentFilter["st_desi"];
//     if(formData.code_produit) Object.assign(componentFilter, {["eq_st_code"]: "'" + formData.code_produit+ "'"}); // eslint-disable-line
//     else delete componentFilter["code_produit"];
//     return(
//       <ReferenceInput
//         fullWidth label=' '
//         source="prix" reference="prixLookup"
//         perPage={500} sort={{ field: 'prix_vente', order: 'ASC' }}
//         filter={Object.assign({}, componentFilter)}
//       >
//         <AutocompleteInput optionText="id" resettable/>
//       </ReferenceInput>
//     )
//   }}
// </FormDataConsumer>
// </TableCell>
// <TableCell>
// <NumberInput label=" " source="qte" />
// </TableCell>
