import * as React from "react";
import { Box} from '@material-ui/core';
import { TextInput} from 'react-admin/lib';

export const SocialNetwork = (record,props)=>{
    //const validateEmail = email();

    return (
      <Box>
        <Box>
          <TextInput source='facebook' label="resources.customers.fields.facebook" variant="outlined" fullWidth/>
        </Box>
        <Box>
          <TextInput source='instagram' label="resources.customers.fields.instagram" variant="outlined" fullWidth/>
        </Box>
        <Box>
         <TextInput source='linkedin' label="resources.customers.fields.linkedin" variant="outlined" fullWidth/>
        </Box>
        <Box>
          <TextInput source='twitter' label="resources.customers.fields.twitter" variant="outlined" fullWidth/>
        </Box>
        <Box>
          <TextInput source='whatsapp' label="resources.customers.fields.whatsapp" variant="outlined" fullWidth/>
        </Box>
        <Box>
          <TextInput source='tiktok' label="resources.customers.fields.tiktok"  variant="outlined" fullWidth/>
        </Box>
        <Box>
          <TextInput source='wechat' label="resources.customers.fields.wechat"  variant="outlined" fullWidth/>
        </Box>
      </Box>
    );
  }
