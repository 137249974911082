import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    useCreate,
    useGetList,
    useNotify,
    FormWithRedirect,
    DateInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    NumberInput,
    useTranslate,
    useRefresh,
    useRedirect,
    ReferenceInput,
    ReferenceManyField,
    FileInput,
    FileField,
    Loading,
    Error,
    useGetOne,
    TextField
} from 'react-admin';
import {
  Typography,
  Box,
  CardContent,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import {Dialog, DialogTitle, DialogContent,DialogActions} from '@material-ui/core';

const util = require('../utils');
let userFilter = util.getUserFilter();
const useStyles = makeStyles({
    rightAlignedCell: { textAlign: 'right' },
});
function AddOffer(props,{ record }) {
  const classes= useStyles;

  const [showDialog, setShowDialog] = useState(false);
  const [create] = useCreate('lignes_offres');
  const notify = useNotify();
  const userFilter =util.getUserFilter();
  const translate = useTranslate();
  const refresh = useRefresh();
  const form = useForm();
  const redirect = useRedirect();
  const { data, loading, error } = useGetOne(props.resource, props.id);

  //console.log(data);
  //console.log(props);
  if (loading) { return <Loading />; }
  if (error) { return <Error />; }

  const handleSubmit = async values => {
      create(
          { payload: { data: values } },
          {
              onSuccess: ({ data }) => {
                  setShowDialog(false);
                  notify('Le produit '+ data.libelle_produit +' a été ajouté')
                  form.change('id', data.id);
                  refresh();
              },
              onFailure: ({ error }) => {
                  notify(error.message, 'Le produit n\'a pu être ajouté ');
                  refresh()
              },
          }
      );
  };
  return (
    <FormWithRedirect
        resource="offres"
        save={handleSubmit}
        render={({
            handleSubmitWithRedirect,
            pristine,
            saving
        }) => (
            <>
            <Card fullWidth>
                          <form>
                              <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    {"Offre"}
                                </Typography>
                                <ReferenceInput fullWidth  label="Offre" source="id_offre" reference="offres">
                                    <SelectInput optionText="libelle" />
                                </ReferenceInput>
                                <Box
                                    flex={2}
                                    ml={{ xs: 0, sm: '0.5em' }}
                                />
                                <Box
                                    flex={1}
                                    ml={{ xs: 0, lg: '1em' }}
                                    mt={{ xs: '1em', lg: 0 }}
                                >
                                  <Typography variant="h6" gutterBottom>
                                  {translate(
                                      'resources.products.fields.prod'
                                  )}
                                  </Typography>

                                  <Table >
                                      <TableHead >
                                      <TableRow>
                                        <TableCell>
                                            {translate("resources.offres.field.codeProd")}
                                        </TableCell>
                                        <TableCell>
                                            {translate("resources.offres.field.lib")}
                                        </TableCell>
                                        <TableCell className={classes.rightAlignedCell}>
                                            {translate("resources.offres.field.mill")}
                                        </TableCell>
                                        <TableCell className={classes.rightAlignedCell}>
                                            {translate("resources.offres.field.format")}
                                        </TableCell>
                                        <TableCell className={classes.rightAlignedCell}>
                                            {translate("resources.offres.field.prix")}
                                        </TableCell>
                                        <TableCell className={classes.rightAlignedCell}>
                                            {translate("resources.offres.field.qte")}
                                        </TableCell>
                                      </TableRow>
                                      </TableHead>
                                      <TableBody>
                                                    <TableRow>
                                                    <TableCell>
                                                    <TextInput
                                                     label=" "
                                                        source="code_produit"
                                                        resource="lignes_offres"
                                                        defaultValue={data.st_code}
                                                        />
                                                    </TableCell>
                                                          <TableCell>
                                                          <TextInput
                                                           label=" "
                                                              source="libelle_produit"
                                                              resource="lignes_offres"
                                                              defaultValue={data.libelle}
                                                              />
                                                          </TableCell>
                                                          <TableCell >
                                                          <TextInput
                                                           label=" "
                                                              source="millesime"
                                                              resource="lignes_offres"
                                                              defaultValue={data.millesime}
                                                              />

                                                          </TableCell>
                                                          <TableCell >
                                                          <TextInput
                                                           label=" "
                                                              source="st_desi"
                                                              resource="lignes_offres"
                                                              defaultValue={data.st_desi}
                                                              />

                                                          </TableCell>
                                                          <TableCell >
                                                          <TextInput
                                                           label=" "
                                                              source="prix"
                                                              resource="lignes_offres"
                                                              defaultValue={data.prix_vente}
                                                              />

                                                          </TableCell>
                                                          <TableCell>
                                                            <NumberInput label=" " source="qte" />
                                                          </TableCell>

                                                      </TableRow>

                                      </TableBody>
                                  </Table>
                                      </Box>
                              </CardContent>
                          </form>
                      </Card>

                <DialogActions>
                    <SaveButton
                        handleSubmitWithRedirect={
                            handleSubmitWithRedirect
                        }
                        pristine={pristine}
                        saving={saving}
                        disabled={loading}
                    />
                </DialogActions>
            </>
        )}
    />
  );
}
export default AddOffer;
