import {
  Edit,
  PasswordInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar
} from 'react-admin';

import {
  Grid
} from '@material-ui/core';

const SaveButtonToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const Profile = (props) => {
  return (
    <Edit
      id={localStorage.getItem("username")}
      resource="profile"
      basePath="/profile"
      redirect={false}
      title="Mon profil"
      onSuccess={({data})=>{
        localStorage.setItem("username", data.identifiant);
        localStorage.setItem("firstName", data.prenom);
        window.location.reload(false);
      }}
      undoable={false}
      {...props}
    >
      <SimpleForm {...props} toolbar={<SaveButtonToolbar />}>
        <TextInput source="uid" style={{visibility: "hidden"}} />
        <Grid container spacing={1} style={{width:"100%"}}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <TextInput source="codeutil" disabled label="resources.users.fields.code" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <TextInput source="identifiant" label="resources.users.fields.id" fullWidth/>
            </Grid>
            <Grid item xs={4}>
              <PasswordInput source="mot_de_passe" label="resources.users.fields.password" fullWidth/>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6}>
              <TextInput source="prenom"  label="resources.users.fields.first_name" fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <TextInput source="nom" label="resources.users.fields.last_name" fullWidth/>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6}>
              <TextInput source="mail_user"  label="resources.users.fields.mail_user" fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <PasswordInput source="mail_pwd" label="resources.users.fields.mail_pwd" fullWidth/>
            </Grid>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
