import * as React from "react";
import {
  Box,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import {useTranslate} from 'react-admin';
import {
  TextInput
} from 'react-admin';

export const CustomerFileShow = (record,props)=>{
    const translate=useTranslate();
    const isLarge = useMediaQuery(theme =>theme.breakpoints.up('lg'));
    const nbRows= isLarge? 13: 10;

    return (
      <Box >
        <Box display="flex">
          <Box flex={1} mr="0.25em">

            {/* Sprint 7 1/6/2021 switch entre commentaire 1 et commentaire 4
            <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.fields.comment1')}</Typography> */}
            <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.fields.comment4')}</Typography>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput {...props}  source="v_dossier_client.cl6_comment" label=' ' multiline rows={nbRows}  fullWidth disabled={true}/>
              </Box>
            </Box>

          </Box>

          <Box flex={1}>

            <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.fields.comment2')}</Typography>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput {...props}  source="commandes" label = ' '  multiline rows={nbRows} fullWidth />
              </Box>
            </Box>

          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} mr="0.25em">

            <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.fields.comment3')}</Typography>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput {...props}  source="offres" label=' '  multiline rows={nbRows} fullWidth />
              </Box>
            </Box>
          </Box>

          <Box flex={1}>

            {/* Sprint 7 1/6/2021 switch entre commentaire 1 et commentaire 4
            <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.fields.comment4')}</Typography> */}
            <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.fields.comment1')}</Typography>

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput {...props}  source="shipping" label = ' '  multiline rows={nbRows}  fullWidth />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
