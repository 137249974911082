import * as React from 'react';

import { Link } from 'react-router-dom';

// standard GUI components
import {
  Box,
  Card,
  Divider,
  Typography,
} from '@material-ui/core';


// import pour ticket 378
import Loader from 'react-loader-spinner';

import {
  useDataProvider,
  useLocale,
  useTranslate,
} from 'react-admin';

import {
    useEffect,
    useState
  } from 'react';

// hooks
import { createElement } from 'react';

// custom GUI & icons
import { infoBoxStyles } from '../style';

//////////////////////////////////////////////////////////////////////////////////////////////
// util est pour le ticket 378
const util = require('../utils');



const InfoBox = (props) => {
    const { spanBgColor, icon, title, value, to, children } = props;
    const classes = infoBoxStyles({spanBgColor: props.spanBgColor});
    const dataProvider= useDataProvider();
    const translate = useTranslate();
    const locale = useLocale();

    const [kpis, setKpis] = useState({
      isLoading:true,
      isError: false,
      data:[{id:0, count:0}]
    });

    useEffect(()=> {
        dataProvider.getList(props.value,{
            pagination:{
                page:1,
                perPage:25
            },
            filter: Object.assign({}, util.getUserFilter()),
            sort:{
                // field:'',
                // order:''
            }
        })
        .then((data)=>{
            setKpis({
              isLoading:false,
              isError: false,
              data: data.data[0]
          });
        })
        .catch((erreur)=>{
            //console.log(erreur);
            setKpis({
              isLoading:false,
              isError: true,
              data:[{id:0, count:0}]
            });
        });
    }, []);

    const getKpiValue = () =>  {
      const kpi = kpis.data.kpiValue;
      if( kpi === undefined) return "N/A";

      return isNaN(parseFloat(kpi))? kpi:
        new Intl.NumberFormat(locale, props.isCurrency? {style: 'currency', currency: 'EUR', maximumFractionDigits: 0}: {}).format(kpi)
    };

    return (
      <div>
        {kpis.isLoading ?
          <Loader
               type="Oval"
               color="#0000FF"
               height={50}
               width={50}
            />
          :
          <Card className={classes.card}>
              <Link to={to}>
                  <div className={classes.main}>
                      <Box width="3em">
                          {createElement(icon, {className: classes.icon, style:{color: spanBgColor}})}
                      </Box>
                      <Box >
                          <Typography className={classes.label} noWrap={true} >
                              {title}
                          </Typography>
                          <Typography className={classes.value}>
                              {getKpiValue()}
                          </Typography>
                      </Box>
                  </div>
              </Link>
              {children && <Divider />}
              {children}
          </Card>
        }
      </div>
    );
};


// const InfoBox = (props) => {
//     const { spanBgColor, icon, title, value, to, children } = props;
//     const classes = infoBoxStyles({spanBgColor: props.spanBgColor});
//
//     return (
//         <Card className={classes.card}>
//             <Link to={to}>
//                 <div className={classes.main}>
//                     <Box width="3em">
//                         {createElement(icon, {className: classes.icon, style:{color: spanBgColor}})}
//                     </Box>
//                     <Box >
//                         <Typography className={classes.label} noWrap={true} >
//                             {title}
//                         </Typography>
//                         <Typography className={classes.value}>
//                             {value || '-'}
//                         </Typography>
//                     </Box>
//                 </div>
//             </Link>
//             {children && <Divider />}
//             {children}
//         </Card>
//     );
// };

export default InfoBox;


/*--------------------------------------------
old
import React from "react";
import Paper from "@material-ui/core/Paper";
import { grey } from '@material-ui/core/colors';

const grey800 = grey['800'];
const grey300 = grey['300'];

const InfoBox = (props) =>{
    const { spanBgColor, title, value, Icon } = props;
    const styles = {
      content: {
        padding: "5px 10px",
        minWidth: "300px",
        height: 80,
        backgroundColor:"#e6e6e6",
        borderRadius: 16,
        textAlign:"right"
      },
      number: {
        display: "block",
        fontWeight: 500,
        fontSize: 14,
        color: spanBgColor,
        textAlign: "right"
      },
      text: {
        fontSize: 12,
        fontWeight:500,
        color: grey800
      },
      iconSpan: {
        float: "left",
        height: 90,
        width: 90,
        textAlign: "center",
        backgroundColor: "#e6e6e6"
      },
      icon: {
        height: 85,
        width: 55,
        maxWidth: "100%",
        color: spanBgColor
      }
    };
    return (
      <Paper>
        <span style={styles.iconSpan}>
          <Icon  style={styles.icon}  />
        </span>
        <div style={styles.content}>
          <span style={styles.text}>{title}</span>
          <span style={styles.number}>{value}</span>
        </div>
      </Paper>
    );
}
export default InfoBox;
----------------------------------------------*/
