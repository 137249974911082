import React, { useState, useEffect } from 'react';
import { DOC_ROOT } from "../confProvider.js";

import {
  Button,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  TextField,
  Toolbar,
  useDataProvider,
  useListContext,
  useLocale,
  useTranslate,
} from 'react-admin';

import Loader from 'react-loader-spinner';

import {LinkField, LinkToFile} from '../customComponents/LinkField';
import {SubListPagination} from "../customComponents/SubListPagination";
import {NoResult} from "../customComponents/NoResult";

//import { EarlyWinesList } from "../orders/EarlyWines";

import IconClose from "@material-ui/icons/Close";
import BarChartIcon from '@material-ui/icons/BarChart';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';


import Dialog from '@material-ui/core/Dialog';

import Grid from '@material-ui/core/Grid';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';


import { makeStyles } from '@material-ui/core/styles';
import {orange} from "@material-ui/core/colors";

const util = require('../utils');

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  header: {
      background: orange[300],
  },
  cell: {
    background: orange[300],
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ProductStatButton = ({record, ...props}) => {
    const [showDialog, setShowDialog] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    }

    return (
        <>
            <Button onClick={handleClick}>
                <BarChartIcon />
            </Button>
            <Dialog  fullScreen open={showDialog} onClose={handleCloseClick} TransitionComponent={Transition}>
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton edge="start" onClick={handleCloseClick} aria-label={translate("ra.actions.close")}>
                    <IconClose />
                  </IconButton>
                  {props.basePath === "/HorsTarif"?
                    (true && <Typography variant="h6" className={classes.title} color="primary"> {translate("resources.products.tabs.statistics")} {record.id} </Typography>):
                    (true && <Typography variant="h6" className={classes.title} color="primary"> {translate("resources.products.tabs.statistics")} {record.libelle} {record.appel} {record.ta_libr} {record.millesime} </Typography>)
                  }
                </Toolbar>
              </AppBar>
              {props.basePath === "/HorsTarif"?
                (true && <AutoGrid st_code= {record[props.linkValue]} {...props} product={record.linkValue} millesime={record.millesime}/>):
                (true && <AutoGrid st_code= {record[props.linkValue]} {...props} product={record.libelle} millesime={record.millesime}/>)
              }
             </Dialog>
        </>
    );
}

export default ProductStatButton;

const T32_TYP_LIST = " IN ('01', '03', '04', '07', '08', '09', '10', '11', '12', '13', '30', '31', '38', '41', '42', 'AF', 'AX', 'EX','FS', 'FX', 'RF', 'RX', 'SX', 'X4', 'XA', 'XF', 'XR')"

const AutoGrid = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  const dataProvider= useDataProvider();
  const numberFormatter = item => new Intl.NumberFormat(locale, {}).format(item);
  const productFilter = (props.basePath === "/HorsTarif")?
    {"eq_ba_lib": "'" + props.st_code.replace(/'/g, "''") + "'"}:
    {"eq_SUBSTR(b1016.st_code,1,6)": "'" + props.st_code.substring(0,6) + "'"};
  const userFilter = Object.assign({}, util.getUserFilter(), productFilter, {"com.t32_typ": T32_TYP_LIST});

  const [state, setState] = useState({
      primeur: true,
    });

  const [data, setData] = useState({
    isLoading:true,
    isError: false,
    data:[]
  });
  const [dataFilter, setDataFilter] = useState({});      // filter on data retreive from server

  const getData = () => {
    dataProvider.getList(('product_sales'),{
      pagination:{
          page:1,
          perPage:25
      },
      sort:{
          field:null,
          order:null
      },
      filter: Object.assign({}, userFilter,  dataFilter)
    })
    .then((results)=>{
      setData({
        isLoading:false,
        isError: false,
        data: results.data
      });
    })
    .catch((erreur)=>{
      //console.log(erreur);
      setData({
        isLoading:false,
        isError: true,
        data:[{id:0, count:0}]
      });
    });
  };

  useEffect(()=> {
    // if(state.primeur) delete dataFilter["notin_com.t32_typ"];
    // else Object.assign(dataFilter, {["notin_com.t32_typ"]: "('EX', 'AX', 'FX', 'RX', '04', '13', 'SX', 'XA', 'XR', 'XF', 'X4')"});
    getData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const onPrimeurChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    let tmpFilter = dataFilter;
    if(event.target.checked) delete tmpFilter["notin_com.t32_typ"];
    else Object.assign(tmpFilter, {["notin_com.t32_typ"]: "('EX', 'AX', 'FX', 'RX', '04', '13', 'SX', 'XA', 'XR', 'XF', 'X4')"});// eslint-disable-line
    setDataFilter(tmpFilter);
    getData();
  };

  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12}  >
          <FormControlLabel
            control={
              <Switch
                checked={state.primeur}
                onChange={onPrimeurChange}
                name="primeur"
                color="primary"
              />
            }
            label={`${translate("pos.dashboard.filters.primeur")}`}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
         <ResponsiveContainer width="99%" height={250}>
           <LineChart data={data.data}>
             <XAxis dataKey="id" />
             <YAxis tickFormatter={numberFormatter} width={100} />
             <CartesianGrid strokeDasharray="3 3" />
             <Line type="monotone" dataKey="sales_n_3" name={translate("pos.dashboard.salesYear", {year: new Date().getFullYear()-3})} stroke="#48d1cc"  />
             <Line type="monotone" dataKey="sales_n_2" name={translate("pos.dashboard.salesYear", {year: new Date().getFullYear()-2})} stroke="#ff7f50"  />
             <Line type="monotone" dataKey="sales_prec_year" name={translate("pos.dashboard.salesYear", {year: new Date().getFullYear()-1})} stroke="#82ca9d"  />
             <Line type="monotone" dataKey="sales_year" name={translate("pos.dashboard.salesYear", {year: new Date().getFullYear()})} stroke="#8884d8" strokeWidth={2}/>
             <Text> {translate("pos.dashboard.salesAmount")}</Text>
             <Tooltip formatter={numberFormatter}/>
             <Legend verticalAlign="top" height={36}/>
           </LineChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.products.title.history")}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <HistoryList st_code={props.st_code} category={props.basePath}/>
        </Grid>

        <Grid item md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.last_ten_orders")}</Typography>
          <OrderList st_code={props.st_code} productFilter={productFilter}/>
        </Grid>
        <Grid item md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.sample_list")}</Typography>
          <SampleList {...props} st_code={props.st_code} productFilter={productFilter} category={props.basePath}/>
        </Grid>

        <Grid item md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.products.fields.last_customers")}</Typography>
          <LastCustomersList st_code={props.st_code} productFilter={productFilter} category={props.basePath}/>
        </Grid>
        <Grid item md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.earlyWines")}</Typography>
          <EarlyWinesList {...props} basePath="/earlyWines" resource="earlyWines"
                          productFilter={productFilter}
                          exporter={false} actions={false}
          />
        </Grid>

        <Grid item md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.reserve_list")}</Typography>
          <ProductReservesList st_code={props.st_code} productFilter={productFilter} category={props.basePath}/>
        </Grid>
        <Grid item md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.offers_list")}</Typography>
          <ProductOffersList st_code={props.st_code} productFilter={productFilter} category={props.basePath}/>
        </Grid>
      </Grid>
     </div>
  );
}

const displayMill = (record) => {
  if(record.mill.charCodeAt(0) <= 57 && record.mill.charCodeAt(0) >= 48) return '19' + record.mill;
  else if(record.mill.charCodeAt(0) <=74 && record.mill.charCodeAt(0) >= 65)
    return '20' + String.fromCharCode(48 + record.mill.charCodeAt(0) - 65) + record.mill.substr(1, 1);
  else return 'SM';
}

const HistoryListDetail = (props) => {
  const translate = useTranslate();
  const style = useStyles();
  const {isLoading} = useListContext();
  //"@ba_prod": "'" + props.st_code.substring(0,6) + "'",
  if(isLoading) return (<Loader/>)
  return(
      <List
          resource="productHistoryDetail"
          basePath="/productHistoryDetail"
          filter={Object.assign({}, util.getUserFilter(), props.productFilter,
                                {"@t32_typ": T32_TYP_LIST,
                                "eq_t72_secsta": "'" + props.id + "' AND (" +
                                  "\"ca0\" IS NOT NULL or \"volume0\" IS NOT NULL OR \"volume1\" IS NOT NULL OR \"ca1\" IS NOT NULL OR \"volume2\" IS NOT NULL OR \"ca2\" IS NOT NULL" +
                                  " OR \"volume3\" IS NOT NULL OR \"ca3\" IS NOT NULL OR \"volume4\" IS NOT NULL OR \"ca4\" IS NOT NULL)"
                              })
                  }
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          empty={<NoResult/>}
          sort={{field: '"cl_rsoc1"', order: "ASC"}}
          pagination={<SubListPagination/>}
          title=" "
      >
          <Datagrid >
            <TextField source="cl_rsoc1" label={`resources.customers.fields.name`} sortable={false}/>
            {props.category === "/HorsTarif" && <FunctionField source="mill" label="resources.products.fields.mill" render={record => displayMill(record)} sortable={false}/>}
            <NumberField source="ca4" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-4})} sortable={false} />
            <NumberField source="ca3" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-3})} sortable={false} />
            <NumberField source="ca2" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-2})} sortable={false} />
            <NumberField source="ca1" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-1})} sortable={false} />
            <NumberField source="ca0" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()})} sortable={false} />
            <NumberField source='volume4' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-4})}
              options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}}
              headerClassName={style.header} cellClassName={style.cell} sortable={false}/>
            <NumberField source='volume3' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-3})}
              options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}}
              headerClassName={style.header} cellClassName={style.cell} sortable={false}/>
            <NumberField source='volume2' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-2})}
              options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}}
              headerClassName={style.header} cellClassName={style.cell} sortable={false}/>
            <NumberField source='volume1' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-1})}
              options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}}
              headerClassName={style.header} cellClassName={style.cell} sortable={false}/>
            <NumberField source='volume0' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()})}
              options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}}
              headerClassName={style.header} cellClassName={style.cell} sortable={false}/>
          </Datagrid>
      </List>
  )
}

const HistoryList = (props) => {
  const translate = useTranslate();
  const style = useStyles();
  const {isLoading} = useListContext();
  const productFilter = (props.category === "/HorsTarif")?
    {"@ba_prod": "Article.ba_lib = '" + props.st_code.replace(/'/g, "''") + "'"}:
    {"@ba_prod": "SUBSTR(b1016.st_code, 1, 6) = '" + props.st_code.substring(0,6) + "'"};

  if(isLoading) return (<Loader/>)

  return(
      <List
          resource="productHistory"
          basePath="/productHistory"
          filter={Object.assign({}, util.getUserFilter(), productFilter, {"@t32_typ": T32_TYP_LIST})}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          empty={<NoResult/>}
          sort={{field: '"ta_lib"', order: "ASC"}}
          pagination={<SubListPagination/>}
          title=" "
      >
          <Datagrid expand={<HistoryListDetail {...props} productFilter={productFilter} />}>
            <TextField source="ta_lib" label={`resources.customers.fields.statistical_area`} />
            <NumberField source="n4.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-4})} />
            <NumberField source="n3.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-3})} />
            <NumberField source="n2.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-2})} />
            <NumberField source="n1.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()-1})} />
            <NumberField source="n0.ca" label={translate("resources.orders.fields.turnover_by_year", {year: new Date().getFullYear()})} />
            <NumberField source='n4.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-4})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n3.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-3})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n2.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-2})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n1.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()-1})}
              headerClassName={style.header} cellClassName={style.cell}/>
            <NumberField source='n0.volume' label={translate("resources.orders.fields.eq75_by_year", {year: new Date().getFullYear()})}
              headerClassName={style.header} cellClassName={style.cell}/>
          </Datagrid>
      </List>
  )
}

const OrderList = (props) =>{

  return(
      <List
          resource="productLastOrders"
          basePath="/productLastOrders"
          filter={Object.assign({}, util.getUserFilter(), props.productFilter, { "b1010.t32_typ": T32_TYP_LIST })}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          hasEdit={false}
          hasList={false}
          hasShow={false}
          empty={<NoResult/>}
          pagination={null}
          sort={{field: "ce_datsais", order: "DESC"}}
          title=" "
      >
          <Datagrid >
            <TextField source="client" label={"resources.customers.fields.name"} linkValue="cl_code" {...props}/>
            {props.category === "/HorsTarif" && <FunctionField source='mill' label={`resources.orders.fields.millesime`} render={record => displayMill(record)} />}
            <LinkField source="ce_num" label={"resources.orders.fields.order_num"} {...props}  linkValue="ce_num" target="_blank" basePath="/orders" />
            <LinkToFile label={'resources.invoices.fields.number'} url={`${DOC_ROOT}FACTURES/`} source="ce_numfac" type=".pdf" target="_blank"/>
            <TextField source="st_desi" label={`resources.products.fields.condit`} />
            {/*<TextField source="bouteille" label={"resources.products.fields.libBout"} />*/}
            <TextField source="bouchon" label={"resources.products.fields.libBouch"} />
            {/*<TextField source="regie" label={"resources.products.fields.libRegie"} />*/}
            <TextField source="habillage" label={"resources.products.fields.libHab"} />
            <NumberField source='ca_ht' label={`resources.orders.fields.turnover`} />
            <NumberField source='volume' label={`resources.orders.fields.eq75`} />
          </Datagrid>
      </List>
  )
}

const SampleList = (props) =>{
  return(
      <List
        {...props}
          resource="productSamples"
          basePath="/productSamples"
          filter={Object.assign({}, util.getUserFilter(), props.productFilter,  {eq_ce_acti: "'0'"})}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          pagination={<SubListPagination/>}
          sort={{field: "com.ce_datenl", order: "DESC"}}
          title=" "
          empty={<NoResult/>}
      >
          <Datagrid optimized>
            <LinkField source='nom_client' label={`resources.customers.fields.name`} {...props}  linkValue="cl_code" target="_blank" basePath="/customers" />
            {props.category === "/HorsTarif" && <FunctionField source='mill' label={`resources.orders.fields.millesime`} render={record => displayMill(record)} />}
            <LinkField source="ce_num" label={"resources.orders.fields.order_num"} {...props} linkValue="ce_num" target="_blank" basePath="/orders" />
            <DateField source="com.ce_datenl" label={"resources.orders.fields.dates.exped_date"} />
            <NumberField source='volume' label={`resources.orders.fields.eq75`} options={{style: "decimal", maximumFractionDigits: 2}}/>
          </Datagrid>
      </List>
  )
}

const LastCustomersList = (props) =>{

  return(
      <List
          resource="productLastCustomers"
          basePath="/productLastCustomers"
          filter={Object.assign({}, util.getUserFilter(), props.productFilter)}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          hasEdit={false}
          hasList={false}
          hasShow={false}
          pagination={<SubListPagination/>}
          sort={{field: "ce_datsais", order: "DESC"}}
          title=" "
          empty={<NoResult/>}
      >
          <Datagrid >
            <LinkField source='cl_rsoc1' label={`resources.customers.fields.name`} linkValue="cl_code" target="_blank" {...props} basePath="/customers" />
            {props.category === "/HorsTarif" && <FunctionField source='mill' label={`resources.orders.fields.millesime`} render={record => displayMill(record)} />}
            <LinkField source="ce_num" label={"resources.orders.fields.order_num"} linkValue="ce_num" target="_blank" {...props} basePath="/orders" />
            <TextField source="t42_ancde" label={"resources.orders.fields.anomaly"} />
            <DateField source="ce_datsais" label={"resources.orders.fields.dates.input"} />
            <NumberField source='eq75' label={`resources.orders.fields.eq75`} />
            <NumberField source="ca" label="resources.orders.fields.turnover" options={{minimumFractionDigits: 2}}/>
          </Datagrid>
      </List>
  )
}

const ProductReservesList = (props) =>{
  return(
      <List
          resource={props.category === "/HorsTarif"? "productReserves":"productReserves"}
          basePath={props.category === "/HorsTarif"? "/productReserves":"/productReserves"}
          filter={Object.assign({}, util.getUserFilter(), props.productFilter)}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          hasCreate={false}
          hasEdit={false}
          hasList={false}
          hasShow={false}
          pagination={<SubListPagination/>}
          sort={{field: "CL_RSOC1", order:"ASC"}}
          title=" "
          empty={<NoResult/>}
      >
          <Datagrid >
            <LinkField source="CL_RSOC1" label={"resources.orders.fields.customer"} linkValue="CL_CODE_FAC" target="_blank" {...props} basePath="/customers" />
            {props.category === "/HorsTarif" && <TextField source='MILL' label={`resources.orders.fields.millesime`} />}
            <LinkField source='CE_NUM' label={`resources.orders.fields.reserve_number`} linkValue="CE_NUM" target="_blank" {...props} basePath="/orders"  />
            <DateField source="CE_DATSAIS" label={"resources.orders.fields.dates.input"} />
            <DateField source="CE_DATEXP" label={"resources.orders.fields.dates.expiration"} />
            {/*<TextField source="COULEUR" label={"resources.products.fields.libCoul"} /> (ticket 369)*/}
            <TextField source="ST_DESI" label={"resources.products.fields.condit"} />
            <NumberField source='SOLDE' label={`resources.orders.fields.solde`} sortBy="SOLDE" />
            <NumberField source="PRMP" label={`resources.orders.fields.prmp`} options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 2}} />
            <NumberField source="TOTAL_PR" label={`resources.orders.fields.total_pr`} options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 0}} />
          </Datagrid>
      </List>
  )
}

const ProductOffersList = (props) =>{
  return(
      <List
        {...props}
          resource="productOffers"
          basePath="/productOffers"
          filter={{"@st_code": "'" + props.st_code.substr(0, 6) + "'" }}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          pagination={<SubListPagination/>}
          sort={{field: "client_name", order: "ASC"}}
          title=" "
          empty={<NoResult/>}
      >
          <Datagrid >
            <TextField source='client_name' label={`resources.customers.fields.name`} />
            {props.category === "/HorsTarif" && <FunctionField source='millesime' label={`resources.orders.fields.millesime`} />}
            <TextField source="code_offre" label="resources.offres.field.code" />
            <DateField source="date_fin_offre" label={"resources.offres.field.dateEnd"} />
            <NumberField source='qte' label={`resources.offres.field.qte`} />
            <NumberField source="prix" label="resources.offres.field.prix" />
          </Datagrid>
      </List>
  )
}

const EarlyWinesList = props =>{
  return(
    <List
      {...props}
      sort={{field: "CL_RSOC1", order: "ASC"}, {field: "millesime", order: "DESC"}}
      bulkActionButtons={false}
      title=" "
      empty={<NoResult/>}
      pagination={<SubListPagination/>}
      filter={props.productFilter}
    >
      <Datagrid >
        <TextField source="CL_RSOC1" label="resources.customers.fields.name" sortBy='"CL_RSOC1"'/>
        <TextField source="MILLESIME" label={`resources.orders.fields.millesime`} />
        <NumberField source="qte"  label="resources.orders.fields.qty" sortBy='"qte"'/>
        <NumberField source="qte_liv"  label="resources.orders.fields.quantity_exp" sortBy='"qte_liv"'/>
        <NumberField source="solde"  label="resources.orders.fields.solde" sortBy='"solde"'/>
      </Datagrid>
    </List>
  )
}
