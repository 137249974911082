import React, {useState} from "react";

import { DOC_ROOT } from "../confProvider.js";

import {
  Edit,
  TextInput,
  SaveButton,
  SimpleForm,
  FileField,
  FileInput,
  Toolbar,
  useTranslate
} from 'react-admin';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Slide,
  makeStyles,
  Dialog,
  IconButton,
  AppBar,
  Button,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import {Modal} from '../modals/modals';
import CreateOffer from './offer_CreateButton';
import IconContentAdd from '@material-ui/icons/Add';
import IconClose from "@material-ui/icons/Close";
import {LinkField, LinkToImg} from '../customComponents/LinkField';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  page: {
  flexDirection: 'row',
  backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const DetailToolBar = props => {
  return(
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
);}

export const Details = (props) => {
  const translate = useTranslate();
  return props.resource == "articlesHorsTarif" ?(
    <Edit
      resource={props.resource}
      basePath={props.basePath}
      {...props}
      title={" "}
    >
      <SimpleForm {...props} toolbar={<DetailToolBar {...props} />}>
        <Card fullWidth>
          <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
              <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                <Typography variant="h6" gutterBottom>
                  {translate('resources.products.fields.infos')}
                </Typography>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <TextInput InputProps={{readOnly:true}} source="libelle" fullWidth />
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                    <TextInput InputProps={{readOnly:true}} source="millesime" fullWidth />
                  </Box>
                </Box>
                <TextInput InputProps={{readOnly:true}} source="st_desi" fullWidth />
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <TextInput source="dispo" fullWidth />
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <TextInput InputProps={{readOnly:true}} source="reserve" fullWidth />
                  </Box>
                  <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} />
                </Box>
                {/* gencode and custom id line*/}
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <TextInput source="genpf" label="resources.products.fields.gencode_article" fullWidth InputProps={{readOnly:true}}/>
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <TextInput source="genuvc" label="resources.products.fields.gencode_bottle" fullWidth InputProps={{readOnly:true}}/>
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <TextInput source="t15_nc1" label="resources.products.fields.customId" fullWidth InputProps={{readOnly:true}}/>
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} />
                </Box>

                {/* Marketing box */}
                <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    {translate('resources.products.fields.mark')}
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <TextInput source="dossier" label="resources.products.fields.folder" fullWidth />
                  </Box>
              </Box>

              <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }} >
                <Typography variant="h6" gutterBottom>
                  {translate('resources.products.fields.Tarifi')}
                </Typography>

                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput InputProps={{readOnly:true}} source="st_pinv" label="Prix inventaire" fullWidth/>
                  </Box>
                  <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput InputProps={{readOnly:true}} source="st_prmp" label="Prix de revient moyen" fullWidth/>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </SimpleForm>
    </Edit>
  ):(
    <Edit
      resource={props.resource}
      basePath={props.basePath}
      {...props}
      title={" "}
    >
      <SimpleForm {...props} toolbar={<DetailToolBar {...props} />}>
        <Card fullWidth>
          <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
              <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                <Typography variant="h6" gutterBottom>
                  {translate( 'resources.products.fields.infos' )}
                </Typography>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput InputProps={{readOnly:true}} source="libelle" fullWidth/>
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                    <TextInput InputProps={{readOnly:true}} source="millesime" fullWidth/>
                  </Box>
                </Box>
                <TextInput InputProps={{readOnly:true}} source="st_desi" fullWidth/>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput InputProps={{readOnly:true}} source="dispo" fullWidth/>
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput InputProps={{readOnly:true}} source="reserve" fullWidth/>
                  </Box>
                  <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}/>
                </Box>
                {/* gencode and custom id line*/}
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <TextInput source="genpf" label="resources.products.fields.gencode_article" fullWidth InputProps={{readOnly:true}}/>
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <TextInput source="genuvc" label="resources.products.fields.gencode_bottle" fullWidth InputProps={{readOnly:true}}/>
                  </Box>
                  <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                    <TextInput source="t15_nc1" label="resources.products.fields.customId" fullWidth InputProps={{readOnly:true}}/>
                  </Box>
                  <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} />
                </Box>

                {/* Marketing box */}
                <Box mt="1em" />
                  <Typography variant="h6" gutterBottom>
                    {translate('resources.products.fields.mark')}
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <TextInput source="dossier" label="resources.products.fields.folder" fullWidth />
                  </Box>
                </Box>

                <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }}>
                <Typography variant="h6" gutterBottom>
                  {translate('resources.products.fields.Tarifi')}
                </Typography>
                <Box display={{ xs: 'block', sm: 'flex' }}>
                  <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput InputProps={{readOnly:true}} source="prix_vente" fullWidth/>
                  </Box>
                  <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                    <TextInput InputProps={{readOnly:true}} source="prix_plancher" fullWidth/>
                  </Box>
                </Box>
                <Box flex={2}>
                  <TextInput InputProps={{readOnly:true}} source="prmp_col" fullWidth/>
                </Box>

                <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput InputProps={{readOnly:true}} source="marge" fullWidth/>
                </Box>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextInput InputProps={{readOnly:true}} source="margepp" fullWidth/>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </SimpleForm>
    </Edit>
  )
}

// <Toolbar fullWidth>
//   <ProdOffer {...props}/>
// </Toolbar>
