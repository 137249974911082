import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    useCreate,
    useNotify,
    FormWithRedirect,
    NumberInput,
    useTranslate,
    useRefresh,
    ReferenceInput,
    FormDataConsumer,
    AutocompleteInput,
    TextInput,
    Datagrid,
    TextField,
    NumberField,
    List,
    Filter,
    Button,
    useGetList,
    useUnselectAll,
    useGetMany
} from 'react-admin';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  DialogActions,
  Grid,
  Table
} from '@material-ui/core';
import AddProducts from './offresAddProducts';
import {Modal} from '../../modals/modals';
import IconContentAdd from '@material-ui/icons/Add';
const useStyles = makeStyles({
    rightAlignedCell: { textAlign: 'right' },
});
const ProdFilter = (props) =>(
    <Filter {...props} >
      <TextInput label="resources.products.fields.prod" source="likeic_libelle" alwaysOn />
      <TextInput label="resources.products.fields.appell" source="likeic_appel.ta_lib" alwaysOn />
      <TextInput label="resources.products.fields.mill" source="likeic_millesime" alwaysOn />
    </Filter>
)

const Conditionnement = (props) => (
    <List
        resource={"productsCondallproducts"}
        basePath={"/productsCondallproducts"}
        filter= {props.categorie === "HorsTarif" ?
                  {"eq_SUBSTRING(Art.st_code, 1, 6)": "'" + props.id + "'"}:
                  {"eq_SUBSTRING(tarifs.st_code, 1, 6)": "'" + props.id + "'"}
                }
        filters={null}
        perPage={10}
        exporter={false}
        actions={false}
        title=" "
        bulkActionButtons={false}
    >
      <Datagrid >
        <TextField label="resources.products.fields.libCoul" source='ta_libr'/>
        <TextField label="resources.products.fields.condit" source='condit'/>
        <TextField label="resources.products.fields.libBout" source="bouteille" />
        <TextField label="resources.products.fields.libBouch" source="bouchon" />
        <TextField label="resources.products.fields.libHab" source="habillage" />
        <NumberField label="resources.products.fields.PrixV" source='prix_vente'/>
        <NumberField label="resources.products.fields.PrixP" source='prix_plancher'/>
        <NumberField label="resources.products.fields.P" source='st_poid'/>
        <NumberField label="resources.products.fields.deg" source='degre'/>
        <NumberField label="resources.products.fields.stock" source='dispo'/>
        <NumberField label="resources.products.fields.res" source='reserve'/>
      </Datagrid>
    </List>
);
const BulkAddOffreButton = (props) => {
  const translate = useTranslate();
  const { data,loading, error } = useGetMany (
        'addProductsLookup',
         props.selectedIds
    );
  const [create] = useCreate('lignes_offres');
  const unselectAll = useUnselectAll();
  const refresh = useRefresh();
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        data[i]['id_offre'] = props.id;
        data[i]['id'] = null;
        create(
            { payload: { data: data[i] } },
            {
                onSuccess: ({ data }) => {
                    notify(translate("resources.offres.prod.Succesprod"), 'success')
                    form.change('id', data.id);
                    unselectAll('allproducts');
                    refresh();
                },
                onFailure: ( error ) => {
                    notify(translate("resources.offres.prod.Failprod"), 'warning')
                    unselectAll('allproducts');
                },
            }
          );
      }
       unselectAll('allproducts');
    }
 }
  return (
    <Button
        label="resources.offres.button.AddProd"
        onClick={handleClick}
        disabled={loading}
    >
    </Button>
  );
};

const AddOffreButton = (props) => {
  const translate = useTranslate();
  return (

    <Modal  title={translate("resources.offres.title.AddProd")}
            buttonIcon={<IconContentAdd />}
            buttonText={translate("resources.offres.button.AddNewProd")}
            {...props} width="l"
    >
    <AddProducts {...props} />
    </Modal>
  );
};
function ListCreateOffreFWD(props) {
  const translate = useTranslate();
  return (
    <List
        resource="allproducts"
        basePath="/allproducts"
        filters={<ProdFilter {...props}/> }
        perPage={10}
        exporter={false}
        currentSort={{field:"libelle_produit",order:"ASC"}}
        title=" "
        bulkActionButtons={<BulkAddOffreButton {...props}/>}
        actions={<AddOffreButton {...props}/>}
    >
      <Datagrid {...props} >
        <TextField label="resources.products.fields.prod" source='libelle'/>
        <TextField label="resources.products.fields.appell" source='appel' />
        <NumberField label="resources.products.fields.mill" source='millesime'/>
        <TextField label="resources.products.fields.libCoul" source='ta_libr'/>
        <TextField label="resources.products.fields.condit" source='condit'/>
        <TextField label="resources.products.fields.libBout" source="bouteille" />
        <TextField label="resources.products.fields.libBouch" source="bouchon" />
        <TextField label="resources.products.fields.libHab" source="habillage" />
        <NumberField label="resources.products.fields.PrixV" source='prix_vente'/>
        <NumberField label="resources.products.fields.PrixP" source='prix_plancher'/>
        <NumberField label="resources.products.fields.P" source='st_poid'/>
        <NumberField label="resources.products.fields.deg" source='degre'/>
        <NumberField label="resources.products.fields.stock" source='dispo'/>
        <NumberField label="resources.products.fields.res" source='reserve'/>
      </Datagrid>
    </List>
  );
}
export default ListCreateOffreFWD;
