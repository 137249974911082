import * as React from 'react';
import { NumberField, TextField } from 'react-admin';
import { LinkField } from "./LinkField.js"
import classnames from 'classnames';
import {colorNumberStyles} from '../style.js';

export const ColoredSignNumberField = props => {
  const classes = colorNumberStyles();
  return ( props.record === undefined ? null:
      <NumberField
          className={classnames({
              [classes.negative]: props.record[props.source] < 0,
              [classes.positive]: props.record[props.source] >= 0,
          })}
          {...props}
      />
  );
};

export const ColoredSignTextField = props => {
    const classes = colorNumberStyles();
    return (
        <TextField
            classes={classnames({
                [classes.negative]: props.record[props.test_value] < 0,
                [classes.positive]: props.record[props.test_value] >= 0,
            })}
            {...props}
        />
    );
};

export const ColoredSignLinkField = props => {
    const classes = colorNumberStyles();
    return (
        <LinkField
            className={classnames({
                [classes.negative]: props.record[props.test_value] < 0,
                [classes.positive]: props.record[props.test_value] >= 0,
            })}
            {...props}
        />
    );
};
