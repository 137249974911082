import * as React from "react";
import { DOC_ROOT } from "../confProvider.js";

import {
    Box,
    Grid,
    Paper,
    Typography
} from '@material-ui/core';
import {useState, useEffect} from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Text,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import {
  FormDataConsumer,
 } from 'react-admin';

import {
    Datagrid,
    DateField,
    DateInput,
    List,
    NumberField,
    NumberInput,
    TextField,
    TextInput,
    useDataProvider,
    useTranslate,
    useLocale,
} from 'react-admin';
import {saleStyle} from '../style.js';

import {ColoredSignNumberField} from '../customComponents/ColoredField';
import {LinkToFile} from '../customComponents/LinkField';

export const SalesShow = props =>{
    const translate=useTranslate();
    return (
      <Box flex={12} mr={{ md: 0, lg: '1em' }} mt={{xs:0, sm: 0}}>
        {/* -------------------------  SALES INFORMATIONS  ------------------------ */}
        <Box display={{ xs: 'block', sm: 'flex' }}>
          <Box flex={2} mr={{ xs: 0, sm: '2em' }}>
            <DateInput inputProps={{style:{color:"black"}}} source="cl_dercde"
              label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.last_order')}</Typography>}
              fullWidth disabled
            />
            <NumberInput inputProps={{style:{color:"black"}}} source="cl_impaye"
              label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.unpayed_number')}</Typography>}
              fullWidth disabled
            />
            <TextInput inputProps={{style:{color:"black"}}} source="cl_assure" /*label='resources.customers.fields.insurance_ceiling'*/
              label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.insurance_ceiling')}</Typography>}
              format={v => new Intl.NumberFormat({useGrouping: true}).format(v)} fullWidth disabled
            />
            <TextInput inputProps={{style:{color:"black"}}} source="cl_compta"
              label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.comptable_encour')}</Typography>}
              format={v => new Intl.NumberFormat({useGrouping: true}).format(v)} fullWidth disabled
            />
            <TextInput elStyle={{color: "red"}}  inputProps={{style:{color:"black"}}} source="cl_encour"
              label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.commercial_encour')}</Typography>}
              format={v => new Intl.NumberFormat({useGrouping: true}).format(v)} fullWidth disabled
            />
            <FormDataConsumer>
            {({formData, ...rest}) => {
              return(
                <TextInput source="cl_solcofa"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.insurance_balance')}</Typography>}

                  format={v => new Intl.NumberFormat({useGrouping: true}).format(v)} record={formData} fullWidth
                  inputProps={{readonly:true,
                                style:{color: formData.cl_solcofa < 0? "red":
                                              formData.cl_solcofa < formData.cl_assure * 0.15? "orange": "green"}}} disabled
                />
              )}}
            </FormDataConsumer>
          </Box>
          <Box flex={10} mr={{ xs: 0, sm: '0.5em' }} mt={{xs:0, sm: 0}} >
            {/* -------------------------  INVOICES  ------------------------ */}
            <Typography variant="subtitle2" color='primary' gutterBottom>{translate('resources.customers.formBox.invoices')}</Typography>
            <List
              filter={{"eq_cli.cl_code": "'" + props.idCli + "'"}}
              basePath="/invoices"
              resource="invoices"
              actions={false}
              bulkActionButtons={false}
              sort={{field:"clb_dteche", sort: "ASC"}}
              title=" "
              empty={false}
            >
              <Datagrid >
                <DateField source="clb_dteche" label={'resources.invoices.fields.term_date'} />
                <ColoredSignNumberField source="clb_mt" label={'resources.invoices.fields.amount'} options={{minimumFractionDigits: 2}} textAlign="right" />
                <TextField  source="clb_libelle" label={'resources.invoices.fields.label'} />
                <LinkToFile label={'resources.invoices.fields.number'} url={`${DOC_ROOT}FACTURES/`} source="clb_piece" type=".pdf" target="_blank"/>
              </Datagrid>
            </List>
                     {/* -------------------------  PRIMEURS EB RETARD  ------------------------ */} 
            <Typography variant="subtitle2" color='primary' gutterBottom>{translate('resources.customers.formBox.retard_primeur')}</Typography>
            <List
              filter={{"eq_CL_CODE_FAC": "'" + props.idCli + "'"}}
              basePath="/retard_primeur"
              resource="retard_primeur"
              actions={false}
              bulkActionButtons={false}
              sort={{field:"clb_dteche", sort: "ASC"}}
              title=" "
              empty={false}
            >
              <Datagrid >
                <DateField source="Date_Echeance" label={'resources.invoices.fields.term_date'} />
                <ColoredSignNumberField source="CA_HT" label={'resources.invoices.fields.amount'} options={{minimumFractionDigits: 2}} textAlign="right" />
                <ColoredSignNumberField source="CA_TTC" label={'resources.invoices.fields.amount_TTC'} options={{minimumFractionDigits: 2}} textAlign="right" />
                <TextField  source="Chateau" label={'resources.invoices.fields.chateau'} />
                <TextField  source="MILLESIME" label={'resources.invoices.fields.mill'} />
                <TextField  source="Conditionnement" label={'resources.invoices.fields.conditionnement'} />
                <LinkToFile label={'resources.invoices.fields.number'} url={`${DOC_ROOT}FACTURES/`} source="Num_Facture" type=".pdf" target="_blank"/>
              </Datagrid>
            </List>

          </Box>
        </Box>
      </Box>
    );
  }





// ancien code ---------------------------------------------------------------------------------
export const SalesView = props =>{
    return (
        <SalesViewGrid idClient={props.idCli} />
    )
}

export const SalesViewGrid = props =>{
  const classes = saleStyle();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const locale = useLocale();
  const [data, setData] = useState({
    isLoading:true,
    isError: false,
    data:[]
  });
  const numberFormatter = item => new Intl.NumberFormat(locale, {}).format(item);
  let userFilter = {["eq_cl_code"]: "'" + props.idCli + "'"};

  const getData = () => {
    dataProvider.getList(('sales_and_margin'),{
      pagination:{
          page:1,
          perPage:25
      },
      sort:{
          field:null,
          order:null
      },
      filter: Object.assign({}, userFilter)
    })
    .then((results)=>{
      //console.log("results", results)
      setData({
        isLoading:false,
        isError: false,
        data: results.data
      });
      //console.log("data", data.data)
    })
    .catch((erreur)=>{
      //console.log(erreur);
      setData({
        isLoading:false,
        isError: true,
        data:[{id:0, count:0}]
      });
    });
  };

  useEffect(()=> {
    getData();
  },[]);

  return(
    <div className={classes.root}>
       <Grid container spacing={3}>
         <Grid item xs={12} sm={6}>
           <Paper className={classes.paper}>
             <LineChart width={750} height={250} data={data.data}>
               <XAxis dataKey="id" />
               <YAxis tickFormatter={numberFormatter} width={100} />
               <CartesianGrid strokeDasharray="3 3" />
               <Line type="monotone" dataKey="sales_year" name={translate("pos.dashboard.salesYear")} stroke="#8884d8" strokeWidth={2}/>
               <Line type="monotone" dataKey="sales_prec_year" name={translate("pos.dashboard.salesPrecYear")} stroke="#82ca9d"  />
               <Line type="monotone" dataKey="sales_n_2" name={translate("pos.dashboard.salesYearN_2")} stroke="#ff7f50"  />
               <Line type="monotone" dataKey="sales_n_3" name={translate("pos.dashboard.salesYearN_3")} stroke="#48d1cc"  />
               <Text> {translate("pos.dashboard.salesAmount")}</Text>
               <Tooltip formatter={numberFormatter}/>
               <Legend verticalAlign="top" height={36}/>
             </LineChart>
           </Paper>
         </Grid>
         <Grid item xs={12} sm={6}>
           <Paper className={classes.paper}>xs=12 sm=6 </Paper>
         </Grid>
       </Grid>
     </div>
  );
};
