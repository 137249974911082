import { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import IconClose from "@material-ui/icons/Close";
import ReplyIcon from '@material-ui/icons/Reply';
import SendIcon from '@material-ui/icons/Send';
import { grey, green } from '@material-ui/core/colors';
import {Title} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import {getWebsocket, read as readWebsocket, getClientName} from "../websocket/websocket";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root_parent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'auto',
    alignContent: 'start',
    width:'100%',
    backgroundColor:grey[200],
  },

  flex_row:{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  grey: {
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
  },
  dark_red: {
    color: '#EA6252',
    backgroundColor: grey[300],
  },
  avatar: {
    marginRight: '10px',
    width:"30px",
    height:"30px",
    fontSize: '13px'
  },

  fluxContent_container: {
    gridArea: '2 / 2 / 3 / 3',
    width:'100%',
    height:'60vh',
    overflowY:'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius:'10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },

  fluxContent_post: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    margin:'10px 25px',
    backgroundColor:grey[100],
    borderRadius:'10px',
  },
  fluxContent_post_author:{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin:'5px 25px',
    alignItems: 'center',
  },
  fluxContent_post_body:{
    margin:'5px 25px 15px 25px',
    fontSize:'13px',
  },
  fluxContent_post_date:{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin:'15px 25px',
    color:grey[500],
    fontSize:'11px',
  },
  fluxTextArea_container:{
    flex: '0 0 auto',
    margin: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'auto',
    alignContent: 'start',
    height:'10vh',
  },
  fluxTextArea_area:{
    flex: '1 0 auto',
    margin: '10px',
    width: '35vw'
  },
  fluxTextArea_btn:{
    flex: '1 0 auto',
    margin: '10px',
    width: '5vw'
  }
}));

export const NotificationShow = (props) => {

  const classes = useStyles();
  const [websocket, setWebsocket] = useState(getWebsocket());
  const [posts, setPosts] = useState([]);
  const [selectedSalon, setSelectedSalon] = useState("GENERAL");
  const [selectedTarget, setSelectedTarget] = useState("*");

  useEffect(()=>{
    let updateWs = setInterval(function () {
      let tmpWebsocket = getWebsocket();
      readWebsocket();
      //console.log("websocket modified, updating");
      let tmpMsgs = [];
      for(let salon in tmpWebsocket.notifications.notifications){
        if(salon !== "GENERAL"){
          for (let sender in tmpWebsocket.notifications.notifications[salon]) {
            let messages = tmpWebsocket.notifications.notifications[salon][sender];
            for (let notifNo in messages) {
              let notif = messages[notifNo];
              tmpMsgs.push(
                {
                  sender:sender,
                  msg:
                    <span>
                      Envoyé depuis le client {getClientName(salon)}:
                      <br/>
                      <i>
                        {notif.contenu.split('\n').map((item)=>{return(<>{item}<br /></>);})}
                      </i>
                    </span>,
                  date:notif.datenotif,
                  salon:salon
                }
              );
            }
          }
        }else{
          for (let sender in tmpWebsocket.notifications.notifications[salon]) {
            let messages = tmpWebsocket.notifications.notifications[salon][sender];
            for (let notifNo in messages) {
              let notif = messages[notifNo];
              tmpMsgs.push(
                {
                  sender:sender,
                  msg:notif.contenu.split('\n').map((item)=>{return(<>{item}<br /></>);}),
                  date:notif.datenotif,
                  salon:salon
                }
              );
            }
          }
        }
      }


      tmpMsgs.sort((a,b)=>{return (new Date(b.date)).getTime()-(new Date(a.date)).getTime()});

      let tmpPosts = [];
      for (let i = 0; i < tmpMsgs.length; i++) {
        let notif = tmpMsgs[i];
        if(notif.salon!=="GENERAL"){
          tmpPosts.push(<Post
            pseudo={notif.sender}
            msg={<span>{notif.msg}</span>}
            date={notif.date.replace(/T/g,' ').replace(/\..+/,'')}
            answerSalon={notif.salon}
            changeSalon={(salon)=>{setSelectedSalon(salon);setSelectedTarget(notif.sender);}}
          />);
        }else{
          tmpPosts.push(<Post
            pseudo={notif.sender}
            msg={<span>{notif.msg}</span>}
            date={notif.date.replace(/T/g,' ').replace(/\..+/,'')}
            answerSalon={"GENERAL"}
            changeSalon={(salon)=>{setSelectedSalon("GENERAL");setSelectedTarget(notif.sender);}}
          />);
        }
      }
      setPosts(tmpPosts);

      setWebsocket(tmpWebsocket);
    }, 1000);
    return function cleanup() {
      clearInterval(updateWs);
    };
  },[]);

  const sendMsgToWebsocket = (message) => {
    getWebsocket().methods.newMessage(message, selectedSalon);
  }

  return(
    <>
    <Title title={props.title} />
    {getWebsocket().isInitiated && <div className={classes.root_parent}>
      <div className={classes.fluxContent_container}>
        {posts}
      </div>
      {selectedSalon!=="GENERAL" && <div>
        <ReplyNoteArea
          clientName={getClientName(selectedSalon)}
          replyTo={selectedTarget}
          reinitSalon={()=>{setSelectedSalon("GENERAL");setSelectedTarget("*");}}
        />
      </div>}
      <div className={classes.fluxTextArea_container}>
        <WritingArea
          onSubmit={sendMsgToWebsocket}
        />
      </div>
    </div>}

    </>
  );
}

export const ReplyNoteArea = (props) => {
  return (
    <span style={{margin:"0 25px -15px 25px", display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'auto', alignContent: 'start'}}>
      <span style={{margin:"15px 0 -15px 0"}}>Réponse à {props.replyTo} pour le client {props.clientName}</span>
      <IconButton
        aria-label="close"
        onClick={()=>{props.reinitSalon();}}
      >
        <IconClose />
      </IconButton>
    </span>
  );
}

export const Post = (props) => {
  const classes = useStyles();
  const isMine = (props.pseudo === localStorage.getItem('username'));

  return(
    <span className={classes.fluxContent_post} style={{width:'80%',float:(isMine?'right':'left'), backgroundColor:(isMine?green[100]:grey[100])}}>
      <span style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'auto', alignContent: 'start'}}>
        {isMine ?
          <>
            <span className={classes.fluxContent_post_date}>{props.date}</span>
            <span className={`${classes.fluxContent_post_author} ${classes.flex_row}`}>
              <Avatar className={`${classes.grey} ${classes.avatar}`} alt={props.pseudo} src="unexistantPhoto.png"></Avatar>
              <span style={{fontSize:'13px'}}>{props.pseudo}</span>
            </span>
          </>
          :
          <>
            <span className={`${classes.fluxContent_post_author} ${classes.flex_row}`}>
              <Avatar className={`${classes.grey} ${classes.avatar}`} alt={props.pseudo} src="unexistantPhoto.png"></Avatar>
              <span style={{fontSize:'13px'}}>{props.pseudo}</span>
            </span>
            <span className={classes.fluxContent_post_date}>{props.date}</span>
          </>
        }

      </span>
      <span style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'auto', alignContent: 'start'}}>
        <span className={classes.fluxContent_post_body}>
          {props.msg}
        </span>
        {props.answerSalon &&
          <span className={classes.fluxContent_post_body}>
            <IconButton aria-label="close" onClick={()=>{props.changeSalon(props.answerSalon)}}>
              <ReplyIcon />
            </IconButton>
          </span>
        }
      </span>
    </span>
  );
};

export const WritingArea = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const changeContenu = (event)=>{
    if (event.key==="Enter" && event.ctrlKey) {
      submitMessage();
    }
  }
  const submitMessage = () => {
    let msg = document.getElementById("messageTextArea").value;
    if(msg.length<=1024){
      props.onSubmit(msg);
      document.getElementById("messageTextArea").value="";
    }else{
      alert("Pour des raisons techniques, les messages sont limités à 1024 caractères.\nVotre message fait "+msg.length+" caractères.\nMerci de le raccourcir")
    }
  }
  return(
    <>
      <textarea className={classes.fluxTextArea_area} id="messageTextArea" onKeyDown={changeContenu}></textarea>
      <button
        className={`${classes.fluxTextArea_btn} btn btn-secondary`}
        type="button"
        onClick={submitMessage}
        style={{fontSize:'13px'}}
      >
        {!smallWidth ? "ENVOYER" : <SendIcon />}
      </button>
    </>
  );
}
