import * as React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  NumberField,
  TextField,
} from 'react-admin';

import {LinkField} from '../customComponents/LinkField';

import { DatagridTotalLine } from '../customComponents/CustomTotalLine';

const util = require('../utils');

const MobileOrderList = (props) => {
  let userFilter = props.userFilter;
  userFilter["gte_ce_datsais"] = "TO_DATE('01/01'|| TO_CHAR(EXTRACT(YEAR FROM CURRENT_DATE) - 3, '9999'), 'DD/MM/YYYY')";
  // userFilter["eq_b0100.cl_acti"] = "'0'";
  // userFilter["isnull_b1010.ce_datdepart"] = true; // have to put something

  return (
    <DatagridTotalLine
      filter={userFilter}
      fieldsToTotalize={props.totalFields}
      {...props}
    >
      <LinkField source="ce_num" label="resources.orders.fields.order_num" linkValue="id" {...props}/>
      <LinkField source="cli.cl_rsoc1" label={`resources.orders.fields.customer`} {...props} basePath="/customers" linkValue="cl_code_fac" />
      <NumberField source='ce_cacdeht' label={`resources.orders.fields.turnover`}
        options={{style: "currency", currency:"EUR", maximumFractionDigits: 2}} />
      <TextField source="ce_refcli" label={`resources.orders.fields.customer_order_num`} />
      <DateField source="ce_datsais" label={`resources.orders.fields.dates.input`} />
      <DateField source="ce_datenl" label={`resources.orders.fields.dates.takeoff`} />
    </DatagridTotalLine>
  );
};
export default MobileOrderList;
