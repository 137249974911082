import React, { useState, useEffect } from 'react';
import {
  Button,
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  Toolbar,
  useTranslate,
  useDataProvider,
  useLocale
} from 'react-admin';

import { ClientLastProductsList, ClientOrderList, ClientReservesList, ClientSampleList, ClientHistoryList } from "../orders/OrdersStats";
//import { EarlyWinesList } from "../orders/EarlyWines";

import {LinkField} from '../customComponents/LinkField';
import Loader from 'react-loader-spinner';
import {NoResult} from "../customComponents/NoResult";
import {SubListPagination} from "../customComponents/SubListPagination";

import IconClose from "@material-ui/icons/Close";
import BarChartIcon from '@material-ui/icons/BarChart';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';


import Dialog from '@material-ui/core/Dialog';

import Grid from '@material-ui/core/Grid';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const CustomerStatButton = ({record, ...props}) => {
    const [showDialog, setShowDialog] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        <>
            <Button onClick={handleClick} stye={{width: "20px"}}>
                <BarChartIcon />
            </Button>
            <Dialog fullScreen open={showDialog} onClose={handleCloseClick} TransitionComponent={Transition}>
               <AppBar className={classes.appBar}>
                 <Toolbar>
                   <IconButton edge="start" onClick={handleCloseClick} aria-label={translate("ra.actions.close")}>
                     <IconClose />
                   </IconButton>
                   <Typography variant="h6" className={classes.title} color="primary"> {translate("resources.customers.formTab.statistics")} {record.cl_rsoc1} </Typography>
                 </Toolbar>
               </AppBar>
               <AutoGrid cl_code= {record[props.linkValue]} {...props}/>
             </Dialog>
        </>
    );
}

export default CustomerStatButton;

export const CustomerEditStatButton = (props) => {
    const [showDialog, setShowDialog] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        <>
            <Button onClick={handleClick}>
                <BarChartIcon />
            </Button>
            <Dialog fullScreen open={showDialog} onClose={handleCloseClick} TransitionComponent={Transition}>
               <AppBar className={classes.appBar}>
                 <Toolbar>
                   <IconButton edge="start" onClick={handleCloseClick} aria-label={translate("ra.actions.close")}>
                     <IconClose />
                   </IconButton>
                   <Typography variant="h6" className={classes.title} color="primary"> {translate("resources.customers.formTab.statistics")} {props.cl_rsoc1} </Typography>
                 </Toolbar>
               </AppBar>
               <AutoGrid cl_code= {props.record[props.linkValue]} {...props}/>
             </Dialog>
        </>
    );
}


const AutoGrid = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const locale = useLocale();
  const dataProvider= useDataProvider();
  const numberFormatter = item => new Intl.NumberFormat(locale, {}).format(item);

  const [state, setState] = useState({
      primeur: true,
    });

  const [data, setData] = useState({
    isLoading:true,
    isError: false,
    data:[]
  });
  const [margins, setMargins] = useState({
    isLoading:true,
    isError: false,
    data:[]
  });
  const [dataFilter, setDataFilter] = useState({});      // filter on data retreive from server
  let userFilter = {["eq_com.cl_code_fac"]: "'" + props.cl_code + "'"};

  const getData = () => {
    getMargins();
    getSales();
  }

  const getSales = () => {
    setData({
      isLoading:true,
      isError: false
    });
    dataProvider.getList(('sales_data'),{
      pagination:{
          page:1,
          perPage:25
      },
      sort:{
          field:null,
          order:null
      },
      filter: Object.assign({}, userFilter, dataFilter)
    })
    .then((results)=>{
      setData({
        isLoading:false,
        isError: false,
        data: results.data
      });
    })
    .catch((erreur)=>{
      //console.log(erreur);
      setData({
        isLoading:false,
        isError: true,
        data:[{id:0, count:0}]
      });
    });
  };

  // margin data
  const getMargins = () => {
    setMargins({
      isLoading:true,
      isError: false
    });
    dataProvider.getList(('margin_data'),{
      pagination:{
          page:1,
          perPage:25
      },
      sort:{
          field:null,
          order:null
      },
      filter: Object.assign({}, userFilter, dataFilter)
    })
    .then((results)=>{
      setMargins({
        isLoading:false,
        isError: false,
        data: results.data
      });
    })
    .catch((erreur)=>{
      //console.log(erreur);
      setMargins({
        isLoading:false,
        isError: true,
        data:[{id:0, count:0}]
      });
    });
  };

  useEffect(()=> {
    // if(state.primeur) delete dataFilter["notin_com.t32_typ"];
    // else Object.assign(dataFilter, {["notin_com.t32_typ"]: "('EX', 'AX', 'FX', 'RX', '04', '13', 'SX', 'XA', 'XR', 'XF', 'X4')"});
    getData();
  },[]);

  const onPrimeurChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    let tmpFilter = dataFilter;
    if(event.target.checked) delete tmpFilter["notin_com.t32_typ"];
    else Object.assign(tmpFilter, {["notin_com.t32_typ"]: "('EX', 'AX', 'FX', 'RX', '04', '13', 'SX', 'XA', 'XR', 'XF', 'X4')"});
    setDataFilter(tmpFilter);
    getData();
  };


  return (
    <div>
      <Grid container spacing={3} direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12}  >
          <FormControlLabel
            control={
              <Switch
                checked={state.primeur}
                onChange={onPrimeurChange}
                name="primeur"
                color="primary"
              />
            }
            label={`${translate("pos.dashboard.filters.primeur")}`}
          />
        </Grid>
         <Grid item xs={12} sm={6}>
          {data.isLoading ?
            <Loader
                 type="Oval"
                 color="#392722"
                 height={100}
                 width={100}
            />
          :
            <ResponsiveContainer width="99%" height={250}>
             <LineChart data={data.data}>
               <XAxis dataKey="id" />
               <YAxis tickFormatter={numberFormatter} width={100} />
               <CartesianGrid strokeDasharray="3 3" />
               <Line type="monotone" dataKey="sales_n_3" name={translate("pos.dashboard.salesYear", {year: new Date().getFullYear()-3})} stroke="#48d1cc"  />
               <Line type="monotone" dataKey="sales_n_2" name={translate("pos.dashboard.salesYear", {year: new Date().getFullYear()-2})} stroke="#ff7f50"  />
               <Line type="monotone" dataKey="sales_prec_year" name={translate("pos.dashboard.salesYear", {year: new Date().getFullYear()-1})} stroke="#82ca9d"  />
               <Line type="monotone" dataKey="sales_year" name={translate("pos.dashboard.salesYear", {year: new Date().getFullYear()})} stroke="#8884d8" strokeWidth={2}/>
               <Text> {translate("pos.dashboard.salesAmount")}</Text>
               <Tooltip formatter={numberFormatter}/>
               <Legend verticalAlign="top" height={36}/>
             </LineChart>
            </ResponsiveContainer>
          }
         </Grid>
         <Grid item xs={12} sm={6}>
          {margins.isLoading ?
            <Loader
                 type="Oval"
                 color="#392722"
                 height={100}
                 width={100}
            />
          :
            <ResponsiveContainer width="99%" height={250}>
             <LineChart data={margins.data}>
               <XAxis dataKey="id" />
               <YAxis tickFormatter={numberFormatter} width={100} />
               <CartesianGrid strokeDasharray="3 3" />
               <Line type="monotone" dataKey="margin_n_3" name={translate("pos.dashboard.marginYear", {year: new Date().getFullYear()-3})} stroke="#48d1cc"  />
               <Line type="monotone" dataKey="margin_n_2" name={translate("pos.dashboard.marginYear", {year: new Date().getFullYear()-2})} stroke="#ff7f50"  />
               <Line type="monotone" dataKey="margin_prec_year" name={translate("pos.dashboard.marginYear", {year: new Date().getFullYear()-1})} stroke="#82ca9d"  />
               <Line type="monotone" dataKey="margin_year" name={translate("pos.dashboard.marginYear", {year: new Date().getFullYear()})} stroke="#8884d8"  strokeWidth={2} />
               <Text> {translate("pos.dashboard.marginAmount")}</Text>
               <Tooltip formatter={numberFormatter}/>
               <Legend verticalAlign="top" height={36}/>
             </LineChart>
            </ResponsiveContainer>
          }
         </Grid>
         <Grid item xs={12} sm={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.history")}</Typography>
          <ClientHistoryList cl_code={props.cl_code}/>
         </Grid>
         <Grid item  md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.last_ten_orders")}</Typography>
          <ClientOrderList cl_code={props.cl_code}/>
         </Grid>
         <Grid item  md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.sample_list")}</Typography>
          <ClientSampleList {...props} cl_code={props.cl_code} />
         </Grid>
         <Grid item md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.last_products")}</Typography>
          <ClientLastProductsList cl_code={props.cl_code}/>
         </Grid>
         <Grid item md={12} lg={12}>
          <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.earlyWines")}</Typography>
          <EarlyWinesList {...props} basePath="/earlyWines" resource="earlyWines" filter={{eq_cl_code: "'" + props.cl_code + "'"}}
            exporter={false} actions={false}
          />
         </Grid>
         <Grid item md={12} lg={12}>
            <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.reserve_list")}</Typography>
            <ClientReservesList cl_code={props.cl_code} />
         </Grid>
         <Grid item md={12} lg={12}>
            <Typography variant="h6" className={classes.title} color="primary">{translate("resources.customers.fields.offers_list")}</Typography>
            <ClientOffersList cl_code={props.cl_code} />
         </Grid>
       </Grid>
     </div>
  );
}

const ClientOffersList = (props) =>{

  return(
      <List
        {...props}
          resource="customerOffers"
          basePath="/customerOffers"
          filter={{"eq_cust.cl_code": "'" + props.cl_code + "'" }}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          pagination={<SubListPagination/>}
          sort={{field: "prod.libelle", order: "ASC"}}
          title=" "
          empty={<NoResult/>}
      >
          <Datagrid >
            <TextField source="code_offre" label="resources.offres.field.code" />
            <DateField source="date_fin_offre" label={"resources.offres.field.dateEnd"} />
            <TextField source='libelle' label={`resources.products.fields.appell`} />
            <TextField source='millesime' label={`resources.products.fields.mill`} />
            <NumberField source='qte' label={`resources.offres.field.qte`} />
            <NumberField source="prix" label="resources.offres.field.prix" />
          </Datagrid>
      </List>
  )
}

const EarlyWinesList = props =>{
  return(
    <List
      {...props}
      sort={{field: "product.ba_lib", order: "ASC"}, {field: "millesime", order: "DESC"}}
      bulkActionButtons={false}
      title=" "
      empty={<NoResult/>}
      pagination={<SubListPagination/>}
    >
      <Datagrid >
          <TextField source="product.ba_lib" label="resources.orders.fields.product" sortBy='"product.ba_lib"'/>
        <TextField source="MILLESIME" label={`resources.orders.fields.millesime`} />
        <NumberField source="solde"  label="resources.orders.fields.solde" sortBy='"solde"'/>
      </Datagrid>
    </List>
  )
}
