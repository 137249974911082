import * as React from "react";
import {
  Create,
  Datagrid,
  Edit,
  EmailField,
  FormTab,
  List,
  SaveButton,
  Show,
  SimpleShowLayout,
  TabbedForm,
  TextField,
  Toolbar,
} from 'react-admin';
import {LinkField} from '../customComponents/LinkField';
import MobileProspect from './mobileProspect';
import { useMediaQuery } from '@material-ui/core';
import {SocialNetwork} from './socialMedia';
import {General,  ProspectFile, SocialMedia, ProspectOffers} from './CreateProspect';
import NotificationsIcon from '@material-ui/icons/Notifications';
import {ClientNotifications} from '../notification/clientNotifications';

import {ClientMails} from '../mails/mails';
import Drawer from '@material-ui/core/Drawer';
import MailIcon from '@material-ui/icons/Mail';
import IconButton from '@material-ui/core/IconButton';
import {useState} from 'react';

const util = require('../utils');

function setUserFilter() {
  return Object.assign({}, util.getUserFilter());
}


const ProspectTitle = ({ record }) => {
  return <span>{record ? `${record.cl_rsoc1}` : ''}</span>;
}

export const ProspectList = props =>{
      const isXsmall = useMediaQuery(theme =>theme.breakpoints.down('xs'));
      const userFilter = setUserFilter();

      return(
          <List
              filter={Object.assign( {},userFilter)}
              {...props}
              hasEdit hasList
              sort="id"
              bulkActionButtons={false}
          >
              {isXsmall ? (
                      <MobileProspect/>
                  ) : (
              <Datagrid >
                <LinkField label="resources.customers.fields.name" source="cl_rsoc1" linkValue="id" {...props}/>
                <TextField label="resources.customers.fields.correspondant" source="cl_corres" />
                <EmailField label='resources.customers.fields.email' source = 'cl_email' />
                <TextField label="resources.customers.fields.phone" source="cl_tel" />
                <TextField label="resources.customers.fields.country" source="cl_pays" />
              </Datagrid>
                  )}
          </List>
      )
}

const ProspectToolbar = props => (
  <Toolbar {...props} >
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

export const ProspectCreate = (props) => {

    return (
      <Create undoable={false}  title={'resources.customers.page.create_prospect'}  {...props} >
        <TabbedForm>
          <FormTab label='resources.customers.formTab.general'>
            <General/>
          </FormTab>
          <FormTab label='resources.customers.formTab.customer_file'>
            <ProspectFile/>
          </FormTab>
          <FormTab label='resources.customers.formTab.social_network'>
            <SocialMedia/>
          </FormTab>
        </TabbedForm>
      </Create>
    );
}

export const  ProspectEdit = (props) => {

  const [openNotification, setNotificationOpen] = useState(false);
  const handleNotificationDrawer = () => {
    setNotificationOpen(!openNotification);
  }

  const [openMail, setMailOpen] = useState(false);
  const handleMailDrawer = () => {
    setMailOpen(!openMail);
  }

  return (
    <>
      <Edit title={<ProspectTitle/>} undoable={true} {...props} >
          <TabbedForm toolbar={<ProspectToolbar/>}>
            <FormTab label='resources.customers.formTab.general'>
                <General/>
            </FormTab>
            <FormTab label='resources.customers.formTab.customer_file'>
              <ProspectFile/>
            </FormTab>
            <FormTab label='resources.customers.formTab.social_network'>
                <SocialNetwork/>
            </FormTab>
            <FormTab label='resources.customers.formTab.offers'>
                <ProspectOffers {...props}/>
            </FormTab>
            <FormTab label='Emails'>
                <ClientMails id={props.id} type="prospect"/>
            </FormTab>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleNotificationDrawer}
            >
              <NotificationsIcon />
            </IconButton>
            {/* <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleMailDrawer}
            >
              <MailIcon />
            </IconButton> */}
          </TabbedForm>
      </Edit>
      <Drawer
        anchor="right"
        open={openNotification}
        onClose={handleNotificationDrawer}
      >
        <ClientNotifications id={props.id} />
      </Drawer>
      {/* <Drawer
        anchor="right"
        open={openMail}
        onClose={handleMailDrawer}
      >
        <ClientMails id={props.id} type="prospect"/>
      </Drawer> */}
    </>
  );
}
let exports = {
  edit: ProspectEdit,
  list: ProspectList,
  create: ProspectCreate,
};
export default exports;
