import * as React from "react";
import { Box} from '@material-ui/core';
import { TextInput} from 'react-admin/lib';

export const SocialNetworkShow = (record,props)=>{

    //const validateEmail = email();

    return (
      <Box>
        <Box>
          <TextInput source='facebook' label="resources.customers.fields.facebook"  fullWidth/>
        </Box>
        <Box>
          <TextInput source='instagram' label="resources.customers.fields.instagram"  fullWidth/>
        </Box>
        <Box>
         <TextInput source='linkedin' label="resources.customers.fields.linkedin"  fullWidth/>
        </Box>
        <Box>
          <TextInput source='twitter' label="resources.customers.fields.twitter"  fullWidth/>
        </Box>
        <Box>
          <TextInput source='whatsapp' label="resources.customers.fields.whatsapp"  fullWidth/>
        </Box>
        <Box>
          <TextInput source='tiktok' label="resources.customers.fields.tiktok"   fullWidth/>
        </Box>
        <Box>
          <TextInput source='wechat' label="resources.customers.fields.wechat"   fullWidth/>
        </Box>
      </Box>
    );
  }
