// prod server
// export const HOST = '192.1.1.11:3008';
// let protocoleHttp = 'https';
// let protocoleWs = 'wss';
// let docRoot = "cfe-doc/";

// test server
export const HOST = 'cfe-api.ange-online.app';
export const PROTOCOLE_HTTP = 'https';
export const PROTOCOLE_WS = 'wss';
export const DOC_ROOT = "cfe-doc/";
export const CORS = "server";

// dev server
// export const HOST = 'localhost:3008';
// export const LOCAL_HOST = 'localhost:3000';
// export const PROTOCOLE_HTTP = 'http';
// export const PROTOCOLE_WS = 'ws';
// export const DOC_ROOT = "cfe-doc/"; // must be terminated by \ or / depending on o.s.
// export const CORS = "internal";
