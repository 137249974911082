import * as React from "react";
import { Admin, Resource,useTranslate } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import { Helmet } from 'react-helmet'
import {APP_NAME, APP_VERSION} from './confProvider';

import authProvider from './authProvider';
import {dataProvider} from './dataProvider';
import frenchMessages from './i18n/fr';
import Dashboard from './dashboard/dashboard';
import {Layout} from './layout';
import customRoutes from './routes';

import offres from './offres/offres';
import customer from './customers/customers';
import invoice from './invoices/invoices';
import order from './orders/AllOrders';
import prospect from './prospect/prospect';
import categories from './products/categories';
//import {CustomerEdit, CustomerList, CustomerCreate}  from './customers/customers';
//import {RelationCreate, RelationEdit, RelationList, RelationShow} from './relation/relation';
//import {ClientTotalCreate, ClientTotalEdit, ClientTotalList, ClientTotalShow} from './clientTotal/clientTotal';
//import {OrderCreate , OrderList} from './orders/orders';



//import { createBrowserHistory as createHistory } from 'history';
//import {Statistique} from './statistique/statistique';

import {Person, ShoppingCart, Contacts, LocalBar, Euro} from '@material-ui/icons';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import {Details as ArticleEdit} from "./products/details";
//import {ProductEdit} from "./products/ProductEdit";

// import {
//   useLocale,
//   useTranslate,
// } from 'react-admin';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'en') {
        return import('./i18n/en').then(messages => messages.default);
    }

    // Always fallback on english
    return frenchMessages;
}, 'fr');
//const history = createHistory();

const App = () => {
    const translate = useTranslate();
  return(
    <>
      <Helmet><title>{APP_NAME} {APP_VERSION}</title></Helmet>
      <Admin
      title=""
      dataProvider={dataProvider}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dashboard={Dashboard}
      layout={Layout}
      i18nProvider={i18nProvider}
      >
          {/*<Resource icon={Person} name="client" list={CustomerList} edit={CustomerEdit} create={CustomerCreate} />*/}
          <Resource icon={LocalBar}       name='categories' options={{label:translate("pos.menu.categories") }} {...categories} />
          <Resource icon={Person}         name="customers"  options={{label:translate("pos.menu.customers") }}  {...customer}   />
          <Resource icon={LocalOfferIcon} name="offres"     options={{label:translate("pos.menu.offres")}}      {...offres}     />
          <Resource icon={Euro}           name="invoices"   options={{label:translate("pos.menu.invoices")}}    {...invoice}    />
          <Resource icon={ShoppingCart}   name="orders"     options={{label:translate("pos.menu.orders")}}      {...order}      />
          <Resource icon={Contacts}       name="prospect"   options={{label:translate("pos.menu.prospect")}}    {...prospect}   />

          {/* Resources without menu entry */}
          <Resource name='assistant' />
          <Resource name='clientSamples' options={{label: "Echantillons"}}/>
          <Resource name='clientLastProducts' options={{label: "Derniers produits"}}/>
          <Resource name="customerHistory"/>
          <Resource name="customerHistoryDetail"/>
          <Resource name="customerOffers" />
          <Resource name="deliveredCustomers"/>
          <Resource name="productHistory"/>
          <Resource name="productOffers" />
          <Resource name='palette' />
          <Resource name='best_orders_customers' />
          <Resource name='objectifs_avancement' />
          <Resource name='commande'/>
          <Resource name="country" />
          <Resource name="statistical_area" />  
          <Resource name='reserves' options={{label: "Réserves"}}/>
          <Resource name='DetailsProd'/>
          <Resource name='last_created_customers' />
          <Resource name='month_orders'/>
          <Resource name='earlyWines'/>
          <Resource name="clientReserves" />
          <Resource name="orderLines" />
          <Resource name="salesman" />
          <Resource name="sales_mgr" />
          <Resource name="categoriesClient" />
          <Resource name="typesClient" />
          <Resource name="zone" />
          <Resource name="clientProductOrders" />
          <Resource name="productReserves" />
          <Resource name="contacts"/>
          <Resource name="getInternalEmails" />
          <Resource name="retard_primeur" />
          <Resource name="statutClients" />
          {/*<Resource name="offres" />*/}
          <Resource name="mail"/>
          <Resource name="lignes_offres" />
          <Resource name="status_offre" />
          <Resource name="status" />
          <Resource name="offres_clients" />
          <Resource name="offres_prospect" />
          <Resource name="prospectOffers" />
          <Resource name="utilisateur" />
          <Resource name="productLastOrders" />
          <Resource name="productSamples" />
          <Resource name="productLastCustomers" />
          <Resource name="productHistoryDetail" />
          <Resource name="allproducts" />
          <Resource name="productsCondallproducts" />
          <Resource name="addProductsLookup" />
          <Resource name="addCustomersLookup" />
          <Resource name="offreCustomers" />
          <Resource name="addProspectLookup"/>
          <Resource name="updateStatus"/>
          <Resource name="fichiers" />
          <Resource name="dossiers" />
          <Resource name="products_files" />
          {/* Resources categorie */}
          <Resource name='productsFWD' />
          <Resource name='productsTarif' />
          <Resource name='productsHorsTarif' />
          <Resource name='productsPrimeurs' />
          <Resource name='conditProductFWD' />
          <Resource name="productsCondFWD" />
          <Resource name='conditProductTarif' />
          <Resource name="productsCondHorsTarif" />
          <Resource name="productsCondTarif" />
          <Resource name='caractProd'/>
          <Resource name='palette' />
          <Resource name='matieresSeches' />
          <Resource name='articles'/>
          <Resource name='articlesHorsTarif'/>
          <Resource name='lu_millesime' />
          <Resource name='palette'/>
          <Resource name='libelleLookup'/>
          <Resource name='millesimeLookup'/>
          <Resource name='conditLookup'/>
          <Resource name='couleurLookup'/>
          <Resource name='productsLookup'/>
          <Resource name='prixLookup'/>
          <Resource name='codeLookup'/>
          <Resource name='clientLookup'/>
          <Resource name='offresEmailClients'/>
          <Resource name='offresEmailProspect'/>
          <Resource name="articles" edit={ArticleEdit} />
          <Resource name="articlesHorsTarif" edit={ArticleEdit} />
          <Resource name="director" />
          <Resource name="profile" />
          {/* technical resources */}
          <Resource name="fileList" />

          {/* lookups*/}
      </Admin>
    </>
)};

export default App;
