import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    required,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
    FormWithRedirect,
    DateInput,
    SelectInput,
    useTranslate,
    useRefresh,
    useRedirect,
    ReferenceInput,
} from 'react-admin';
import {
  Typography,
  Box,
  CardContent,
  Card,
  DialogActions,
  Grid
} from '@material-ui/core';
import AddOffer from './addProdButton';


function CreateOffer(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('offres');
  const notify = useNotify();
  const form = useForm();
  const translate = useTranslate();
  const refresh = useRefresh();
  const redirect = useRedirect();
  //console.log(props);

  const handleSubmit = async values => {
      create(
          { payload: { data: values } },
          {
              onSuccess: ({ data }) => {
                  setShowDialog(false);
                  notify('Offre créé')
                  form.change('id', data.id);
                  refresh()
              },
              onFailure: ({ error }) => {
                  notify(error.message, 'l\'offre n\'a pas pu être créé');
                  refresh()
              }
          }
      );
  };
  return (
    <FormWithRedirect
        resource="offres"
        save={handleSubmit}
        render={({
            handleSubmitWithRedirect,
            pristine,
            saving
        }) => (
            <>
            <Box fullWidth>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                        {translate("resources.offres.offres")}
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={3}>
                        <TextInput label="resources.offres.offres" source="code_offre" validate={required()}/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <TextInput label="resources.offres.field.com" source="code_com" resource="offres" defaultValue={localStorage.id} disabled/>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={3}>
                        <DateInput label="resources.offres.field.dateAdd" source="date_offre" defaultValue={new Date()} validate={required()}/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <DateInput label="resources.offres.field.dateEnd" source="date_fin_offre" defaultValue={new Date()} validate={required()}/>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3}>
                        <ReferenceInput  label="resources.offres.field.status" source="status" reference="status_offre"
                          sort={{field: "libelle", order: "ASC"}}>
                          <SelectInput optionText="libelle" validate={required()} />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInput fullWidth label="resources.offres.field.lib" source="libelle" validate={required()} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Box>


                <DialogActions>
                    <SaveButton
                        handleSubmitWithRedirect={
                            handleSubmitWithRedirect
                        }
                        pristine={pristine}
                        saving={saving}
                        disabled={loading}
                    />
                </DialogActions>

            </>
        )}
    />
  );
}
export default CreateOffer;
