import React from "react";
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import {Typography, Box} from '@material-ui/core';
import {useTranslate} from 'react-admin';
import {logoSocialMedia} from '../style.js';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import IconButton from '@material-ui/core/IconButton';
import {
  FormDataConsumer,
} from "react-admin";

import {LinkField} from '../customComponents/LinkField';
import {SubListPagination} from "../customComponents/SubListPagination";

const util = require('../utils');

export const General = (record,props)=>{
  const classes = logoSocialMedia();
  const translate=useTranslate();
  let userFilter = util.getUserFilter();

    //const validateEmail = email();
  return (
    <Box>
      <Box display="flex">
        <Box flex={2} mr="0.25em">
          <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.formBox.identity')}</Typography>

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput  source="cl_rsoc1" label='resources.customers.fields.name' fullWidth />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <ReferenceInput
                source="t75_typcl" reference="typesClient"
                allowEmpty={true} resettable={false}
                emptyText= {`pos.all`}
                formClassName={classes.inlineBlock}
                sort={{field: "ta_lib", order: "ASC"}}
                perPage={10000}
                label='resources.customers.fields.client_type'
                fullWidth
              >
                <SelectInput optionText="ta_lib" />
              </ReferenceInput>
            </Box>
            <Box flex={1} mr="0.5em">
              <ReferenceInput
                source="t02_catcli" reference="categoriesClient"
                allowEmpty={true} resettable={false}
                emptyText= {`pos.all`}
                formClassName={classes.inlineBlock}
                sort={{field: "ta_lib", order: "ASC"}}
                perPage={10000}
                label='resources.customers.fields.client_category'
                fullWidth
              >
                <SelectInput optionText="ta_lib" />
              </ReferenceInput>
            </Box>
          </Box>
          <Box mt="0.5em" />
          <Box display ="flex" >
            <Box flex={1} mr="0.5em">
              <IconButton color="primary" aria-label="Facebook" href={`${record.record.facebook}`}  target="_blank">
                <FacebookIcon className={classes.logo} />
              </IconButton>
            </Box>
            <Box flex={1} mr="0.5em">
              <IconButton color="primary" aria-label="Linkedin" href={`${record.record.linkedin}`} target="_blank">
                <LinkedInIcon className={classes.logo} />
              </IconButton>
            </Box>
            <Box flex={1} mr="0.5em">
              <IconButton color="primary" aria-label="Twitter" href={`${record.record.twitter}`} target="_blank">
                <TwitterIcon className={classes.logo} />
              </IconButton>
            </Box>
            <Box flex={1} mr="0.5em">
              <IconButton color="primary" aria-label="Instagram" href={`${record.record.instagram}`} target="_blank">
                <InstagramIcon className={classes.logo} />
              </IconButton>
            </Box>
          </Box>
          <Box mt="1.75em" />
          <Box>
            <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.internal_info')}</Typography>
            <Box display="flex">
              <Box flex={1} >
                <Box  mr="0.5em">
                  <ReferenceInput
                    source="ta3_libre" reference="sales_mgr"
                    allowEmpty={true} resettable={false}
                    emptyText= {`pos.all`}
                    filter={userFilter}
                    formClassName={classes.inlineBlock}
                    sort={{field: "dcomm.ta_lib", order: "ASC"}}
                    perPage={10000}
                    label='resources.customers.fields.commercialDirector'
                    fullWidth
                  >
                    <SelectInput optionText="ta_lib" />
                  </ReferenceInput>
                </Box>
              </Box>
              <Box flex={1} >
                <Box  mr="0.5em"  >
                  <ReferenceInput
                    source="ta7_libre" reference="salesman"
                    allowEmpty={true} resettable={false}
                    emptyText= {`pos.all`}
                    filter={userFilter}
                    formClassName={classes.inlineBlock}
                    sort={{field: "ta_lib", order: "ASC"}}
                    perPage={10000}
                    label='resources.customers.fields.commercial'
                    fullWidth
                  >
                    <SelectInput optionText="ta_lib" />
                  </ReferenceInput>
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={1} >
                <Box mr="0.5em">
                  <ReferenceInput
                    source="ta1_libre" reference="assistant"
                    allowEmpty={true} resettable={false}
                    emptyText= {`pos.all`}
                    filter={userFilter}
                    formClassName={classes.inlineBlock}
                    sort={{field: "assist.ta_lib", order: "ASC"}}
                    perPage={10000}
                    label='resources.customers.fields.assistant'
                    fullWidth
                  >
                    <SelectInput optionText="ta_lib" />
                  </ReferenceInput>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex={2}>
          <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.main_address')}</Typography>

          <TextInput  source="cl_adr1" label='resources.customers.fields.address_line_1' fullWidth />
          <TextInput  source="cl_adr2" label='resources.customers.fields.address_line_2' fullWidth />

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput source="cl_cp" label='resources.customers.fields.zipcode' fullWidth/>
            </Box>
            <Box flex={1}  >
              <TextInput label="Pays" source="cl_pays" fullWidth/>
            </Box>
            <Box flex={1} mr="0.5em">
              <TextInput source="cl_ville" label='resources.customers.fields.city' fullWidth/>
            </Box>
          </Box>

          <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.contact')}</Typography>

          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <TextInput source="cl_tel" label='resources.customers.fields.phone' fullWidth />
            </Box>
            <Box flex={1}  mr="0.5em">
              <TextInput source="cl_fax" label='resources.customers.fields.fax' fullWidth />
            </Box>
            <Box flex={1} >
              <TextInput source="cl_email" label='resources.customers.fields.email' fullWidth />
            </Box>
          </Box>
          <Box display="flex">
            <Box flex={1}  mr="0.5em">
              <TextInput  source="cl_url" label='resources.customers.fields.url' fullWidth />
            </Box>
            <Box flex={1} >
                <TextInput label="Nom du correspondant" source="cl_corres" fullWidth />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
)};

export const ProspectFile = (record,props)=>{
    const translate=useTranslate();
    //const validateEmail = email();
    return (
        <Box>
            <Box display="flex">
                <Box flex={1}>
                <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.fields.prospectComment1')}</Typography>
                    <Box display="flex">
                        <Box flex={1} mr="0.5em">
                            <TextInput {...props}  source="offres" label = ' ' variant="outlined" multiline rows={13} fullWidth />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box flex={1}>
            <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.fields.prospectComment2')}</Typography>
                <Box display="flex">
                <Box flex={1} mr="0.5em">
                    <TextInput {...props}  source="shipping" label = ' ' variant="outlined" multiline rows={13}  fullWidth />
                </Box>
            </Box>
        </Box>
      </Box>
    );
};

export const SocialMedia = (record,props)=>{

    return (
        <Box>

            <Box>
            <TextInput source='facebook' label="resources.customers.fields.facebook" variant="outlined" fullWidth/>
            </Box>
            <Box>
            <TextInput source='linkedin' label="resources.customers.fields.linkedin" variant="outlined" fullWidth/>
            </Box>
            <Box>
            <TextInput source='twitter' label="resources.customers.fields.twitter" variant="outlined" fullWidth/>
            </Box>
            <Box>
            <TextInput source='whatsapp' label="resources.customers.fields.whatsapp" variant="outlined" fullWidth/>
            </Box>
            <Box>
            <TextInput source='tiktok' label="resources.customers.fields.tiktok"  variant="outlined" fullWidth/>
            </Box>
            <Box>
            <TextInput source='wechat' label="resources.customers.fields.wechat"  variant="outlined" fullWidth/>
            </Box>
        </Box>
    );
};

export const ProspectOffers = (record,props)=>{
    const translate=useTranslate();
    //const validateEmail = email();
    //console.log("record", record);
    //console.log("props", props)
    return (
      <List
        {...props}
          resource="prospectOffers"
          basePath="/prospectOffers"
          filter={{"eq_cust.id_client_cfe": "'" + record.id + "'" }}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={false}
          actions={false}
          pagination={<SubListPagination/>}
          sort={{field: "prod.libelle", order: "ASC"}}
          title=" "
          empty={false}
      >
          <Datagrid >
            <TextField source="code_offre" label="resources.offres.field.code" />
            <DateField source="date_fin_offre" label={"resources.offres.field.dateEnd"} />
            <TextField source='libelle' label={`resources.products.fields.appell`} />
            <TextField source='millesime' label={`resources.products.fields.mill`} />
            <NumberField source='qte' label={`resources.offres.field.qte`} />
            <NumberField source="prix" label="resources.offres.field.prix" />
          </Datagrid>
      </List>
  )
};
