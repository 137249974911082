import * as React from 'react';
// import { defaultProps } from 'recompose';

import {
  FunctionField,
  Link,
  TextField,
  useRecordContext,
} from 'react-admin';

export const LinkField = props => {
  var fieldValue;
  if(props.record) {
    var splitField=props.linkValue.split(".");
    fieldValue = splitField.reduce(function(acc, val){
      if(acc !== undefined) {
        acc = acc[val];
      }
      return acc;
    }, props.record)
    if(props.linkParam) fieldValue += '/'+props.linkParam;
  }

  return (
    props.record ? (
      <Link to={`${props.basePath}/${fieldValue}`} target={props.target !== undefined? props.target: false} rel="noopener noreferrer">
        {true&&props.render !== undefined?
          <FunctionField {...props} />
        :
          <TextField {...props} />
        }
      </Link>
  ):
  ( <TextField {...props} /> )
)};

export const LinkToFile = (props) => {
  const {source} = props;
  const record = useRecordContext(props);
  if(record === undefined) return null;
  return (
    props.url ? (
      //<a href={`${props.url}/${record[source]}.pdf`} target={props.target} rel="noreferrer">{record[source]}</a>
      <a href={`${props.url}${record[source]}${props.type}`} target={props.target} rel="noreferrer">{record[source]}</a>
    ):
    ( <TextField {...props} /> )
)};

export const LinkToImg = (props) => {
  //console.log(props);
  const {source} = props;

  const record = useRecordContext(props);
  //console.log(record);
  //console.log(record[source]);
  return (
    props.url ? (
      <a href={`${props.url}${record[source]}${props.docfile}/${record[source]}${props.type}`} target={props.target} rel="noreferrer">{record[source]}</a>
    ):
    ( <TextField {...props} /> )
)};

// const TextFieldInForm = ({ variant, ...props }) => <TextField {...props} />;
// const withDefaultProps = defaultProps({...TextField.defaultProps});
// TextFieldInForm = withDefaultProps(TextFieldInForm);
//
// export TextFieldInForm;
