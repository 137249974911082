import React, {useState, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export const AccordionTab = (props) => {
  const [subject, setSubject] = useState(props.subject);
  useEffect(()=>{
    let tmpSubject = props.subject.substring(0,30);
    if(tmpSubject !== props.subject){
      tmpSubject+="...";
    }
    setSubject(tmpSubject);
  },[props.subject]);
  return (
    <Accordion square expanded={props.isExpanded} onChange={()=>{props.handleChange()}} style={{width:"60vw"}}>
      <AccordionSummary>
        <Typography style={{width:"300px", display:"block"}}>{props.from}</Typography>
        <Typography>{subject}</Typography>
        <Typography style={{right:'10px',position:'absolute'}}>{props.date}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {props.from} -> {props.to}
        <Typography>
          {props.body}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
