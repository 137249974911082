import React from 'react';
import {
  Datagrid,
  DateField,
  DateInput,
  Edit,
  FormWithRedirect,
  List,
  ListButton,
  ReferenceInput,
  SelectInput,
  NumberField,
  TextField,
  TextInput,
  ReferenceField,
  EditButton,
  Create,
  SimpleForm,
  required,
  TopToolbar,
  TabbedForm,
  FormTab,
  DeleteButton,
  NumberInput,
} from 'react-admin';
import {
    CardContent,
    Box,
    Grid,
    Typography,
    Drawer,
} from '@material-ui/core';
import {useTranslate} from 'react-admin';
import { Route } from 'react-router';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import AddFiles from './editOffre/offresAddFiles';
import AddCustomers from './editOffre/offresAddCustomers';
import AddProspects from './editOffre/offresAddProspects';
import AddProducts from './editOffre/listOffresAddProducts';
import {Modal} from '../modals/modals';
import IconContentAdd from '@material-ui/icons/Add';
import {OffresCreateMails} from './editOffre/offresEmail';
import SendIcon from '@material-ui/icons/Send';
import Aside from './AsideListOffres';
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 600,
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

const CustomToolbar = props => (
  <TopToolbar {...props} >
    <ListButton basePath={props.basePath}/>
  </TopToolbar>
);

const OffresAddCustomers = props => {
  const translate = useTranslate();
  return(
    <TopToolbar>
    <Modal  title={translate("resources.offres.title.AddCli")}
            buttonIcon={<IconContentAdd />}
            buttonText={translate("resources.offres.button.AddCli")}
            {...props} width="md"
    >
    <AddCustomers {...props} />
    </Modal>
    </TopToolbar>
);}
const OffresAddProducts = props => {
  const translate = useTranslate();
  return(
    <TopToolbar>
    <Modal  title={translate("resources.offres.title.AddProd")}
            buttonIcon={<IconContentAdd />}
            buttonText={translate("resources.offres.button.AddProd")}
            {...props} width="lg"
    >
    <AddProducts {...props} />
    </Modal>
    </TopToolbar>
);}
const OffresAddProspects = props => {
  const translate = useTranslate();
  return(
    <TopToolbar>

    <Modal  title={translate("resources.offres.title.AddPros")}
            buttonIcon={<IconContentAdd />}
            buttonText={translate("resources.offres.button.AddPros")}
            {...props} width="md"
    >
    <AddProspects {...props} />
    </Modal>
    </TopToolbar>

);}
const OffresAddFiles = props => {
  const translate = useTranslate();
  return(
    <TopToolbar>
    <Modal  title={translate("resources.files.fields.file")}
            buttonIcon={<IconContentAdd />}
            buttonText={translate("resources.files.fields.addFile")}
            {...props} width="md"
    >
    <AddFiles {...props} />
    </Modal>
    </TopToolbar>
);}
const OffresSendEmail = props => {
  const translate = useTranslate();
  return(
    <Modal  title={translate("resources.offres.title.SendEmail")}
            buttonIcon={<SendIcon />}
            buttonText={translate("resources.offres.title.SendEmail")}
            width="md"
    >
    <OffresCreateMails {...props}  id={props.record.id} />
    </Modal>
);}

export const OffresEdit = (props) => (
    <Edit
      undoable={true} component="div"
      actions={<CustomToolbar {...props}/>}
      {...props} title=" "
    >
      <FormWithRedirect {...props} render={(formProps) =>  (
        <TabbedForm syncWithLocation={false} toolbar={false} {...props}>

          <FormTab label="resources.offres.offres">
            <OffresLines embedded={true} id={props.id} {...props}/>
          </FormTab>

            <FormTab label="resources.offres.field.prod">
              <OffresListProducts embedded={true} fullWidth id={props.id}/>
            </FormTab>

            <FormTab label="resources.offres.field.cli">
              <OffresListCustomers  embedded={true} id={props.id}/>
            </FormTab>

            <FormTab label="resources.offres.field.pros">
              <OffresListProspect  embedded={true} id={props.id}/>
            </FormTab>

        </TabbedForm>

      )}/>
    </Edit>
);

const Prix = (props) =>(
  <Grid container>
    <Grid item xs={12} sm={12} md={12}>
      <Edit
        resource='offres'
        basePath='/offres'
        title=" "
        {...props}
      >
        <SimpleForm redirect={`/offres/${props.record.id_offre}`}>
          {props.code_com === localStorage.id ?
            <>
              <NumberInput source="prix" label="resources.offres.field.prix"/>
              <NumberInput source="qte" label="resources.offres.field.qte" />
            </>
          :
            <>
              <NumberInput source="prix" label="resources.offres.field.prix" disabled/>
              <NumberInput source="qte" label="resources.offres.field.qte" disabled/>
            </>
          }
        </SimpleForm>
      </Edit>
    </Grid>
    <Grid item xs={12} sm={12} md={12}>
      <FileList {...props} />
    </Grid>
  </Grid>
);

export const FileList = (props) =>(
  <>
    <TopToolbar>
      <OffresAddFiles {...props} />
    </TopToolbar>
    <List
      resource="fichiers"
      basePath="/fichiers"
      filter={{eq_id_lignes_offres: "'" + props.id + "'"}}
      filters={null}
      perPage={5}
      exporter={false}
      currentSort={{field:"libelle",order:"ASC"}}
      title=" "
      bulkActionButtons={false}
    >
      <Datagrid {...props} >
        <TextField label="resources.offres.field.codeProd" source="libelle" />
        {props.code_com === localStorage.id ?
          <DeleteButton redirect={`/offres/${props.record.id_offre}`}/>
          : ""
        }
      </Datagrid>
    </List>
  </>
);

const UpdateStatus = (props) =>(
    <Edit  {...props}
    resource={props.resource}
    basePath={props.basePath}
    title=" "
    >
      <SimpleForm redirect={`/offres/${props.record.id_offre}`} >
      {props.code_com === localStorage.id ?
        <ReferenceInput label="resources.offres.field.status" source="status" reference="status_offre"
          sort={{field: "libelle", order: "ASC"}}>
          <SelectInput optionText="libelle" validate={required()} />
        </ReferenceInput>
        :
        <ReferenceInput label="resources.offres.field.status" source="status" reference="status_offre"
          sort={{field: "libelle", order: "ASC"}}>
          <SelectInput optionText="libelle" disabled />
        </ReferenceInput>
      }
      </SimpleForm>
    </Edit>
);

const OffresLines = (props) =>  (
  <Edit {...props} style={{width:"auto"}} >
    <SimpleForm redirect={props.location.pathname} >
      <Box fullWidth>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                {props.record.code_com === localStorage.id ?
                  <TextInput label="resources.offres.offres" source="code_offre" validate={required()} />
                  :
                  <TextInput label="resources.offres.offres" source="code_offre" disabled />
                }
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextInput label="resources.offres.field.com" source="code_com" defaultValue={localStorage.id} disabled/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                {props.record.code_com === localStorage.id ?
                  <DateInput label="resources.offres.field.dateAdd" source="date_offre" defaultValue={new Date()} validate={required()}/>
                  :
                  <DateInput label="resources.offres.field.dateAdd" source="date_offre" defaultValue={new Date()} disabled/>
                }
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                {props.record.code_com === localStorage.id ?
                  <DateInput label="resources.offres.field.dateEnd" source="date_fin_offre" defaultValue={new Date()} validate={required()}/>
                  :
                  <DateInput label="resources.offres.field.dateEnd" source="date_fin_offre" defaultValue={new Date()} disabled/>
                }
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                {props.record.code_com === localStorage.id ?
                  <ReferenceInput label="resources.offres.field.status" source="status" reference="status_offre"
                    sort={{field: "libelle", order: "ASC"}}>
                    <SelectInput optionText="libelle" validate={required()} />
                  </ReferenceInput>
                  :
                  <ReferenceInput label="resources.offres.field.status" source="status" reference="status_offre"
                    sort={{field: "libelle", order: "ASC"}}>
                    <SelectInput optionText="libelle" disabled />
                  </ReferenceInput>
                }
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                {props.record.code_com === localStorage.id ?
                  <TextInput fullWidth label="resources.offres.field.lib" source="libelle" validate={required()} />
                  :
                  <TextInput fullWidth label="resources.offres.field.lib" source="libelle" disabled />
                }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </SimpleForm>
  </Edit>
);
const OffresListProducts = (props) =>(
  <>
  {props.record.code_com === localStorage.id ?
    <TopToolbar>
    <OffresAddProducts {...props} />
    </TopToolbar>
    : ""
  }
    <List
        resource="lignes_offres"
        basePath="/lignes_offres"
        filter={{eq_id_offre: "'" + props.id + "'"}}
        filters={null}
        perPage={5}
        exporter={false}
        currentSort={{field:"libelle_produit",order:"ASC"}}
        title=" "
        bulkActionButtons={false}
    >
      <Datagrid expand={<Prix code_com={props.record.code_com} />} {...props} >
        <TextField label="resources.offres.field.codeProd" source="code_produit" />
        <TextField label="resources.offres.field.lib" source="libelle_produit" />
        <TextField label="resources.offres.field.mill" source="millesime" />
        <TextField label="resources.offres.field.format" source="st_desi" />
        <NumberField label="resources.offres.field.prix" source="prix" />
        <NumberField label="resources.offres.field.qte" source="qte" />
        {props.record.code_com === localStorage.id ?
          <DeleteButton redirect={`/offres/${props.id}`}/>
          : ""
        }
      </Datagrid>
    </List>
    </>
);
const OffresListCustomers = (props) =>(
  <>
  {props.record.code_com === localStorage.id ?
    <TopToolbar>
      <OffresAddCustomers {...props} />
    </TopToolbar>
    : ""
  }
    <List
       resource="offres_clients"
       basePath="/offres_clients"
       filter={{eq_id_offre: "'" + props.id + "'"}}
       filters={null}
       perPage={5}
       exporter={false}
       currentSort={{field:"id",order:"ASC"}}
       title=" "
       bulkActionButtons={false}
    >
      <Datagrid expand={<UpdateStatus code_com={props.record.code_com}  />} {...props} style={{width:"-webkit-fill-available"}} >
        <ReferenceField link={false} label="resources.offres.field.cli" source="cl_code" reference="offreCustomers">
          <TextField source="cl_rsoc1" />
        </ReferenceField>
        <ReferenceField link={false} label="resources.customers.fields.city" source="cl_code" reference="offreCustomers">
          <TextField source="cl_ville" />
        </ReferenceField>
        <ReferenceField link={false} label="resources.customers.fields.country" source="cl_code" reference="offreCustomers">
          <TextField source="cl_pays" />
        </ReferenceField>
        <ReferenceField link={false} label="resources.offres.field.status" source="status" reference="status_offre">
          <TextField source="libelle" />
        </ReferenceField>
        {props.record.code_com === localStorage.id ?
          <OffresSendEmail {...props} />
          : ""
        }
        {props.record.code_com === localStorage.id ?
          <DeleteButton  redirect={`/offres/${props.id}`}/>
          : ""
        }
      </Datagrid>
    </List>
    </>
);
const OffresListProspect = (props) => (
  <>
  {props.record.code_com === localStorage.id ?
    <TopToolbar>
      <OffresAddProspects {...props} />
    </TopToolbar>
    : ""
  }
     <List
         resource="offres_prospect"
         basePath="/offres_prospect"
         filter={{eq_id_offre: "'" + props.id + "'"}}
         filters={null}
         perPage={5}
         exporter={false}
         currentSort={{field:"id",order:"ASC"}}
         title=" "
         bulkActionButtons={false}
     >
       <Datagrid  expand={<UpdateStatus code_com={props.record.code_com} />} {...props} style={{width:"-webkit-fill-available"}}  >
        <ReferenceField link={false} label="resources.offres.field.pros" source="id_client_cfe" reference="prospect">
          <TextField source="cl_rsoc1" />
        </ReferenceField>
        <ReferenceField link={false} label="resources.customers.fields.city" source="id_client_cfe" reference="prospect">
          <TextField source="cl_ville" />
        </ReferenceField>
        <ReferenceField link={false} label="resources.customers.fields.country" source="id_client_cfe" reference="prospect">
          <TextField source="cl_pays" />
        </ReferenceField>
        <ReferenceField link={false} label="resources.offres.field.status" source="status" reference="status_offre">
          <TextField source="libelle" />
        </ReferenceField>
        {props.record.code_com === localStorage.id ?
        <OffresSendEmail {...props} />
        : ""
      }
      {props.record.code_com === localStorage.id ?
        <DeleteButton redirect={`/offres/${props.id}`}/>
        : ""
      }
       </Datagrid>
     </List>
  </>
);

export const OffresList = props =>{
const classes = useStyles();
  return(
    <List
      aside={<Aside/>}
      {...props}
      sort={{field: "date_offre", order: "ASC"}}
      className={classnames(classes.list)}
      
    >
      <Datagrid>
        <TextField source="libelle" label="resources.offres.field.lib" />
        <TextField label="resources.offres.field.by" source="code_com" />
        <ReferenceField link={false} label="resources.offres.field.status" source="status" reference="status_offre">
          <TextField source="libelle" />
        </ReferenceField>
        <DateField label="resources.offres.field.dateAdd" source='date_offre'/>
        <DateField label="resources.offres.field.dateEnd" source='date_fin_offre'/>
        <EditButton  {...props}/>
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

export const OffresCreate = (props) => {
  const translate = useTranslate();
  return(
    <Create  actions={<OffreActions />} {...props} >
      <SimpleForm >
        <Box fullWidth>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" gutterBottom>
                    {translate("resources.offres.offres")}
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={12} md={3}>
                    <TextInput label="resources.offres.offres" source="code_offre" validate={required()}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <TextInput label="resources.offres.field.com" source="code_com" defaultValue={localStorage.id} disabled/>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={3}>
                    <DateInput label="resources.offres.field.dateAdd" source="date_offre" defaultValue={new Date()} validate={required()}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <DateInput label="resources.offres.field.dateEnd" source="date_fin_offre" defaultValue={new Date()} validate={required()}/>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <ReferenceInput  label="resources.offres.field.status" source="status" reference="status_offre"
                       sort={{field: "libelle", order: "ASC"}}>
                      <SelectInput optionText="libelle" validate={required()} />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextInput fullWidth label="resources.offres.field.lib" source="libelle" validate={required()} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </SimpleForm>
    </Create>
)};
const OffreActions = ({ basePath }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
    </TopToolbar>
);

const exports = {
  list: OffresList,
  edit: OffresEdit,
  create:OffresCreate,
}
export default exports;
