import React, { useState, useEffect } from 'react';
import {
    Button,
    useNotify,
    FormWithRedirect,
    useTranslate,
    TextInput,
    useRefresh,
    SaveButton,
    useGetList,
    FileInput,
    FileField,
    RichTextField
} from 'react-admin';

import {
  makeStyles,
  DialogActions,
  IconButton,
  Typography,
  Dialog,
  useMediaQuery,
  useTheme,
  FormGroup,
  FormControlLabel,
  Switch,
  Box,
  Badge
} from '@material-ui/core';


import IconClose from "@material-ui/icons/Close";
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';

import RichTextInput from 'ra-input-rich-text';

import { URL_SERVER_REST } from '../confProvider';

import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent  from '@material-ui/core/DialogContent';
import { grey, lightGreen } from '@material-ui/core/colors';

import { NotificationShow } from '../notification/notification';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { MailShow } from '../mails/mails';


import { getWebsocket, getAlert, read as readWebsocket} from "../websocket/websocket";

import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#e6230c',
    color: '#e6230c',
    boxShadow: `0 0 0 0px ${theme.palette.background.paper}`,
    width: '30%',
    height: '30%',
    borderRadius: '50%'
  },
}))(Badge);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
  },
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  titlePart: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'auto',
    alignContent: 'start',
  },
  root: {
      marginTop: '1em',
  },
  media: {
      height: 140,
  },
  title: {
      paddingBottom: '0.5em',
  },
  actionSpacer: {
      display: 'flex',
      justifyContent: 'space-around',
  },
  root_parent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'auto',
    alignContent: 'start',
    width:'100%',
    backgroundColor:grey[200],
  },

  flex_row:{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  grey: {
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
  },
  dark_red: {
    color: '#EA6252',
    backgroundColor: grey[300],
  },
  avatar: {
    marginRight: '10px',
    width:"30px",
    height:"30px",
    fontSize: '13px'
  },

  fluxContent_container: {
    gridArea: '2 / 2 / 3 / 3',
    width:'100%',
    height:'60vh',
    overflowY:'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius:'10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
  fluxContent_post: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    margin:'10px 25px',
    backgroundColor:grey[100],
    borderRadius:'10px',
  },
  fluxContent_post_author:{
    margin:'5px 25px',
    fontSize:'16px',
    fontWeight:"normal",
    textAlign:"left",
    inlineSize: "max-content"

  },
  fluxContent_post_body:{
    margin:'5px 25px 15px 25px',
    fontSize:'13px',
  },
  fluxContent_post_date:{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin:'15px 25px',
    color:grey[500],
    fontSize:'11px',
  },
  fluxTextArea_container:{
    flex: '0 0 auto',
    margin: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'auto',
    alignContent: 'start',
    height:'10vh',
  },
  fluxTextArea_area:{
    flex: '1 0 auto',
    margin: '10px',
    width: '35vw'
  },
  fluxTextArea_btn:{
    flex: '1 0 auto',
    margin: '10px',
    width: '5vw'
  }
}));

export const Modal = ({record, ...props}) => {
    const [showDialog, setShowDialog] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down(props.width));

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    return (
        <>
          <Button onClick={handleClick} label={props.buttonText||""} style={{...props.buttonStyle}}>{props.buttonIcon||""}</Button>
          <Dialog
            onClose={handleCloseClick}
            aria-labelledby="customized-dialog-title"
            open={showDialog}
            fullWidth={true}
            fullScreen={fullScreen}
            maxWidth={fullScreen?false:props.width}
            scroll="paper"
          >
            <MuiDialogTitle id="customized-dialog-title" disableTypography className={classes.titlePart}>
              <Typography variant="h6" style={{margin:"10px 75px 0 0"}}>{props.title}</Typography>
              <span className={classes.buttons} style={{...props.actionButtonsStyle}}>
                {props.actionButtons}
                <IconButton aria-label="close" onClick={handleCloseClick}>
                  <IconClose />
                </IconButton>
              </span>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
              {props.children}
            </MuiDialogContent>
          </Dialog>
        </>
    );
}

export const ModalAbonnements = (props) => {
  let [users, setUsers] = useState({});

  useEffect(()=>{
    let tmpUsers = Object.keys(props.users).sort((a,b)=>{return a===props.user?-1:b===props.user?1:a.localeCompare(b);}).reduce((obj, key) => {
      obj[key] = props.users[key];
      return obj;
    }, {});
    setUsers(tmpUsers);
  },[props.users, props.user]);

  const handleSwitchClick = (newState) => {
    setUsers({...users, [newState.target.name]: !users[newState.target.name]});
    props.modifyFollowing(newState.target.name, !users[newState.target.name]); //users still not modified by setUsers
  }

  return(
    <Modal
      buttonIcon={<SettingsIcon color={grey[500]} />}
      title={"Gérer mes abonnements"}
      width="sm"
    >
      <div style={{marginLeft:'25px'}}>
        <label>Mes abonnements :</label>
        <FormGroup style={{marginLeft:'25px'}}>
          {Object.keys(users).filter(user=>{return user===props.user || users[user]}).map((username, i)=>{
            return(
              <FormControlLabel
              disabled={username===props.user}
              control={
                <Switch
                  checked={username===props.user || users[username]}
                  onChange={handleSwitchClick}
                  name={username}
                />
              }
              label={username}
            />);
          })}
        </FormGroup>
      </div>
      <div style={{marginLeft:'25px'}}>
        <label>Autres utilisateurs :</label>
        <FormGroup>
          {Object.keys(users).filter(user=>{return user!==props.user && !users[user]}).map((username, i)=>{
            return(
              <FormControlLabel
              control={
                <Switch
                  checked={users[username]}
                  onChange={handleSwitchClick}
                  name={username}
                />
              }
              label={username}
            />);
          })}
        </FormGroup>
      </div>
    </Modal>
  );
}

export const ModalNotifications = (props) => {

  const [isThereNotification, changeState] = useState(0);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    let intervalNotification = setInterval(() => {
      changeState(getAlert()!=="false"?"!":0);
      setUserList(getWebsocket().notifications.userList);
    }, 1000);

    return function cleanup() {
      clearInterval(intervalNotification);
    };
  },[]);

  return(

    <Modal
      buttonIcon={
        <StyledBadge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
          badgeContent={isThereNotification}
          style={{marginLeft:"-10px"}}
          onClick={()=>{readWebsocket();}}
        >
        <NotificationsIcon style={{ color:grey[500]}}/>
        </StyledBadge>
      }
      title={"Notifications"}
      width="md"
      actionButtons={
          <ModalAbonnements
            users={userList}
            user={localStorage.getItem('username')}
            modifyFollowing={getWebsocket().methods.modifyFollowing}
          />
      }
    >
      <NotificationShow />
    </Modal>
  );
}

export const ModalCreateMails = (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  let to = [];
  let cc = [];
  let bcc = [];
  let message = props.message !== undefined? props.message: "";
  let subject = "";

  if(props.mailType !== undefined && props.record !== undefined) {
    if(props.mailType === "ReplyMail" || props.mailType === "ReplyToAllMail" || props.mailType === "ForwardMail") {
      if(props.mailType !== "ForwardMail") to.push(props.record.from);
      message = props.record.body;
      subject = ((props.mailType === "ForwardMail")? translate("resources.offres.email.forward"): translate("resources.offres.email.replyTo")) + props.record.subject;
    }
    if(props.mailType === "ReplyToAllMail") {
      if(!Array.isArray(props.record.cc && props.record.cc !== localStorage.getItem("mail"))) cc.push(props.record.cc);
      else cc = props.record.cc.filter(item => item !== localStorage.getItem("mail"));
      if(Array.isArray(props.record.to))
        props.record.to.filter(item => item !== localStorage.getItem("mail")).map(item => cc.push(item));
    }
  }
  delete props.record.cc;
  delete props.record.subject;

  /* Récupération destinataires email OFFR1 = A:, OFFRx = CC: */
  const { data: contacts } = useGetList(
    'contacts',
    { page: 1, perPage: 255 },
    { field: "TA_CODE", order: "ASC"},
    { eq_CL_CODE: "'" + props.id + "'" }
  );
  if(to.length === 0 && props.mailType === "newMail") {
    if (Object.entries(contacts).length > 0) {
      to = Object.values(contacts).filter(item => item.TA_CODE.trim() === 'OFFR1').map(item => item.CTT_EMAIL.trim());
      cc = Object.values(contacts).filter(item => item.TA_CODE.substr(0, 4) === "OFFR" && item.TA_CODE.substr(4, 1) !== '1').map(item => item.CTT_EMAIL.trim());
    }
    else to.push(props.record.email)
  }

  const { data: get_internal } = useGetList(
    'getInternalEmails',
    { page: 1, perPage: 255 },
    { field: "id", order: "ASC"},
    { eq_cl_code: "'" + props.id + "'"}
  );

  if (localStorage.profile === "ADM") {
    if (Object.entries(get_internal).length > 0) {
      Object.values(get_internal).map(item => {
        if(item.mail_assistante !== null) cc.push(item.mail_assistante);
        if(item.mail_commercial !== null) cc.push(item.mail_commercial);
      })
    }
  }
  else if (localStorage.profile === "DCOM") {
    if (Object.entries(get_internal).length > 0) {
      Object.values(get_internal).map(item => {
        if(item.mail_assistante !== null) cc.push(item.mail_assistante);
        if(item.mail_commercial !== null) cc.push(item.mail_commercial);
      })
    }
  }
  else if (localStorage.profile === "COM") {
    if (Object.entries(get_internal).length > 0) {
      Object.values(get_internal).map(item => {
        if (item.mail_assistante !== null) cc.push(item.mail_assistante);
      })
    }
  }
  else if (localStorage.profile === "ASSI") {
    if (Object.entries(get_internal).length > 0) {
      Object.values(get_internal).map(item => {
        if (item.mail_commercial !== null) cc.push(item.mail_commercial);
      })
    }
  }

  const handleSend = async values => {
    const formData = new FormData();

    if(values.fichier !== undefined){
      values.fichier.forEach((item) => {
        formData.append('datafiles' ,item.rawFile);
      });
    };
    let incorrectEmails = [];
    let incorrectCc = [];
    let incorrectBcc = [];

    if (!values.com) {
      values.com = localStorage.firstName + " " + localStorage.lastName
    };
    if (values.emails) {
      if(!Array.isArray(values['emails'])){
        values['emails'] = values['emails'].trim().replace(/ /g, '').split(',');

      }
      incorrectEmails =values['emails'].filter((emails)=>{
        return !((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(emails));
      });
    };
    if (values.cc) {
      if(!Array.isArray(values['cc'])){
        values['cc'] = values['cc'].trim().replace(/ /g, '').split(',');
      }
      incorrectCc =values['cc'].filter((cc)=>{
        return !((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(cc));
      });
    };
    if (values.bcc) {
      if(!Array.isArray(values['bcc'])){
        values['bcc'] = values['bcc'].trim().replace(/ /g, '').split(',');
      }
      incorrectCc =values['bcc'].filter((bcc)=>{
        return !((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(bcc));
      });
    };

    if ((incorrectEmails.length === 0) && (incorrectCc.length === 0) && (incorrectBcc.length === 0)) {

        formData.append('email',JSON.stringify(values['emails']))
        formData.append('bcc',JSON.stringify(values['bcc']))
        formData.append('cc',JSON.stringify(values['cc']))
        formData.append('subject',values['subject'])
        formData.append('message',values['message'])
        formData.append('com',values['com'])
        formData.append('id',localStorage.id)

         fetch(URL_SERVER_REST+`/mail/exchange`,
          {
            method: 'POST',
            body: formData,
            credentials: 'include',
          })
          .then(res=>res.json())
          .then((response)=>{
            // console.log(response);
            if (response.msg == "NoError") {

              notify(translate("resources.offres.email.Succesmail"),  'success' );
              refresh();
            }else {
              notify(translate("resources.offres.email.Failmail"), 'warning' );
            }
          })
          // .catch(values=>console.log(values));
      }else {
          notify(translate("resources.offres.email.nonValidMail"), 'warning' );
      };
    };

  return(

      <FormWithRedirect
        save={handleSend}
        render={({
            handleSubmitWithRedirect,
            pristine
        }) => (
        <form>
        <Box display="flex" >
          <Box fullWidth mr="0.5em">
              <TextInput label="resources.offres.email.to" source="emails" defaultValue={to.toString()} required fullWidth/>
              <TextInput label="resources.offres.email.cc" source="cc" defaultValue={cc.toString()}   fullWidth/>
              <TextInput label="resources.offres.email.bcc" source="bcc"  fullWidth/>
              <TextInput label="resources.offres.email.objet" source="subject" required defaultValue={subject} fullWidth/>
              <FileInput source="fichier" label="resources.offres.email.otherFiles" multiple>
                <FileField source="src" title="title" />
              </FileInput>
                <RichTextInput required
                  source="message"
                  label="resources.offres.email.message"
                  defaultValue={message}
                />

          </Box>
        </Box>
        <DialogActions>
            <SaveButton label="resources.offres.email.send" icon={<SendIcon />}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
            />
        </DialogActions>
        </form>
      )}
    />
  );
}

export const ReplyMails = (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleSend = async values => {
    const formData = new FormData();

    if(values.fichier !== undefined){
      values.fichier.forEach((item) => {
        formData.append('datafiles' ,item.rawFile);
      });
    };
    let incorrectEmails = [];
    let incorrectCc = [];
    let incorrectBcc = [];

    if (!values.com) {
      values.com = localStorage.firstName + " " + localStorage.lastName
    };
    if (values.emails) {
      if(!Array.isArray(values['emails'])){
        values['emails'] = values['emails'].trim().replace(/ /g, '').split(',');

      }
      incorrectEmails =values['emails'].filter((emails)=>{
        return !((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(emails));
      });
    };
    if (values.cc) {
      if(!Array.isArray(values['cc'])){
        values['cc'] = values['cc'].trim().replace(/ /g, '').split(',');
      }
      incorrectCc =values['cc'].filter((cc)=>{
        return !((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(cc));
      });
    };
    if (values.bcc) {
      if(!Array.isArray(values['bcc'])){
        values['bcc'] = values['bcc'].trim().replace(/ /g, '').split(',');
      }
      incorrectCc =values['bcc'].filter((bcc)=>{
        return !((/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(bcc));
      });
    };

    if ((incorrectEmails.length === 0)) {

        formData.append('email',JSON.stringify(values['emails']))
        formData.append('subject',values['subject'])
        formData.append('message',values['message'])
        formData.append('com',values['com'])
        formData.append('id',localStorage.id)
        formData.append('mailType',props.mailType)
      if (props.mailType === "ReplyToAllMail") {
        formData.append('bcc',JSON.stringify(values['bcc']))
        formData.append('cc',JSON.stringify(values['cc']))
      }

         fetch(URL_SERVER_REST+`/mail/exchange/${props.mailType}`,
          {
            method: 'POST',
            body: formData,
            credentials: 'include',
          })
          .then(res=>res.json())
          .then((response)=>{
            if (response.msg == "NoError") {

              notify(translate("resources.offres.email.Succesmail"),  'success' );
              refresh();
            }else {
              notify(translate("resources.offres.email.Failmail"), 'warning' );
            }
          })
          .catch(values=>console.log(values));
      }else {
          notify(translate("resources.offres.email.nonValidMail"), 'warning' );
      };
    };

  return(
    <FormWithRedirect
      save={handleSend}
      render={({
          handleSubmitWithRedirect,
          pristine
        }) => (
        <form>
        <Box display="flex" >
          <Box fullWidth mr="0.5em">
              <TextInput label="resources.offres.email.to" source="emails" defaultValue={props.email} required fullWidth/>
              {props.mailType === "ReplyToAllMail"  &&
              <>
               <TextInput label="resources.offres.email.cc" source="cc" fullWidth/>
               <TextInput label="resources.offres.email.bcc" source="bcc" fullWidth/>
               </>
               }
              <TextInput label="resources.offres.email.objet" source="subject"  required fullWidth/>

              <RichTextInput required source="message" label="resources.offres.email.message" />
               {props.mailType === "ForwardMail" &&
              <RichTextField  required source="body" defaultValue={props.record.body} />
               }

          </Box>
        </Box>
        <DialogActions>
            <SaveButton label="resources.offres.email.send" icon={<SendIcon />}
                handleSubmitWithRedirect={handleSubmitWithRedirect}
                pristine={pristine}
            />
        </DialogActions>
        </form>
      )}
    />
  );
}
