import * as React from "react";
import {
    BooleanInput,
    Datagrid,
    DateInput,
    FormDataConsumer,
    FormWithRedirect,
    List,
    NumberInput,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';

import {LinkField} from "../customComponents/LinkField";
import {NoResult} from "../customComponents/NoResult";

import {
  Box,
  Grid,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useTranslate} from 'react-admin';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import { FaTiktok } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaWeixin } from "react-icons/fa";
import IconButton from '@material-ui/core/IconButton';
import {logoSocialMedia} from '../style.js';

export const GeneralShow = (record,props)=>{
    const classes = logoSocialMedia();
    const translate=useTranslate();
    const isLarge = useMediaQuery(theme =>theme.breakpoints.up('lg'));
    //const validateEmail = email();
    return (
        <Box>
          <Box display="flex" sx={{my: 0, p: 0}}>
            {/* -------------------------  IDENTITY  ------------------------ */}
            <Box flex={2} mr="0.25em">

              <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.formBox.identity')}</Typography>

              <Box display="flex" sx={{my: 0, p: 0}}>
                <Box flex={1} mr="0.25em" my="0em">
                  <TextInput InputProps={{style:{color:"black"}}} source="cl_rsoc1"
                    label= { <Typography variant="body1" style={{color:"black"}} >{translate('resources.customers.fields.name')}</Typography>}
                    disabled fullWidth
                  />
                </Box>
                <Box flex={1} ml="0.25em">
                  <TextInput  InputProps={{style:{color:"black"}}} source="cl_rsoc2"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.name2')}</Typography>}
                    disabled fullWidth
                  />
                </Box>
              </Box>

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput InputProps={{style:{color:"black"}}}  source="cl_code"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.code')}</Typography>}
                    disabled
                  />
                </Box>
                <Box flex={1} mr="0.5em">
                  <TextInput InputProps={{style:{color:"black"}}} source="cl_code_ftc"  label='resources.customers.fields.invoice_customer_code'
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.invoice_customer_code')}</Typography>}
                    disabled
                  />
                </Box>
                <Box flex={1} mr="0.5em">
                  <TextInput InputProps={{style:{color:"black"}}} source='typeClient.ta_lib'
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.client_type')}</Typography>}
                    disabled
                  />
                </Box>
              </Box>

              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput InputProps={{style:{color:"black"}}} source="cl_siren"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.siren')}</Typography>}
                    disabled
                  />
                  </Box>
                <Box flex={1} mr="0.5em">
                  <TextInput InputProps={{style:{color:"black"}}} source="cl_vatnum"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.vat_number')}</Typography>}
                    disabled
                  />
                </Box>
                <Box flex={1} >
                  <TextInput InputProps={{style:{color:"black"}}} source='catClient.ta_lib'
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.client_category')}</Typography>}
                    disabled
                  />
                </Box>
              </Box>

              <Box display ="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput InputProps={{style:{color:"black"}}} source="cl_accise"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.accise_number')}</Typography>}
                    fullWidth disabled
                  />
                </Box>
                <Box flex={1} mr="0.5em">
                  <TextInput InputProps={{style:{color:"black"}}} source="cl_accise_ent"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.accise_warehouse')}</Typography>}
                    fullWidth disabled
                  />
                </Box>
                <Box flex={1} >
                  <TextInput InputProps={{style:{color:"black"}}} source="cl_numeori"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.eori')}</Typography>}
                    disabled
                  />
                </Box>
              </Box>
            </Box> 

            {/* -------------------------  MAIN ADDRESS  ------------------------ */}
            {isLarge &&
              <Box flex={2}>

                <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.main_address')}</Typography>

                <TextInput InputProps={{style:{color:"black"}}}  source="cl_adr1"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.address_line_1')}</Typography>}
                  fullWidth disabled
                />
                
                <TextInput InputProps={{style:{color:"black"}}}  source="cl_adr2" label='resources.customers.fields.address_line_2'
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.address_line_2')}</Typography>}
                  fullWidth disabled
                />

                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextInput InputProps={{style:{color:"black"}}} source="cl_cp"
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.zipcode')}</Typography>}
                      disabled
                    />
                  </Box>
                  <Box flex={1} mr="0.5em" >
                    <TextInput InputProps={{style:{color:"black"}}} source="cl_ville"
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.city')}</Typography>}
                      fullWidth disabled
                    />
                  </Box>
                  <Box flex={1} >
                    <TextInput InputProps={{style:{color:"black"}}} source='pays.ta_lib'
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.country')}</Typography>}
                      disabled
                    />
                  </Box>
                </Box>

                <Box display="flex">  
                  <Box flex={1} >
                                
                    <BooleanInput label={translate('resources.customers.fields.client_masque')} source='client_masque' />
                  </Box> 

                  <Box flex={1} >
                    <ReferenceInput label="resources.customers.fields.statut_client" source="id_statut_client" 
                      reference="statutClients" allowEmpty={true} resettable={false} emptyText={`pos.all`}
                    >
                      <SelectInput optionText="libelle" />
                    </ReferenceInput>
                  </Box>

                </Box>  
                    
              </Box>
            }
          </Box>

          <Box display="flex" sx={{my: 0, p: 0}}>
            {/* -------------------------  DELIVERED CUSTOMERS  ------------------------ */}
            <Box flex={2} mr="0.25em">

              <Typography variant="h6" gutterBottom color='primary'>{translate('resources.customers.formBox.delivered_custs')}</Typography>
              <Box mr={{ xs: 0, sm: '0.5em' }} mt={{xs:0, sm: 0}} >
                <DeliveredCustomersList embedded={true} id={record.record.cl_code}/>
              </Box>

            </Box>
            {/* -------------------------  CONTACT INFORMATIONS  ------------------------ */}
            {isLarge &&
              <Box flex={2}>
                <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.contact')}</Typography>

                <Box display="flex">
                  <Box flex={1}  mr="0.5em">
                    <TextInput InputProps={{style:{color:"black"}}} source="cl_corres"
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.correspondant')}</Typography>}
                      fullWidth disabled
                    />
                  </Box>
                    <Box flex={1} mr="0.5em">
                      <TextInput InputProps={{style:{color:"black"}}} source="cl_tel"
                        label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.phone')}</Typography>}
                        fullWidth disabled
                      />
                    </Box>
                    <Box flex={1}  mr="0.5em">
                      <TextInput InputProps={{style:{color:"black"}}} source="cl_fax"
                        label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.fax')}</Typography>}
                        fullWidth disabled
                      />
                    </Box>
                </Box>
                <Box>
                  <Box display ="flex">
                    <Box flex={1} mr="0.5em">
                      <TextInput InputProps={{style:{color:"black"}}} source="cl_email"
                        label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.email')}</Typography>}
                        fullWidth disabled
                      />
                    </Box>
                    <Box flex={1}  mr="0.5em">
                      <TextInput InputProps={{style:{color:"black"}}} source="cl_url"
                        label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.url')}</Typography>}
                        fullWidth disabled
                      />
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <IconButton color="primary" aria-label="Facebook" href={`${record.record.facebook}`}  target="_blank" disabled = {(record.record.facebook ===null || record.record.facebook.trim().length === 0)}>
                        <FacebookIcon className={(record.record.facebook ===null || record.record.facebook.trim().length === 0)? classes.disabled : classes.logo} />
                      </IconButton>
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <IconButton color="primary" aria-label="Linkedin" href={`${record.record.linkedin}`} target="_blank"  disabled = {(record.record.linkedin ===null || record.record.linkedin.trim().length === 0)}>
                        <LinkedInIcon className={(record.record.linkedin ===null || record.record.linkedin.trim().length === 0)? classes.disabled : classes.logo} />
                      </IconButton>
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <IconButton color="primary" aria-label="Twitter" href={`${record.record.twitter}`} target="_blank" disabled={(record.record.twitter ===null || record.record.twitter.trim().length === 0)}>
                        <TwitterIcon className={(record.record.twitter ===null || record.record.twitter.trim().length === 0)? classes.disabled : classes.logo} />
                      </IconButton>
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <IconButton color="primary" aria-label="Instagram" href={`${record.record.instagram}`} target="_blank" disabled={(record.record.instagram ===null || record.record.instagram.trim().length === 0)}>
                        <InstagramIcon className={(record.record.instagram ===null || record.record.instagram.trim().length === 0)? classes.disabled: classes.logo} />
                      </IconButton>
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <IconButton color="primary" aria-label="Whatsapp" href={`${record.record.whatsapp}`} target="_blank" disabled={(record.record.whatsapp ===null || record.record.whatsapp.trim().length === 0)}>
                        <FaWhatsapp className={(record.record.whatsapp ===null || record.record.whatsapp.trim().length === 0)? classes.disabed: classes.logo} />
                      </IconButton>
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <IconButton color="primary" aria-label="tiktok" href={`${record.record.tiktok}`} target="_blank" disabled={(record.record.tiktok ===null || record.record.tiktok.trim().length === 0)}>
                        <FaTiktok className={(record.record.tiktok ===null || record.record.tiktok.trim().length === 0)? classes.disabled: classes.logo} />
                      </IconButton>
                    </Box>
                    <Box flex={1} mr="0.5em">
                      <IconButton color="primary" aria-label="weixin" href={`${record.record.wechat}`} target="_blank" disabled={(record.record.wechat ===null || record.record.wechat.trim().length === 0)}>
                        <FaWeixin className={(record.record.wechat ===null || record.record.wechat.trim().length === 0)? classes.disabled: classes.logo} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
          </Box>

          {/* -------------------------  SALES INFORMATIONS  ------------------------ */}
          <Box>

            <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.sales_info')}</Typography>

            <Box display="flex">
              {isLarge &&
                <Box flex={1} mr="0.5em">
                  <DateInput InputProps={{style:{color:"black"}}} source="cl_premcde"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.first_order')}</Typography>}
                    disabled
                  />
                </Box>
              }
              <Box flex={1} mr="0.5em">
                <DateInput InputProps={{style:{color:"black"}}} source="cl_dercde"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.last_order')}</Typography>}
                  disabled
                />
              </Box>
                <Box flex={1} mr="0.5em">
                   <NumberInput InputProps={{style:{color:"black"}}} source="objectif_commercial"/>
                </Box>
              <Box flex={1} mr="0.5em">
                <NumberInput InputProps={{style:{color:"black"}}} source="cl_impaye"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.unpayed_number')}</Typography>}
                  disabled
                />
              </Box>
              <Box flex={1} >
                <TextInput InputProps={{style:{color:"black"}}} source="cl_encour"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{
                    translate(isLarge? 'resources.customers.fields.commercial_encour': 'resources.customers.fields.s_commercial_encour')}</Typography>}
                  format={v => new Intl.NumberFormat({useGrouping: true}).format(v)} disabled
                />
              </Box>
              <Box flex={1} mr="0.5em" >
                <TextInput InputProps={{style:{color:"black"}}}  source="promo.budget"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.promo_budget')}</Typography>}
                  format={v => new Intl.NumberFormat({useGrouping: true}).format(v)} disabled
                />
              </Box>
            </Box>
          </Box>

          {/* -------------------------  ACCOUNTING  ------------------------ */}

          <Box>
            <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.accounting')}</Typography>
            <Box display="flex">
              {isLarge &&
                <Box flex={1} mr="0.5em">
                  <TextInput InputProps={{style:{color:"black"}}} source="domiciliation.ta_lib"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.domiciliation')}</Typography>}
                    disabled
                  />
                </Box>
              }
              <Box flex={1} mr="0.5em">
                <TextInput InputProps={{style:{color:"black"}}}  source="indiceCred.ta_lib"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.credit_indice')}</Typography>}
                  disabled
                />
              </Box>
              {isLarge &&
                <Box flex={1} mr="0.5em">
                  <TextInput InputProps={{style:{color:"black"}}}  source="cl_coface"
                    label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.coface_account')}</Typography>}
                    disabled
                  />
                </Box>
              }
              <Box flex={1} mr="0.5em">
                <TextInput InputProps={{style:{color:"black"}}} source="cl_assure"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.insurance_ceiling')}</Typography>}
                  format={v => new Intl.NumberFormat({useGrouping: true}).format(v)} disabled
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <FormDataConsumer>
                  {({formData, ...rest}) => {
                    return(
                    <TextInput source="cl_solcofa"
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.insurance_balance')}</Typography>}
                      format={v => new Intl.NumberFormat({useGrouping: true}).format(v)}
                    inputProps={{readonly:true,
                                  style:{color: formData.cl_solcofa < 0? "red":
                                                formData.cl_solcofa < formData.cl_assure * 0.15? "orange": "green"}}} disabled
                    />
                  )}}
                </FormDataConsumer>
              </Box>
              <Box flex={1} mr="0.5em" >
                <TextInput InputProps={{style:{color:"black"}}} source="cl_compta"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.comptable_encour')}</Typography>}
                  format={v => new Intl.NumberFormat({useGrouping: true}).format(v)} disabled
                />
              </Box>
            </Box>
          </Box>

          {/* -------------------------  INTERNAL INFORMATIONS  ------------------------ */}
          {isLarge &&
            <Box>

              <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.internal_info')}</Typography>

              <Box display="flex">
                <Box flex={1} >
                  <Box  mr="0.5em">
                    <TextInput {...props} InputProps={{style:{color:"black"}}}  source="dcomm.ta_lib"
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.commercialDirector')}</Typography>}
                      disabled fullWidth
                    />
                  </Box>
                </Box>
                <Box flex={1} >
                  <Box  mr="0.5em"  >
                    <TextInput {...props} InputProps={{style:{color:"black"}}}  source="comm.ta_lib"
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.commercial')}</Typography>}
                      disabled fullWidth
                    />
                  </Box>
                </Box>
                <Box flex={1} >
                  <Box mr="0.5em">
                    <TextInput {...props} InputProps={{style:{color:"black"}}}  source="assist.ta_lib"
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.assistant')}</Typography>}
                      disabled fullWidth
                    />
                  </Box>
                </Box>
                <Box flex={1} >
                  <Box  mr="0.5em">
                    <TextInput {...props} InputProps={{style:{color:"black"}}}  source="secsta.ta_lib"
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.statistical_area')}</Typography>}
                      disabled fullWidth
                    />
                  </Box>
                </Box>
                <Box flex={1} >
                  <Box  mr="0.5em" >
                    <TextInput {...props} InputProps={{style:{color:"black"}}}  source="zone.ta_lib"
                      label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.sell_area')}</Typography>}
                      disabled fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        </Box>
    );
  }


// tab displaied on small screens
export const ContactShow = (record,props)=>{
    const classes = logoSocialMedia();
    const translate=useTranslate();

    //const validateEmail = email();
    return (
      <Box>
        <Box display="flex" sx={{my: 0, p: 0}}>
          {/* -------------------------  MAIN ADDRESS  ------------------------ */}
          <Box flex={2}>

            <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.main_address')}</Typography>

            <TextInput InputProps={{style:{color:"black"}}}  source="cl_adr1"
              label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.address_line_1')}</Typography>}
              fullWidth disabled
            />
            <TextInput InputProps={{style:{color:"black"}}}  source="cl_adr2" label='resources.customers.fields.address_line_2'
              label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.address_line_2')}</Typography>}
              fullWidth disabled
            />

            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <TextInput InputProps={{style:{color:"black"}}} source="cl_cp"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.zipcode')}</Typography>}
                  disabled
                />
              </Box>
              <Box flex={1} mr="0.5em" >
                <TextInput InputProps={{style:{color:"black"}}} source="cl_ville"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.city')}</Typography>}
                  fullWidth disabled
                />
              </Box>
              <Box flex={1} >
                <TextInput InputProps={{style:{color:"black"}}} source='pays.ta_lib'
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.country')}</Typography>}
                  disabled
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* -------------------------  CONTACT INFORMATIONS  ------------------------ */}

        <Box flex={12}>
          <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.contact')}</Typography>

          <Box display="flex">
            <Box flex={1}  mr="0.5em">
              <TextInput InputProps={{style:{color:"black"}}} source="cl_corres"
                label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.correspondant')}</Typography>}
                fullWidth disabled
              />
            </Box>
            <Box flex={1} mr="0.5em">
              <TextInput InputProps={{style:{color:"black"}}} source="cl_email" label='resources.customers.fields.email'
                label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.email')}</Typography>}
                fullWidth disabled
              />
            </Box>
            <Box flex={1}  mr="0.5em">
              <TextInput InputProps={{style:{color:"black"}}} source="cl_url"
                label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.url')}</Typography>}
                fullWidth disabled
              />
            </Box>
          </Box>
          <Box>
            <Box display ="flex">
              <Box flex={3} mr="0.5em">
                <TextInput InputProps={{style:{color:"black"}}} source="cl_tel"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.phone')}</Typography>}
                  fullWidth disabled
                />
              </Box>
              <Box flex={3}  mr="0.5em">
                <TextInput InputProps={{style:{color:"black"}}} source="cl_fax"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.fax')}</Typography>}
                  fullWidth disabled
                />
              </Box>
              <Box flex={1} mr="0.5em">
                <IconButton color="primary" aria-label="Facebook" href={`${record.record.facebook}`}  target="_blank" disabled = {(record.record.facebook ===null || record.record.facebook.trim().length === 0)}>
                  <FacebookIcon className={(record.record.facebook ===null || record.record.facebook.trim().length === 0)? classes.disabled : classes.logo} />
                </IconButton>
              </Box>
              <Box flex={1} mr="0.5em">
                <IconButton color="primary" aria-label="Linkedin" href={`${record.record.linkedin}`} target="_blank"  disabled = {(record.record.linkedin ===null || record.record.linkedin.trim().length === 0)}>
                  <LinkedInIcon className={(record.record.linkedin ===null || record.record.linkedin.trim().length === 0)? classes.disabled : classes.logo} />
                </IconButton>
              </Box>
              <Box flex={1} mr="0.5em">
                <IconButton color="primary" aria-label="Twitter" href={`${record.record.twitter}`} target="_blank" disabled={(record.record.twitter ===null || record.record.twitter.trim().length === 0)}>
                  <TwitterIcon className={(record.record.twitter ===null || record.record.twitter.trim().length === 0)? classes.disabled : classes.logo} />
                </IconButton>
              </Box>
              <Box flex={1} mr="0.5em">
                <IconButton color="primary" aria-label="Instagram" href={`${record.record.instagram}`} target="_blank" disabled={(record.record.instagram ===null || record.record.instagram.trim().length === 0)}>
                  <InstagramIcon className={(record.record.instagram ===null || record.record.instagram.trim().length === 0)? classes.disabled: classes.logo} />
                </IconButton>
              </Box>
              <Box flex={1} mr="0.5em">
                <IconButton color="primary" aria-label="Whatsapp" href={`${record.record.whatsapp}`} target="_blank" disabled={(record.record.whatsapp ===null || record.record.whatsapp.trim().length === 0)}>
                  <FaWhatsapp className={(record.record.whatsapp ===null || record.record.whatsapp.trim().length === 0)? classes.disabed: classes.logo} />
                </IconButton>
              </Box>
              <Box flex={1} mr="0.5em">
                <IconButton color="primary" aria-label="tiktok" href={`${record.record.tiktok}`} target="_blank" disabled={(record.record.tiktok ===null || record.record.tiktok.trim().length === 0)}>
                  <FaTiktok className={(record.record.tiktok ===null || record.record.tiktok.trim().length === 0)? classes.disabled: classes.logo} />
                </IconButton>
              </Box>
              <Box flex={1} mr="0.5em">
                <IconButton color="primary" aria-label="weixin" href={`${record.record.wechat}`} target="_blank" disabled={(record.record.wechat ===null || record.record.wechat.trim().length === 0)}>
                  <FaWeixin className={(record.record.wechat ===null || record.record.wechat.trim().length === 0)? classes.disabled: classes.logo} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* -------------------------  INTERNAL INFORMATIONS  ------------------------ */}
        <Box  >

          <Typography variant="h6" color='primary' gutterBottom>{translate('resources.customers.formBox.internal_info')}</Typography>

          <Box display="flex">
            <Box flex={1} >
              <Box  mr="0.5em">
                <TextInput {...props} InputProps={{style:{color:"black"}}}  source="dcomm.ta_lib"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.commercialDirector')}</Typography>}
                  disabled fullWidth
                />
              </Box>
            </Box>
            <Box flex={1} >
              <Box  mr="0.5em"  >
                <TextInput {...props} InputProps={{style:{color:"black"}}}  source="comm.ta_lib"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.commercial')}</Typography>}
                  disabled fullWidth
                />
              </Box>
            </Box>
            <Box flex={1} >
              <Box mr="0.5em">
                <TextInput {...props} InputProps={{style:{color:"black"}}}  source="assist.ta_lib"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.assistant')}</Typography>}
                  disabled fullWidth
                />
              </Box>
            </Box>
            <Box flex={1} >
              <Box  mr="0.5em" >
                <TextInput {...props} InputProps={{style:{color:"black"}}}  source="zone.ta_lib"
                  label= { <Typography variant="body1" style={{color:"black"}} gutterBottom>{translate('resources.customers.fields.sell_area')}</Typography>}
                  disabled fullWidth
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }


const DeliveredCustomersList = (props) => {
  return(
      <List
          resource="deliveredCustomers"
          basePath="/deliveredCustomers"
          filter={{eq_cl_code_ftc: "'" + props.id + "'"}}
          filters={null}
          perPage={5}
          exporter={false}
          actions={false}
          empty={<NoResult/>}
          currentSort={{field:"cl_rsoc1",order:"ASC"}}
          title=" "
      >
        <DeliveredCustomersGrid {...props}/>
      </List>
  )
};

const DeliveredCustomersGrid = (props) => {
  return(
    <Datagrid >
      <LinkField label="resources.customers.fields.name" source="cl_rsoc1" linkValue="cl_code" basePath="/customers" resource="customers"/>
      <TextField source="pays" label="resources.customers.fields.country"/>
    </Datagrid>
  );
};
