import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { WritingArea, Post } from './notification';
import { getWebsocket } from "../websocket/websocket";

const useStyles = makeStyles((theme) => ({
  root_parent: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'auto',
    alignContent: 'start',
    width:'50vw',
    backgroundColor:grey[200],
  },

  flex_row:{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },

  grey: {
    color: theme.palette.getContrastText(grey[300]),
    backgroundColor: grey[300],
  },
  dark_red: {
    color: '#EA6252',
    backgroundColor: grey[300],
  },
  avatar: {
    marginRight: '10px',
    width:"30px",
    height:"30px",
    fontSize: '13px'
  },

  fluxContent_container: {
    gridArea: '2 / 2 / 3 / 3',
    width:'100%',
    height:'60vh',
    overflowY:'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius:'10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },

  fluxContent_post: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    margin:'10px 25px',
    backgroundColor:grey[100],
    borderRadius:'10px',
  },
  fluxContent_post_author:{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin:'5px 25px',
    alignItems: 'center',
  },
  fluxContent_post_body:{
    margin:'5px 25px 15px 25px',
    fontSize:'13px',
  },
  fluxContent_post_date:{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    margin:'15px 25px',
    color:grey[500],
    fontSize:'11px',
  },
  fluxTextArea_container:{
    flex: '0 0 auto',
    margin: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'auto',
    alignContent: 'start',
    height:'10vh',
  },
  fluxTextArea_area:{
    flex: '1 0 auto',
    margin: '10px',
    width: '80%'
  },
  fluxTextArea_btn:{
    flex: '1 0 auto',
    margin: '10px',
    width: '5%'
  }
}));

export const ClientNotifications = (props) => {

  const classes = useStyles();
  const [websocket, setWebsocket] = useState(getWebsocket());
  const [posts, setPosts] = useState([]);

  useEffect(()=>{
    let updateWs = setInterval(function () {
      let tmpWebsocket = getWebsocket();
      //console.log("websocket modified, updating");
      let tmpMsgs = [];
      for (let sender in tmpWebsocket.notifications.notifications[props.id]) {
        let messages = tmpWebsocket.notifications.notifications[props.id][sender];
        for (let notifNo in messages) {
          let notif = messages[notifNo];
          tmpMsgs.push({sender:sender,msg:notif.contenu,date:notif.datenotif});
        }
      }

      tmpMsgs.sort((a,b)=>{return (new Date(b.date)).getTime()-(new Date(a.date)).getTime()});

      let tmpPosts = [];
      for (let i = 0; i < tmpMsgs.length; i++) {
        let notif = tmpMsgs[i];
        tmpPosts.push(<Post
          pseudo={notif.sender}
          msg={<span>{notif.msg.split('\n').map((item)=>{return(<>{item}<br /></>);})}</span>}
          date={notif.date.replace(/T/g,' ').replace(/\..+/,'')}
        />);
      }
      setPosts(tmpPosts);

      setWebsocket(tmpWebsocket);
    }, 1000);
    return function cleanup() {
      clearInterval(updateWs);
    };
  },[props.id]);

  const sendMsgToWebsocket = (message) => {
    getWebsocket().methods.newMessage(message, props.id);
  }

  return(
    <>
    {getWebsocket().isInitiated && <div className={classes.root_parent}>
      <div className={classes.fluxContent_container}>
        {posts}
      </div>
      <div className={classes.fluxTextArea_container}>
        <WritingArea
          onSubmit={sendMsgToWebsocket}
        />
      </div>
    </div>}

    </>
  );
}
