import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
    SaveButton,
    useCreate,
    useNotify,
    FormWithRedirect,
    SelectInput,
    useRefresh,
    ReferenceInput,
    TextInput,
    AutocompleteInput,
    Filter,
    useGetMany,
    useUnselectAll,
    Button,
    useTranslate,
    List,
    Datagrid,
    TextField
} from 'react-admin';
import {
  CardContent,
  DialogActions,
  Grid
} from '@material-ui/core';
import {Modal} from '../../modals/modals';
import IconContentAdd from '@material-ui/icons/Add';
import {LinkField} from '../../customComponents/LinkField';

const util = require('../../utils');
function setUserFilter() {
  return Object.assign({}, util.getUserFilter());
}
const CustomerFilter = (props) => (
    <Filter{...props}>
    <TextInput label="resources.customers.fields.name" source="likeic_cl_rsoc1" alwaysOn />
    <TextInput label="resources.customers.fields.city" source="likeic_cl_ville" alwaysOn />
    <ReferenceInput label="resources.customers.fields.country" source="likeic_t76_pays"
      reference="country" allowEmpty={true} resettable={false} emptyText= {`pos.all`}
      alwaysOn sort={{field:"ta_lib", order:"ASC"}}
      perPage={10000}
    >
      <SelectInput optionText="ta_lib" />
    </ReferenceInput>

    </Filter>
  );

const BulkAddOffreButton = (props) => {
  const translate = useTranslate();
  const { data,loading, error } = useGetMany (
        'addCustomersLookup',
         props.selectedIds
    );
  const [create] = useCreate('offres_clients');
  const unselectAll = useUnselectAll();
  const refresh = useRefresh();
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        data[i]['id_offre'] = props.id;
        data[i]['id'] = null;
        create(
            { payload: { data: data[i] } },
            {
                onSuccess: ({ data }) => {
                    notify(translate("resources.offres.cli.Succescli"),'success')
                    form.change('id', data.id);
                    unselectAll('offreCustomers');
                    refresh();
                },
                onFailure: ( error ) => {
                    notify(translate("resources.offres.cli.Failcli"), 'warning')
                    unselectAll('prospect');
                },
            }
          );
      }
    }
 }
  return (
    <Button
        label="resources.offres.button.AddCli"
        onClick={handleClick}
        disabled={loading}
    >
    </Button>
  );
};

function ListAddCustomers(props) {
  const translate = useTranslate();
  return (
    <List
        resource="offreCustomers"
        basePath="/offreCustomers"
        filters={<CustomerFilter /> }
        perPage={10}
        exporter={false}
        currentSort={{field:"cl_rsoc1",order:"ASC"}}
        title=" "
        bulkActionButtons={<BulkAddOffreButton {...props}/>}
    >
      <Datagrid >
      <TextField label="resources.customers.fields.name" source="cl_rsoc1" />
      <TextField label="resources.customers.fields.city" source="cl_ville" />
      <TextField label="resources.customers.fields.country" source="pays.ta_lib" />
      </Datagrid>
    </List>
  );
}
export default ListAddCustomers;
