import * as conf from './confProvider';
import {fetchUtils} from 'react-admin';
import jsonServerProvider from "ra-data-json-server";
import { stringify } from 'query-string';

export var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

export const httpClient = (url, options = {}) => {
  //console.log('http client',options)
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json','cache-control':'no-cache'});
  }
  // add your own headers here;
  const token = localStorage.getItem('token');
  options.user = {
    authenticated: true,

  }
  // //console.log("################ TOKEN ", token);
  options.headers.set('Authorization', token);
  options.credentials = "include";

  return fetchUtils.fetchJson(url, options);
};

export function setRequestOptions() {
  if(conf.CORS === "internal") {
    const token = localStorage.getItem('token');
    const options = {
      headers: new Headers({ Accept: 'application/json','cache-control':'no-cache'}),
      user: {
        authenticated: true,
        token,
      },
      credentials: "include",
    }
    options.headers.set('Authorization', token);
    return options;
  }
  else {
    const options = {credentials: 'include',};
    return options;
  }
}

export const apiUrl = conf.URL_SERVER_REST;
var dataProvider = jsonServerProvider(apiUrl, httpClient);
dataProvider = {
    ...dataProvider,
    getOne: function (resource, params) {
      var _id = localStorage.getItem('id');
      if(!_id || _id==='null' || _id==='undefined'){
          return dataProvider.getOne(resource,params);
      }
      return httpClient(apiUrl + "/" + resource + "/" + params.id ).then(function (_a) {
          var json = _a.json;
          return ({
              data: json
          });
      });
    },
    getList: function (resource, params) {
        var _a = params.pagination, page = _a.page, perPage = _a.perPage;
        var _b = params.sort, field = _b.field, order = _b.order;
        var _id = localStorage.getItem('id');
        var query;
        if(!_id || _id==='null' || _id==='undefined'){
            query = __assign(__assign({}, fetchUtils.flattenObject(JSON.parse(JSON.stringify(params.filter)))), { _sort: field, _order: order, _start: (page - 1) * perPage, _end: page * perPage });
        }else{
            query = __assign(__assign({}, fetchUtils.flattenObject(JSON.parse(JSON.stringify(params.filter)))), { _sort: field, _order: order, _start: (page - 1) * perPage, _end: page * perPage, idUt:_id });
        }
        var url = apiUrl + "/" + resource + "?" + stringify(query);
        return httpClient(url).then(function (_a) {
            var headers = _a.headers, json = _a.json;
            if (!headers.has('x-total-count')) {
                throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
            }
            return {
                data: json,
                total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
            };
        });
    },
    getManyReference: function (resource, params) {
        var _a = params.pagination, page = _a.page, perPage = _a.perPage;
        var _b = params.sort, field = _b.field, order = _b.order;
        var _id = localStorage.getItem('id');
        var query;
        if(!_id || _id==='null' || _id==='undefined'){
            query = __assign(__assign({}, fetchUtils.flattenObject(params.filter)), { _sort: field, _order: order, _start: (page - 1) * perPage, _end: page * perPage });
        }else{
            query = __assign(__assign({}, fetchUtils.flattenObject(params.filter)), { _sort: field, _order: order, _start: (page - 1) * perPage, _end: page * perPage, idUt:_id });
        }
        var url = apiUrl + "/" + resource + "?" + stringify(query);
        return httpClient(url).then(function (_a) {
            var headers = _a.headers, json = _a.json;
            if (!headers.has('x-total-count')) {
                throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
            }
            return {
                data: json,
                total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
            };
        });
    },

}
export {dataProvider}
