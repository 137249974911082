import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { useState, useEffect } from 'react';
import { Loading, Error, useDataProvider,TextField ,EditButton } from 'react-admin';

import AvatarField from '../layout/avatarField';
import { mobileStyles } from '../style';

const MobileFactures = (props) => {
    const classes = mobileStyles();
    const dataProvider = useDataProvider();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        dataProvider.getList('facture',{
          pagination:{
            page:1,
            perPage:10
          },
          sort:{
            field:null,
            order:null
          }
        })
            .then(({ data }) => {
                setData(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [dataProvider]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!data) return null;


    return (
        <div className={classes.root}>
            {data.map(data => (
                <Card key={data.rowid} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <h2>{data.firstname} {data.lastname}</h2>
                                <EditButton/>
                            </div>
                        }
                        avatar={<AvatarField key={data.rowid} size="45" />}

                    />
                    <CardContent
                        body ={
                        <TextField source={data.total_ttc}/>

                        }
                    />
                </Card>
            ))}
        </div>
    );
};
export default MobileFactures;
