import * as React from 'react';
import { CardContent } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FilterList, FilterListItem } from 'react-admin';
import {
    subMonths,
} from 'date-fns';
import {Card} from '../style.js';
import StatusIcon from "@material-ui/icons/MenuBook";

const TermFilter = () => (
  <FilterList label="resources.customers.fields.last_order" icon={<AccessTimeIcon />} >
    <FilterListItem label="resources.customers.filters.active"
      value={{lte_cl_dercde: undefined,
              gte_cl_dercde: "TO_DATE('" + subMonths(new Date(new Date()), 24).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')"
            }} />
    <FilterListItem label="resources.customers.filters.oldest"
      value={{lte_cl_dercde: "TO_DATE('"+subMonths(new Date(), 24).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')",
              gte_cl_dercde: undefined
            }} />
    <FilterListItem label="resources.customers.filters.older"
      value={{lte_cl_dercde: "TO_DATE('"+subMonths(new Date(), 12).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')",
              gte_cl_dercde: undefined
            }} />
    <FilterListItem label="resources.customers.filters.old"
      value={{lte_cl_dercde: "TO_DATE('"+subMonths(new Date(), 6).toLocaleDateString("fr-FR") + "', 'DD/MM/YYYY')",
              gte_cl_dercde: undefined
            }} />
  </FilterList>
)

const Filtre_Etat = () => (
  <FilterList label="resources.customers.fields.etat" icon={<StatusIcon />} >
    <FilterListItem label="resources.customers.filters.masque"
      value={{["eq_COALESCE(client_masque, false)"]:"true"
            }} />
    <FilterListItem label="resources.customers.filters.non_masque"
      value={{["eq_COALESCE(client_masque, false)"]:"false"
            }} />
  </FilterList>
)

const Aside = () => (
  <Card>
    <CardContent>
      <TermFilter />
      {/* <Filtre_Etat/> */}
    </CardContent>
  </Card>
);

export default Aside;
