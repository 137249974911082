import {
    Datagrid,
    Filter,
    List,
    NumberField,
    TextField,
    TextInput,
} from 'react-admin';
import {
  useMediaQuery
} from '@material-ui/core';

import {ButtonConditProd} from './buttons';
import {ButtonConditProdHT} from './buttons';
import ProductStatButton from "./statistique";
import FilesButton from "./files";

const ProdFilter = (props) => (
    <Filter {...props} variant = "outlined">
      <TextInput label="resources.products.fields.prod" source={props.categorie === "HorsTarif"? "likeic_id" : "likeic_libelle"} alwaysOn />
      {props.categorie !== "HorsTarif" &&
        <TextInput label="resources.products.fields.appell" source="likeic_ta_lib" alwaysOn />
      }
      {props.categorie !== "HorsTarif" &&
        <TextInput label="resources.products.fields.mill" source="likeic_millesime" alwaysOn />
      }
    </Filter>
)


const Conditionnement = (props) => {
  const isLarge = useMediaQuery(theme =>theme.breakpoints.up('lg'));

  return(
    <List
        resource={"productsCond"+props.categorie}
        basePath={"/productsCond"+props.categorie}
        filter= {props.categorie === "HorsTarif" ?
                  {"eq_libelle": "'" + props.id + "'"}:
                  {"eq_SUBSTRING(tarifs.st_code, 1, 6)": "'" + props.id + "'"}
                }
        filters={null}
        perPage={10}
        exporter={false}
        actions={false}
        sort={{field: "dispo", order: "DESC"}}
        title=" "
    >
      <Datagrid isRowSelectable={record => false}>
        {props.categorie === "HorsTarif" && <TextField label="resources.products.fields.mill" source='millesime'/>}
        {isLarge && <TextField label="resources.products.fields.libCoul" source='ta_libr'/>}
        <TextField label="resources.products.fields.condit" source='st_desi'/>
        <TextField label="resources.products.fields.libBout" source="bouteille" />
        <TextField label="resources.products.fields.libBouch" source="bouchon" />
        <TextField label="resources.products.fields.libHab" source="habillage" />
        {props.categorie !== "HorsTarif" && <NumberField label="resources.products.fields.PrixV" source='prix_vente'/>}
        {props.categorie !== "HorsTarif" && isLarge && <NumberField label="resources.products.fields.PrixP" source='prix_plancher'/>}
        {isLarge && <NumberField label="resources.products.fields.P" source='st_poid'/>}
        {isLarge && <NumberField label="resources.products.fields.deg" source='degre'/>}
        {props.categorie === "FWD" &&
          <NumberField label="resources.products.fields.stock" source="stock" options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 0}}/>
        }
        <NumberField label="resources.products.fields.stock_avail" source='dispo' options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 0}}/>
        {isLarge && <NumberField label="resources.products.fields.res" source='reserve' options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 0}}/>}
        {props.categorie === "HorsTarif" && <ButtonConditProdHT {...props}/>}
        {props.categorie !== "HorsTarif" && <ButtonConditProd {...props}/>}
        <FilesButton {...props} linkValue="st_code"/>
      </Datagrid>
    </List>
  )
};

/*
27/12/2021, suite remarque Allan Sichel, ne pas mettre les tranches de millésimes en Aside
de la liste.
Donc suppression de la propriété
aside={<AsideListProducts {...props}/>}
de la liste.
*/
export const ProdList = (props) => {
   return (
     <List
         resource={'products'+props.categorie}
         basePath={'/'+props.categorie}
         hasList hasEdit hasShow
         title={"resources.products.title.Prod"+props.categorie}
         filters={<ProdFilter {...props}/> }
         {...props}
     >
        <Datagrid expand={<Conditionnement {...props}/>} {...props} isRowSelectable={record => false}>
          {props.categorie !== "HorsTarif" && <TextField label="resources.products.fields.prod" source='libelle'/>}
          {props.categorie === "HorsTarif" && <TextField label="resources.products.fields.prod" source='id'/>}
          {props.categorie !== "HorsTarif" && <TextField label="resources.products.fields.appell" source='appel' />}
          {props.categorie !== "HorsTarif" && <TextField label="resources.products.fields.mill" source='millesime'/>}
          <NumberField label={props.categorie !== "FWD"? "resources.products.fields.eq75_avail": "resources.products.fields.eq75"}
            source="stock"
            options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 0}}
          />
          {props.categorie === "FWD" &&
            <NumberField label="resources.products.fields.eq75_avail" source="dispo" options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 0}}/>
          }
          <NumberField label="resources.products.fields.res75" source="reserve" options={{useGrouping: "always", style: "decimal", maximumFractionDigits: 0}}/>
          <ProductStatButton linkValue="id" />
        </Datagrid>
     </List>
   )
 }
