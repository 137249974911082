import * as React from 'react';
import { CardContent } from '@material-ui/core';
import { FilterList, FilterListItem, FilterLiveSearch, useGetList } from 'react-admin';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import {
  subMonths,
  startOfMonth,
  startOfYear
} from 'date-fns';

import {Card} from '../style.js';

const DateFilter = () => (
  <FilterList label="Date offre" icon={<AccessTimeIcon  />} >
      <FilterListItem
            label="resources.offres.filter.thisMonth"
            value={{
              gte_date_offre: startOfMonth(new Date()).toISOString(),
              lte_date_fin_offre: undefined,
          }}
        />
       <FilterListItem
            label="resources.offres.filter.threeLastMonth"
            value={{
              gte_date_offre: subMonths(startOfMonth(new Date()),3).toISOString(),
              lte_date_offre: undefined,
            }}
        />
        <FilterListItem
            label="resources.offres.filter.annee"
            value={{
              gte_date_offre: startOfYear(new Date()).toISOString(),
              lte_date_fin_offre: undefined,
          }}
        />
        
  </FilterList>
)

const AsideFilter = (props) =>(
  <>
  <FilterList label="resources.offres.field.lib" >
    <FilterLiveSearch  source="likeic_libelle" />
  </FilterList>
  <FilterList label="resources.offres.field.by" >
    <FilterLiveSearch  source="likeic_code_com" />
    </FilterList>
  </>
)

const StatusFilter = () =>{
  const { data } = useGetList(
    'status',
    { page: 1, perPage: 10 },
    { field: 'id', order: 'ASC' },
);
    let allStatus = Object.values(data)
  return(
  <FilterList
        label="resources.offres.field.status"
        icon={<LocalOfferIcon />}
    >
        {allStatus.map(status => (
            <FilterListItem
                label={status.libelle}
                key={status.id}
                value={{eq_status:status.id}}
            />
        ))}
    </FilterList>
)}

const Aside = (props) => {
    return(
    <Card>
      <CardContent>
        <AsideFilter />
        <StatusFilter/>
        <DateFilter />
      </CardContent>
    </Card>
)};

export default Aside;
