/********************************************************************************************
* Configuration and "global" variables
*******************************************************************************************/

/******************************************************************************************************
* variables settings depending on NODE_ENV environement variables to be independant of
* the production or dev contexte
* by default npm start sets NODE_ENV to development
* Set here the test for NODE_ENV accordingly to the value set in in script section.
******************************************************************************************************/

const localEnv = require("./localEnv.js");

export const URL_SERVER_REST = localEnv.PROTOCOLE_HTTP + '://' + localEnv.HOST;
export const URL_SERVER_WS = localEnv.PROTOCOLE_WS +"://" + localEnv.HOST;
export const URL_LOCAL_SERVER = localEnv.PROTOCOLE_HTTP + "://" + localEnv.LOCAL_HOST;

// external directories
export const DOC_ROOT = localEnv.DOC_ROOT;

// application name and version
export const APP_VERSION = "1.5.1";
export const APP_NAME = "CFE";
export const URL_BO = "http://srvrds.dom-sichel.local:8080/BOE/BI";
export const CORS = localEnv.CORS;
