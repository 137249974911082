import * as React from "react";

import {
  Grid,
  Typography,

  useMediaQuery
} from '@material-ui/core';

import {
  Datagrid,
  List,
  NumberField,
  TextField
} from "react-admin";

import { BestOrdersCustomerList,
  LastCreatedCustomerList,ObjectifsAvancementListe } from '../customers/customers';

import { SubListPagination } from "../customComponents/SubListPagination";

import { EarlyWinesList } from '../orders/EarlyWines';
import { MonthOrder } from '../orders/orders';

import {
  useTranslate,
} from 'react-admin';

const ReservesList = (props) =>{

  return(
      <List
          resource={props.resource}
          basePath={props.basePath}
          filters={null}
          bulkActionButtons={false}
          perPage={10}
          exporter={true}
          hasCreate={false}
          empty={false}
          sort={{field: "\"eq75\"", order: "DESC"}}
          title=" "
          pagination={<SubListPagination/>}
      >
          <Datagrid >
            <TextField source='ba_lib' label={`resources.orders.fields.product_name`} />
            <TextField source='millesime' label={`resources.orders.fields.millesime`} />
            <NumberField source='eq75' label={`resources.orders.fields.eq75`} />
          </Datagrid>
      </List>
  )
}



const list_items_lg = {
  assi: [
    {component: MonthOrder, resource: 'month_orders', basePath: '/month_orders', title:"pos.dashboard.monthOrders", itemByLine:1},
    {component: EarlyWinesList, resource: 'earlyWines', basePath: '/earlyWines', title:"pos.dashboard.earlyWinesEntries", itemByLine:2},
    {component: ReservesList, resource: 'reserves', basePath: '/reserves', title:"pos.dashboard.reserves", itemByLine:2}

  ],
  dcom: [
    {component: ObjectifsAvancementListe, resource: 'objectifs_avancement', basePath:'/objectifs_avancement', title: "pos.dashboard.lists.produits_prioritaires", itemByLine:2},
    //{component: LastCreatedCustomerList, resource: 'last_created_customers', basePath:'/last_created_customers', title: "pos.dashboard.lists.last_customers", itemByLine:2},
    {component: BestOrdersCustomerList, resource: 'best_orders_customers', basePath:'/best_orders_customers', title: "pos.dashboard.lists.best_customers", itemByLine:2}
  ],
  adm: [
    {component: ObjectifsAvancementListe, resource: 'objectifs_avancement', basePath:'/objectifs_avancement', title: "pos.dashboard.lists.produits_prioritaires", itemByLine:2},
    {component: BestOrdersCustomerList, resource: 'best_orders_customers', basePath:'/best_orders_customers', title: "pos.dashboard.lists.best_customers", itemByLine:2}
  ],
  com: [
    {component: ObjectifsAvancementListe, resource: 'objectifs_avancement', basePath:'/objectifs_avancement', title: "pos.dashboard.lists.produits_prioritaires", itemByLine:2},
    //{component: LastCreatedCustomerList, resource: 'last_created_customers', basePath:'/last_created_customers', title: "pos.dashboard.lists.last_customers", itemByLine:2},
    {component: BestOrdersCustomerList, resource: 'best_orders_customers', basePath:'/best_orders_customers', title: "pos.dashboard.lists.best_customers", itemByLine:2}
  ]
}

const list_items_sm = {
  assi: [
    {component: MonthOrder, resource: 'month_orders', basePath: '/month_orders', title:"pos.dashboard.monthOrders", itemByLine:1},
    {component: EarlyWinesList, resource: 'earlyWines', basePath: '/earlyWines', title:"pos.dashboard.earlyWinesEntries", itemByLine:2},
    {component: ReservesList, resource: 'reserves', basePath: '/reserves', title:"pos.dashboard.reserves", itemByLine:2}

  ],
  dcom: [
    {component: BestOrdersCustomerList, resource: 'best_orders_customers', basePath:'/best_orders_customers', title: "pos.dashboard.lists.best_customers", itemByLine:1},
    {component: ObjectifsAvancementListe, resource: 'objectifs_avancement', basePath:'/objectifs_avancement', title: "pos.dashboard.lists.produits_prioritaires", itemByLine:2},
    //{component: LastCreatedCustomerList, resource: 'last_created_customers', basePath:'/last_created_customers', title: "pos.dashboard.lists.last_customers", itemByLine:1},
  ],
  adm: [
    {component: BestOrdersCustomerList, resource: 'best_orders_customers', basePath:'/best_orders_customers', title: "pos.dashboard.lists.best_customers", itemByLine:1},
    {component: ObjectifsAvancementListe, resource: 'objectifs_avancement', basePath:'/objectifs_avancement', title: "pos.dashboard.lists.produits_prioritaires", itemByLine:2},
    //{component: LastCreatedCustomerList, resource: 'last_created_customers', basePath:'/last_created_customers', title: "pos.dashboard.lists.last_customers", itemByLine:1},
  ],
  com: [
    {component: BestOrdersCustomerList, resource: 'best_orders_customers', basePath:'/best_orders_customers', title: "pos.dashboard.lists.best_customers", itemByLine:1},
    {component: ObjectifsAvancementListe, resource: 'objectifs_avancement', basePath:'/objectifs_avancement', title: "pos.dashboard.lists.produits_prioritaires", itemByLine:2},
    //{component: LastCreatedCustomerList, resource: 'last_created_customers', basePath:'/last_created_customers', title: "pos.dashboard.lists.last_customers", itemByLine:1},
  ]
}


const ListD = (props) => {
  const translate=useTranslate();
  const isLarge = useMediaQuery(theme =>theme.breakpoints.up('lg'));
  const list_items = isLarge? list_items_lg: list_items_sm;

  return (
    <Grid
    container spacing={2}
    justifyContent="center"
    alignItems="top"
    >
      {list_items[localStorage.profile.toLowerCase()].map((item) => {
        const ItemList = item.component;
        return (
          <Grid item xs={12/item.itemByLine} style={{alignItems: "top"}}>
            <Typography color="primary" display="inline">{translate(item.title)}</Typography>
            <ItemList resource={item.resource} basePath={item.basePath} {...props}/>
          </Grid>
        )}
      )}
    </Grid>
  );
}

export default ListD ;
